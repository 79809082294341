import { CustomerWithRentedBuildings } from "app/types/customerWithRentedBuildings"
import { Action, Types } from "./customerWithRentedBuildings.actions"

export type CustomerWithRentedBuildingsState = {
  data: CustomerWithRentedBuildings | null
  errors?: Record<string, unknown>
  loading: boolean
}

const initialState: CustomerWithRentedBuildingsState = {
  data: null,
  errors: undefined,
  loading: false
}

export default function customerWithRentedBuildingsReducer(state = initialState, action: Action) {
  switch (action.type) {
    case Types.GET:
      return { ...state, loading: true }
    case Types.GET_SUCCESS:
      return { ...state, data: action.payload.data }
    case Types.GET_FAIL:
      return { ...state, errors: action.payload.errors }
    default:
      return state
  }
}
