import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./customerStatistics.actions"
import { getCustomerStatistics } from "app/api/customerStatistics"

export function* getCustomerStatisticsFlow(
  action: actions.GetCutomerStatisticsAction
): SagaIterator {
  try {
    const response = yield call(getCustomerStatistics, action.payload)
    yield put(actions.getCustomerStatisticsSuccess(response.data))
  } catch (e: any) {
    yield put(actions.getCustomerStatisticsFail(e))
  }
}

export default function* customerStatisticsSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getCustomerStatisticsFlow)
}
