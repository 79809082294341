import { ContactPerson } from "app/types/contactPerson"

export enum Types {
  GET = "CUSTOMER_CONTACT_PERSONS_GET",
  GET_SUCCESS = "CUSTOMER_CONTACT_PERSONS_GET_SUCCESS",
  GET_FAIL = "CUSTOMER_CONTACT_PERSONS_GET_FAIL"
}

export type CustomerContactPersonsPayload = {
  customerId: string
}

export type Action =
  | GetCutomerContactPersonsAction
  | GetCutomerContactPersonsSuccessAction
  | GetCutomerContactPersonsFailAction

export type GetCutomerContactPersonsAction = {
  type: Types.GET
  payload: { customerId: string }
}

export type GetCutomerContactPersonsSuccessAction = {
  type: Types.GET_SUCCESS
  payload: { data: ContactPerson[] }
}

export type GetCutomerContactPersonsFailAction = {
  type: Types.GET_FAIL
  payload: { errors: Record<string, unknown> }
}

export function getCustomerContactPersons(
  payload: CustomerContactPersonsPayload
): Action {
  return { type: Types.GET, payload }
}

export function getCustomerContactPersonsSuccess(
  data: ContactPerson[]
): Action {
  return { type: Types.GET_SUCCESS, payload: { data } }
}

export function getCustomerContactPersonsFail(
  errors: Record<string, unknown>
): Action {
  return { type: Types.GET_FAIL, payload: { errors } }
}

