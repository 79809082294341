import { RentedBuildingConfig } from "app/types/rentedBuildingConfig"
import { AxiosPromise } from "axios"
import { rentalApi } from "./api"

export const getRentedBuildings = (contactPersonId: string): AxiosPromise => {
  return rentalApi(`/contact_persons/${contactPersonId}/rented_buildings`, {
    method: "GET"
  })
}

export const getAssignedPersons = (rentedBuildingId: string): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/contact_persons`, {
    method: "GET"
  })
}

export const patchRentedBuildingConfig = (
  rentedBuildingId: string,
  data: RentedBuildingConfig
): AxiosPromise => {
  return rentalApi(`/cms/rented_buildings/${rentedBuildingId}/config`, {
    method: "PATCH",
    data
  })
}
