import routes from "../routes"
import { Route, Routes } from "react-router-dom"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import React, { ComponentType } from "react"
import { sec } from "app/utils/security"
import { Modules } from "app/enums/modules"
import { Layout } from "app/components/Layout/Layout"
import { AUTH0_CONNECTION } from "app/api/config"

export type RouteObject = {
  path: string
  component: ComponentType
  module?: Modules
  hasAuthentication?: boolean
}

function withLayout(Component: ComponentType, module?: Modules): ComponentType {
  if (module)
    return () => (
      <Layout module={module}>
        <Component />
      </Layout>
    )
  return Component
}

function withAuthentication(
  Component: ComponentType,
  hasAuthentication?: boolean
): ComponentType {
  if (!hasAuthentication) return Component
  const WrappedComponent = () => <Component />
  return withAuthenticationRequired(WrappedComponent, {
    loginOptions: {
      connection: AUTH0_CONNECTION
    }
  })
}

function createRoute({
  path,
  component,
  module,
  hasAuthentication
}: RouteObject) {
  const WithLayout = withLayout(component, module)
  const WithAuthentication = withAuthentication(WithLayout, hasAuthentication)
  return <Route path={path} key={path} element={<WithAuthentication />} />
}

const Router = (): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0()
  sec.setAccessTokenSilently(getAccessTokenSilently)

  return (
    <Routes>
      {routes.map((routeObject: RouteObject) => createRoute(routeObject))}
    </Routes>
  )
}

export default Router
