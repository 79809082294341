import { Button, ToolTip } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import React from "react"

type SubmitButtonsProps = {
  createCampaign: () => void
  hasErrors: boolean
}

type Props = SubmitButtonsProps

const PostCampaignButtons = ({ createCampaign, hasErrors }: Props) => {
  const { translate } = useTranslate()

  return (
    <>
      {hasErrors ? (
        <ToolTip
          value={translate(
            "cms.marketing-dashboard.campaign-detail.save-error-tooltip"
          )}
        >
          <Button
            label={translate("cms.marketing-dashboard.actions.createCampaign")}
            type="primary"
            disabled={hasErrors}
            onClick={createCampaign}
          />
        </ToolTip>
      ) : (
        <Button
          label={translate("cms.marketing-dashboard.actions.createCampaign")}
          type="primary"
          disabled={hasErrors}
          onClick={createCampaign}
        />
      )}
    </>
  )
}

export default PostCampaignButtons
