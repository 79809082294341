import { Campaign } from "app/types/campaign"
import { useTranslate } from "hooks/translate"
import React from "react"
import { useNavigate } from "react-router-dom"
import {
  CampaignMetadata,
  StyledBaseCard,
  StyledCardContent,
  CampaignTitle,
  StyledCardActions,
  Button,
  CampaignActiveIndicator
} from "./style"

export type CampaignCardState = {
  campaign: Campaign
}

export const CampaignCard = ({ campaign }: CampaignCardState) => {
  const navigate = useNavigate()
  const { translate } = useTranslate()

  const determineStatusLine = () => {
    return campaign.active ? "success" : "inactive"
  }

  const handleOnClick = () => {
    navigate(`/marketing-dashboard/${campaign.id}`)
  }

  return (
    <StyledBaseCard backgroundColor="white" statusLine={determineStatusLine()}>
      <StyledCardContent>
        <CampaignMetadata>
          <CampaignTitle>{campaign.title}</CampaignTitle>
        </CampaignMetadata>
        <CampaignActiveIndicator active={campaign.active} />
      </StyledCardContent>
      <StyledCardActions
        alignment="horizontal"
        placement="right"
        displayBorder={true}
      >
        <Button onClick={handleOnClick}>
          {translate("cms.marketing-dashboard.details")}
        </Button>
      </StyledCardActions>
    </StyledBaseCard>
  )
}
