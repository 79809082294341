import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const CustomerCount = styled.h2`
  font-size: ${theme.fontSizes.h5};
  line-height: ${theme.lineHeights.h5};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 2.4rem;
`

export const CustomerTitle = styled.h3`
  font-size: ${theme.fontSizes.paragraph};
  line-height: 2.2rem;
  font-weight: 700;
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 1.6rem;
`

export const TitleCaption = styled.p`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  font-weight: ${theme.fontWeights.regular};
  font-style: ${theme.fontStyles.normal};
  margin-bottom: 4rem;
`

export const SearchBarWrapper = styled.div`
  margin-bottom: 2.4rem;
`

export const CustomerStatisticWrapper = styled.div`
  margin-bottom: 4rem;

  & > div:last-child {
    margin-bottom: 0rem;
  }
`
