import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const ActionCardWrapper = styled.div`
  margin: 0 0 3.2rem 0;
`

export const ActionCardHeader = styled.h3`
  font-size: ${theme.fontSizes.h6};
  line-height: ${theme.lineHeights.h6};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  margin: 0 0 1.6rem 0;
  flex: 1 1 100%;
`

export const ActionCardText = styled.p`
  font-size: ${theme.fontSizes.paragraph};
  line-height: ${theme.lineHeights.paragraph};
  color: ${theme.colors.neutral.darkerGrey};
  margin: 0 0 2.4rem 0;
  flex: 1 1 100%;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  flex-wrap: wrap-reverse;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-wrap: unset;
  }
`

export const ActionText = styled.p`
  font-size: ${theme.fontSizes.paragraph};
  line-height: ${theme.lineHeights.paragraph};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 1.6rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 0 0 0 1.6rem;
  }
`
