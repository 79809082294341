import { Languages } from "app/enums/languages"

export type LanguageFormData = {
  title?: string
  hasLink: boolean
  label?: string
  url?: string
}

export enum ModulesEnum {
  DASHBOARD = "dashboard",
  ENERGY = "energy",
  DOCUMENTS = "documents",
  PROPERTY_MANAGEMENT = "property_management",
  INVOICES = "invoices",
  BUILDING_PASSPORT = "building_passport",
  ADMIN = "admin",
  ACCOUNT = "account",
  NOTIFICATION = "notification",
  FAQ = "faq"
}

export type PutCampaignData = {
  [Languages.EN_GB]?: LanguageFormData
  [Languages.NL_BE]?: LanguageFormData
  [Languages.NL_NL]?: LanguageFormData
  [Languages.FR_FR]?: LanguageFormData
  [Languages.RO_RO]?: LanguageFormData
}

export type Campaign = {
  id?: string
  title: string
  languageData: {
    [Languages.EN_GB]: LanguageFormData
    [Languages.NL_BE]: LanguageFormData
    [Languages.NL_NL]: LanguageFormData
    [Languages.FR_FR]: LanguageFormData
    [Languages.RO_RO]: LanguageFormData
  }
  active: boolean
  bannerPictureUrl?: string
  entityIds: string[]
  color: CampaignTitleColor
  module: ModulesEnum
}

export type CampaignFormData = Record<Languages, LanguageFormData>

export type CampaignsPaginationData = {
  page: number
  perPage: number
  pages: number
}

export type CampaignCandidates = {
  activeInCampaign: boolean
  buildYear: string
  country: string | null
  inCampaign: boolean | null
  name: string
  sourceId: string
  entityId: string
  availability: string | null
}

export type HslColorType = {
  h: number
  s: number
  l: number
  a?: number | undefined
}

export type HsvColorType = {
  h: number
  s: number
  v: number
  a?: number | undefined
}

export type RgbColorType = {
  r: number
  g: number
  b: number
  a?: number | undefined
}

export type CampaignTitleColor = {
  hex: string
  hsl: HslColorType
  hsv?: HsvColorType
  oldHue?: number
  rgb: RgbColorType
  source?: string
}
