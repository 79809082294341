import { applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "@redux-devtools/extension"
import createSagaMiddleware from "redux-saga"
import rootReducer, { createReduxHistory, routerMiddleware } from "./reducers"
import sagas from "./sagas"

const sagaMiddleware = createSagaMiddleware()

const middlewares = [routerMiddleware, sagaMiddleware]

const composedMiddlewares = composeWithDevTools(applyMiddleware(...middlewares))

const store = createStore(rootReducer(), composedMiddlewares)

sagaMiddleware.run(sagas)

export const history = createReduxHistory(store);

export default store
