import { Action, Types, Payload } from "./gaEvents.actions"

export type GaEventsState = {
  payload: Payload | undefined
}

const initialState: GaEventsState = {
  payload: undefined
}

export default function gaEventsReducer(state = initialState, action: Action): GaEventsState {
  switch (action.type) {
    case Types.CREATE_SUCCESS:
      return {
        ...state,
        payload: action.payload
      }

    default:
      return {
        ...state
      }
  }
}
