import { getCustomerContactPersons } from "app/api/customerContactPersons"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./customerContactPersons.actions"

export function* getCustomerContactPersonsFlow(
  action: actions.GetCutomerContactPersonsAction
): SagaIterator {
  try {
    const response = yield call(getCustomerContactPersons, action.payload)
    yield put(actions.getCustomerContactPersonsSuccess(response.data))
  } catch (e: any) {
    yield put(actions.getCustomerContactPersonsFail(e))
  }
}

export default function* customerContactPersonsSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getCustomerContactPersonsFlow)
}
