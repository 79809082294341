import { patchRentedBuildingConfig } from "app/api/rentedBuilding"
import { SagaIterator } from "redux-saga"
import { call, put, select, takeLatest } from "redux-saga/effects"
import * as actions from "./rentedBuilding.actions"
import * as customerActions from "app/redux/customerWithRentedBuildings/customerWithRentedBuildings.actions"
import * as selectors from "app/redux/customerWithRentedBuildings/customerWithRentedBuildings.selectors"

export function* patchRentedBuildingConfigFlow(
  action: actions.PutRentedBuildingConfigAction
): SagaIterator {
  try {
    const { rentedBuildingId, configData } = action.payload
    const response = yield call(
      patchRentedBuildingConfig,
      rentedBuildingId,
      configData
    )
    yield put(actions.patchRentedBuildingConfigSuccess(response))
    const customerWithRentedBuildings = yield select(
      selectors.getCustomerWithRentedBuildings
    )
    yield put(
      customerActions.getCustomerWithRentedBuildings({
        customerId: customerWithRentedBuildings.id
      })
    )
  } catch (e: any) {
    yield put(actions.patchRentedBuildingConfigFail(e))
  }
}

export default function* RentedBuildingContactPersonSaga(): SagaIterator {
  yield takeLatest(
    actions.Types.PATCH_RENTEDBUILDING_CONFIG,
    patchRentedBuildingConfigFlow
  )
}
