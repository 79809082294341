import CampaignDetail from "app/components/CampaignDetail"
import DataStoriesPage from "app/components/DataStoriesPage"
import Logout from "app/components/Logout"
import { Modules } from "app/enums/modules"
import CustomerDetails from "./CustomerDetails"
import LoginAsUserCallback from "./LoginAsUserCallback"
import MarketingDashboard from "./MarketingDashboard"
import Root from "./Root"
import { RouteObject } from "./router"
import UserManagement from "./UserManagement"

const routes: RouteObject[] = [
  {
    component: Root,
    hasAuthentication: true,
    path: "/"
  },
  {
    component: UserManagement,
    hasAuthentication: true,
    path: "/user-management",
    module: Modules.USER_MANAGEMENT
  },
  {
    component: MarketingDashboard,
    hasAuthentication: true,
    path: "/marketing-dashboard",
    module: Modules.MARKETING_DASHBOARD
  },
  {
    component: CampaignDetail,
    hasAuthentication: true,
    path: "/marketing-dashboard/:campaignId",
    module: Modules.MARKETING_DASHBOARD
  },
  {
    component: Logout,
    hasAuthentication: true,
    path: "/logout"
  },
  {
    component: CustomerDetails,
    hasAuthentication: true,
    path: "/user-management/customers/:customerId",
    module: Modules.USER_MANAGEMENT
  },
  {
    component: LoginAsUserCallback,
    hasAuthentication: true,
    path: "/callback",
    module: Modules.USER_MANAGEMENT
  },
  {
    component: DataStoriesPage,
    hasAuthentication: true,
    path: "/data-stories",
    module: Modules.DATA_STORIES
  }
]

export default routes
