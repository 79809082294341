import React from "react"
import { Button } from "@paudigital/wdp-components"
import { useTranslate } from "app/hooks/translate"
import { SubmitButtonsContainer } from "./style"
import { validate } from "uuid"
import PutCampaignButtons from "./PutCampaignButtons"
import PostCampaignButtons from "./PostCampaignButtons"

type SubmitButtonsProps = {
  handleClickBack: () => void
  setCampaignInactive: () => void
  setCampaignActive: () => void
  createCampaign: () => void
  valuesHaveChanged: boolean
  active: boolean
  hasErrors: boolean
  campaignId: string
}

const SubmittingButtons = ({
  handleClickBack,
  setCampaignInactive,
  setCampaignActive,
  valuesHaveChanged,
  active,
  hasErrors,
  campaignId,
  createCampaign
}: SubmitButtonsProps) => {
  const { translate } = useTranslate()

  const renderCampaignSubmitActions = () => {
    if (validate(campaignId)) {
      return (
        <PutCampaignButtons
          active={active}
          valuesHaveChanged={valuesHaveChanged}
          hasErrors={hasErrors}
          setCampaignActive={setCampaignActive}
          setCampaignInactive={setCampaignInactive}
        />
      )
    }
    return (
      <PostCampaignButtons
        hasErrors={hasErrors}
        createCampaign={createCampaign}
      />
    )
  }

  return (
    <SubmitButtonsContainer>
      <Button
        label={translate("cms.marketing-dashboard.go-back")}
        type="secondary"
        onClick={handleClickBack}
      />
      {renderCampaignSubmitActions()}
    </SubmitButtonsContainer>
  )
}

export default SubmittingButtons
