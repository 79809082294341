import styled from "styled-components"
import { StaticNotification, theme } from "@paudigital/wdp-components"

export const StyledStaticNotification = styled(StaticNotification)`
  width: 100%;
  margin-top: 2rem;
  border-left: 0.4rem solid ${theme.colors.system.warning};
  path {
    fill: ${theme.colors.system.warning};
  }
  p {
    font-size: ${theme.fontSizes.captionSmall};
    width: 30rem;
  }
`
export const stylesForDataGrid = {
  ".MuiDataGrid-main": {
    marginTop: "2rem",
    fontSize: "1.5rem"
  },
  ".MuiDataGrid-menuList": {
    "& .MuiMenuItem-root": {
      fontSize: theme.fontSizes.paragraph
    }
  },
  ".MuiCheckbox-root.Mui-checked": {
    color: `${theme.colors.green.contrast} !important`
  },
  ".MuiDataGrid-columnHeader:focus": {
    outline: "none !important"
  },
  ".MuiDataGrid-columnHeader:focus-within": {
    outline: "none !important"
  },
  "&.MuiDataGrid-menuIcon": {
    fontSize: "2rem"
  },
  ".MuiSvgIcon-root": {
    width: "2rem !important",
    height: "2.5rem !important"
  },
  ".MuiDataGrid-row.Mui-selected": {
    backgroundColor: "rgba(255, 255, 255, 0.08) !important"
  },
  ".MuiDataGrid-cell:focus": {
    outline: "none !important"
  },
  ".MuiTablePagination-displayedRows": {
    fontSize: "1.4rem !important"
  },
  ".MuiDataGrid-selectedRowCount": {
    fontSize: theme.fontSizes.paragraph
  }
}
