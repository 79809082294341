import { CustomerWithRentedBuildings } from "app/types/customerWithRentedBuildings"

export enum Types {
  GET = "CUSTOMER_WITH_RENTED_BUILDINGS_GET",
  GET_SUCCESS = "CUSTOMER_WITH_RENTED_BUILDINGS_GET_SUCCESS",
  GET_FAIL = "CUSTOMER_WITH_RENTED_BUILDINGS_GET_FAIL",
  RESEND_INVITE_BULK = "RESEND_INVITE_BULK",
  RESEND_INVITE_BULK_SUCCESS = "RESEND_INVITE_BULK_SUCCESS",
  RESEND_INVITE_BULK_FAIL = "RESEND_INVITE_BULK_FAIL"
}

export type Action =
  | GetCustomerWithRentedBuildingsAction
  | GetCustomerWithRentedBuildingsSuccessAction
  | GetCustomerWithRentedBuildingsFailAction
  | ResendInviteBulkAction
  | ResendInviteBulkSuccessAction
  | ResendInviteBulkFailAction

export type GetCustomerWithRentedBuildingsAction = {
  type: Types.GET
  payload: {
    customerId: string
  }
}

export type GetCustomerWithRentedBuildingsSuccessAction = {
  type: Types.GET_SUCCESS
  payload: { data: CustomerWithRentedBuildings }
}

export type GetCustomerWithRentedBuildingsFailAction = {
  type: Types.GET_FAIL
  payload: { errors: Record<string, unknown> }
}

export type ResendInviteBulkAction = {
  type: Types.RESEND_INVITE_BULK
  payload: { entityId: string }
}

export type ResendInviteBulkSuccessAction = {
  type: Types.RESEND_INVITE_BULK_SUCCESS
}

export type ResendInviteBulkFailAction = {
  type: Types.RESEND_INVITE_BULK_FAIL
}

export function getCustomerWithRentedBuildings(payload: {
  customerId: string
}): Action {
  return { type: Types.GET, payload }
}

export function getCustomerWithRentedBuildingsSuccess(
  data: CustomerWithRentedBuildings
): Action {
  return { type: Types.GET_SUCCESS, payload: { data } }
}

export function getCustomerWithRentedBuildingsFail(
  errors: Record<string, unknown>
): Action {
  return { type: Types.GET_FAIL, payload: { errors } }
}

export function resendInviteBulk(entityId: string): Action {
  return {
    type: Types.RESEND_INVITE_BULK,
    payload: { entityId }
  }
}

export function resendInviteBulkSuccess(): Action {
  return { type: Types.RESEND_INVITE_BULK_SUCCESS }
}

export function resendInviteBulkFail(): Action {
  return { type: Types.RESEND_INVITE_BULK_FAIL }
}
