import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  background-color: ${theme.colors.neutral.lightGrey};
  border-radius: 4px;
  margin-top: 1rem;
`

export const LinkInfoContainer = styled.div`
  border: 1px solid ${theme.colors.neutral.midGrey};
  box-sizing border-box;
  border-radius: 4px;
  padding: 1.6rem;
  margin-top: 1.6rem;
`

export const FormContainer = styled.div`
  padding: 1.6rem;
`

export const LanguageFormHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 1.6rem;
`

export const RotateContainer = styled.div`
  transform: rotate(
    ${(props: { isOpen: boolean }) => (props.isOpen ? "0" : "180")}deg
  );
`
