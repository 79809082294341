import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const ConfirmationTitle = styled.div`
  font-size: ${theme.fontSizes.h3};
  font-weight: ${theme.fontWeights.regular};
  margin: 4.3rem 0;
`
export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const ConfirmationDescription = styled.div`
  text-align: center;
  font-size: ${theme.fontSizes.paragraph};
  max-width: 39rem;
  margin-top: 0.6rem;
`

export const ConfirmationButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
  width: 100%;
  div {
    width: 48%;
  }
`
