import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"
import { Link } from "react-router-dom"

export const WDPHeader = styled.header`
  background: ${theme.colors.neutral.white};
  max-width: 115.2rem;
  margin: 0 auto;
  padding-right: 2.4rem;
  height: 14.5rem;
  display: grid;
  grid-template-columns: 15% auto 10%;
  grid-template-areas:
    "logo . user"
    "logo menu-items menu-items";

  & > svg {
    max-height: 80%;
  }
`

export const UserContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  grid-area: user;
  flex-direction: row;
  align-items: center;
`

export const UserInfo = styled.div`
  margin-left: 1.2rem;
`

export const UserName = styled.label`
  font-style: ${theme.fontStyles.normal};
  font-weight: ${theme.fontWeights.medium};
  font-size: ${theme.fontSizes.captionExtraSmall};
  line-height: ${theme.lineHeights.captionExtraSmall};
  color: ${theme.colors.neutral.darkerGrey};
  display: block;
  align-self: center;
`

export const UserDescription = styled.label`
  position: static;
  font-style: ${theme.fontStyles.normal};
  font-weight: ${theme.fontWeights.regular};
  font-size: ${theme.fontSizes.captionExtraSmall};
  line-height: ${theme.lineHeights.captionExtraSmall};
  width: 6rem;
  text-overflow: ellipsis;
  color: ${theme.colors.neutral.darkerGrey};
  display: block;
  overflow: hidden;
  white-space: nowrap;
  align-self: center;
`

export const Container = styled.div`
  width: 100%;
  background: ${theme.colors.neutral.white};
`

export const NavigationMenuContainer = styled.div`
  grid-area: menu-items;
  border-top: solid 1px ${theme.colors.neutral.midGrey};
  display: flex;
  width: 100%;
`

export const NavigationMenuItemContainer = styled.ul<{ isHidden: boolean }>`
  @media only screen and (${theme.breakpoints.tablet}) {
    display: ${props => (props.isHidden ? "none" : "flex")};
    justify-content: space-between;
    align-items: center;
    padding: 0;
    border: 0;
  }
`
export const MenuItem = styled.li<{ active?: boolean }>`
  height: 5rem;
  display: flex;
  align-items: center;
  font-weight: ${props => props.active && theme.fontWeights.bold};
  text-decoration: none;

  line-height: 1.6rem;
  border: 0;
  position: relative;

  &:after {
    background: ${theme.colors.orange.prc100};
    content: "";
    position: absolute;
    bottom: -1rem;
    border: 2px solid ${theme.colors.orange.prc100};
    border-radius: 5rem;
    width: 100%;
    display: ${props => (props.active ? "block" : "none")};
  }

  &:not(:first-child) {
    margin-left: 5rem;
  }
`

export const MenuItemLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.neutral.darkerGrey};
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 2rem;
  width: 100%;

  &:focus {
    ${theme.outline}
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 0;
  }
`

export const LogoContainer = styled.div`
  grid-area: logo;
  margin-top: 1rem;
  justify-self: center;
  align-self: center;
`
