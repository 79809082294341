import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./campaignPicture.actions"
import {
  getCampaignPicture,
  putCampaignPicture,
  deleteCampaignPicture
} from "app/api/campaignPicture"

export function* putCampaignPictureFlow(
  action: actions.PutCampaignPictureAction
): SagaIterator {
  try {
    const { data, campaignId } = action.payload
    const formData = new FormData()
    formData.append("upfile", data)
    yield call(putCampaignPicture, formData, campaignId)
    yield put(actions.putCampaignPictureSuccess())
  } catch (e: any) {
    yield put(actions.putCampaignPictureFail(e))
  }
}

export function* getCampaignPictureFlow(
  action: actions.GetCampaignPictureAction
): SagaIterator {
  try {
    const campaignId = action.payload
    const response = yield call(getCampaignPicture, campaignId)
    const fileName = getFileNameFromHeaders(response.headers)
    yield put(actions.getCampaignPictureSuccess(response.data, fileName))
  } catch (e: any) {
    yield put(actions.getCampaignPictureFail(e))
  }
}

export function* deleteCampaignPictureFlow(
  action: actions.DeleteCampaignPictureAction
): SagaIterator {
  try {
    const campaignId = action.payload
    yield call(deleteCampaignPicture, campaignId)
    yield put(actions.deleteCampaignPictureSuccess())
  } catch (e: any) {
    yield put(actions.deleteCampaignPictureFail(e))
  }
}

export default function* bannerPictureSaga(): SagaIterator {
  yield takeLatest(actions.Types.PUT, putCampaignPictureFlow)
  yield takeLatest(actions.Types.GET, getCampaignPictureFlow)
  yield takeLatest(actions.Types.DELETE, deleteCampaignPictureFlow)
}

export const getFileNameFromHeaders = (headers: any) => {
  return headers["content-disposition"]
    .split("filename=")[1]
    .replace(/['"]+/g, "")
}
