import { DropDown } from "@paudigital/wdp-components"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import { ModulesEnum } from "app/types/campaign"
import { useTranslate } from "hooks/translate"
import React from "react"
import { OnChangeValue } from "react-select"
import {
  ContentContainer,
  HeaderContainer,
  DescriptionContainer,
  TranslationsTitle,
  TargetWarning
} from "../style"

type CampaignTargetProps = {
  module: ModulesEnum
  handleTargetChange: (option: OnChangeValue<CustomOptionType, false>) => void
}

export const CampaignTarget = ({
  module,
  handleTargetChange
}: CampaignTargetProps) => {
  const { translate } = useTranslate()
  const options = Object.values(ModulesEnum).filter(m => m !== module)
  return (
    <ContentContainer>
      <HeaderContainer>
        <DescriptionContainer>
          <TranslationsTitle>
            {translate("cms.marketing-dashboard.campaignTarget")}
          </TranslationsTitle>
          {module !== ModulesEnum.DASHBOARD && (
            <TargetWarning>
              {translate("cms.marketing-dashboard.campaignTarget.warning")}
            </TargetWarning>
          )}
        </DescriptionContainer>
      </HeaderContainer>
      <DropDown
        hasDropdownIndicator
        options={options.map((o: string) => ({
          value: o,
          label: translate(`cms.marketing-dashboard.modules.${o}`)
        }))}
        onChange={handleTargetChange}
        defaultValue={ModulesEnum.DASHBOARD}
        placeholder={translate(`cms.marketing-dashboard.modules.${module}`)}
      />
    </ContentContainer>
  )
}

export default CampaignTarget
