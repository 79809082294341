import { DeleteContactPersonFlows } from "app/types/deleteContactPersonFlow"
import { RentedBuildingWithContactPersons } from "app/types/rentedBuildingWithContactPersons"
import State from "../state"

export const isLoading = (state: State): boolean => state.deleteContactPerson.loading
export const hasErrors = (state: State): boolean =>
  state.deleteContactPerson.errors ? true : false
export const getDeleteFlow = (state: State): DeleteContactPersonFlows | null => state.deleteContactPerson.data.flow
export const getIdOfContactPerson = (state: State): string | undefined => {
  if (!state.deleteContactPerson.data.contactPersonId) return undefined
  return state.deleteContactPerson.data.contactPersonId
}
export const getEmailOfContactPerson = (state: State): string => {
  if (!state.deleteContactPerson.data.contactPersonId) return ""
  const buildingsWherePresent = state.deleteContactPerson.data.buildings.filter(building => {
    const cp = building.contactPersons.find(cp => cp.id === state.deleteContactPerson.data.contactPersonId)
    if (!cp) return false
    return true
  })
  if (buildingsWherePresent.length === 0) return ""
  const cp = buildingsWherePresent[0].contactPersons.find(cp => cp.id === state.deleteContactPerson.data.contactPersonId)
  if (!cp) return ""

  return cp.email
}
export const getCompanyName = (state: State): string => {
  if (!state.deleteContactPerson.data.companyName) return ""
  return state.deleteContactPerson.data.companyName
}
export const getBuildingIdsToDeleteContactPersonFrom = (state: State): string[] => {
  return state.deleteContactPerson.data.buildings.map(building => building.id)
}
export const getBuildingsToDeleteContactPersonFrom = (state: State): RentedBuildingWithContactPersons[] => {
  return state.deleteContactPerson.data.buildings
}
export const getEntityIdOfContactPerson = (state: State): string => {
  if (!state.deleteContactPerson.data.entityId) return ""
  return state.deleteContactPerson.data.entityId
}
