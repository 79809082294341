import { useTranslate } from "hooks/translate"
import { StyledActiveIndicator } from "./style"
import React from "react";

type Props = {
    active: boolean,
    className?: string
}

const ActiveIndicator = ({active, className}: Props) => {

    const { translate } = useTranslate()

    return <StyledActiveIndicator className={className} active={active} >{translate(`cms.marketing-dashboard.${active ? "active" : "inactive"}`)}</StyledActiveIndicator>
}

export default ActiveIndicator;
