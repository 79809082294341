import { usePermission } from "app/hooks/permission"
import React from "react"
import { useNavigate } from "react-router-dom"

const Root = (): null => {
  const navigate = useNavigate()
  const { isMarketing, isUserManagement } = usePermission()

  React.useEffect(() => {
    if (isUserManagement) {
      return navigate("/user-management", { replace: true })
    }
    if (isMarketing) {
      return navigate("/marketing-dashboard", { replace: true })
    }
  }, [navigate, isMarketing, isUserManagement])

  return null
}

export default Root
