import { BreadCrumbs, Link } from "@paudigital/wdp-components"
import { getCustomerWithRentedBuildings } from "app/redux/customerWithRentedBuildings/customerWithRentedBuildings.actions"
import * as selectors from "app/redux/customerWithRentedBuildings/customerWithRentedBuildings.selectors"
import {
  getAmountOfRentedBuildingsWithoutAdmins,
  determineIfRentedBuildingContactPersonsHaveExpiredInvitations
} from "app/utils/customerActions.utils"
import { useTranslate } from "hooks/translate"
import React from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import AddBuildingAdminAction from "../AddBuildingAdminAction"
import CustomerRentedBuildingList from "../CustomerRentedBuildingList"
import { DataContainer } from "../Layout/style"
import ResendInvitationsAction from "../ResendInvitationsAction"
import { CustomerName, CustomerSourceId } from "./style"
import { useDispatch } from "react-redux"

export const CustomerDetailPage = () => {
  const navigate = useNavigate()
  const { customerId } = useParams<{ customerId: string }>()
  const { translate } = useTranslate()
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (customerId) {
      dispatch(getCustomerWithRentedBuildings({ customerId }))
    }
  }, [dispatch, customerId])

  const handleOnClick = (): void => {
    navigate("/")
  }

  const customerWithRentedBuildings = useSelector(
    selectors.getCustomerWithRentedBuildings
  )

  const customerName = React.useMemo(() => {
    return customerWithRentedBuildings && customerWithRentedBuildings.name
      ? customerWithRentedBuildings.name
      : ""
  }, [customerWithRentedBuildings])
  const customerSourceId = React.useMemo(() => {
    return customerWithRentedBuildings && customerWithRentedBuildings.sourceId
      ? customerWithRentedBuildings.sourceId
      : ""
  }, [customerWithRentedBuildings])
  const customerRentedBuildings = React.useMemo(() => {
    return customerWithRentedBuildings &&
      customerWithRentedBuildings.rentedBuildings
      ? customerWithRentedBuildings.rentedBuildings
      : []
  }, [customerWithRentedBuildings])
  const buildingsWithNoAdmin = React.useMemo(() => {
    return getAmountOfRentedBuildingsWithoutAdmins(customerRentedBuildings)
  }, [customerRentedBuildings])
  const expiredInvitationsPresent = React.useMemo(() => {
    return determineIfRentedBuildingContactPersonsHaveExpiredInvitations(
      customerRentedBuildings
    )
  }, [customerRentedBuildings])

  return (
    <DataContainer>
      <BreadCrumbs>
        <Link
          type="breadcrumb"
          value={translate("cms.customer.customerOverview.link")}
          onClick={handleOnClick}
        />
        <Link type="breadcrumb" disabled={true} value={customerName} />
      </BreadCrumbs>
      <CustomerName>
        {customerName}
        <CustomerSourceId>&nbsp;-&nbsp;{customerSourceId}</CustomerSourceId>
      </CustomerName>
      {buildingsWithNoAdmin > 0 && (
        <AddBuildingAdminAction
          entityId={customerId!}
          buildingsWithoutAdmin={buildingsWithNoAdmin}
        />
      )}
      {expiredInvitationsPresent && (
        <ResendInvitationsAction entityId={customerId!} />
      )}
      <CustomerRentedBuildingList
        rentedBuildingsWithContactPersons={customerRentedBuildings}
      />
    </DataContainer>
  )
}

export default CustomerDetailPage
