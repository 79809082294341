import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import {
  Types,
  GetDataStoriesLastSendingAction,
  getDataStoriesLastSendingFail,
  getDataStoriesLastSendingSuccess,
  PostDataStoriesSendingAction,
  postDataStoriesSendingFail,
  postDataStoriesSendingSuccess
} from "./dataStories.actions"
import {
  getDataStoriesLastSending,
  postDataStoriesNewsLettersSending,
  postDataStoriesSending
} from "app/api/dataStories"

export function* getDataStoriesLastSendingFlow(
  action: GetDataStoriesLastSendingAction
): SagaIterator {
  try {
    const response = yield call(getDataStoriesLastSending)
    yield put(getDataStoriesLastSendingSuccess(response.data))
  } catch (e: any) {
    yield put(getDataStoriesLastSendingFail(e))
  }
}

export function* postDataStoriesLastSendingFlow(
  action: PostDataStoriesSendingAction
): SagaIterator {
  try {
    yield call(postDataStoriesNewsLettersSending)
    const response = yield call(postDataStoriesSending, action.payload)
    yield put(postDataStoriesSendingSuccess(response.data))
  } catch (e: any) {
    yield put(postDataStoriesSendingFail(e))
  }
}
export default function* dataStoriesSaga(): SagaIterator {
  yield takeLatest(
    Types.GET_DATA_STORIES_LAST_SENDING,
    getDataStoriesLastSendingFlow
  )
  yield takeLatest(Types.POST_DATA_STORIES, postDataStoriesLastSendingFlow)
}
