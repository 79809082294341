import { Action, Types } from "./campaignPicture.actions"

export type CampaignPictureState = {
  errors?: Record<string, unknown>
  loading: boolean
  data?: Blob
  filename?: string
}

const initialState: CampaignPictureState = {
  errors: undefined,
  loading: false,
  data: undefined,
  filename: undefined
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: Action): CampaignPictureState => {
  switch (action.type) {
    case Types.PUT:
      return {
        ...state,
        loading: true
      }

    case Types.PUT_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.PUT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.GET:
      return {
        ...state,
        loading: true,
        filename: undefined,
        data: undefined
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        filename: action.payload.filename
      }

    case Types.GET_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        filename: undefined,
        data: undefined
      }

    case Types.DELETE:
      return {
        ...state,
        loading: true
      }

    case Types.DELETE_SUCCESS:
      return {
        ...state,
        data: undefined,
        loading: false
      }

    case Types.DELETE_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    default:
      return state
  }
}
