import { Pagination } from "@paudigital/wdp-components"
import { useViewport } from "app/hooks/viewport"
import { getCampaigns } from "app/redux/campaign/campaign.actions"
import * as selectors from "app/redux/campaign/campaign.selectors"
import { useTranslate } from "hooks/translate"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { CampaignActions } from "./CampaignActions"
import { Campaigns } from "./Campaigns"
import { DataContainer } from "./style"


const MarketingOverViewPage = () => {

  const { search } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const { width } = useViewport()

  const params = new URLSearchParams(search)
  const page = Number(params.get("page")) || 1
  const perPage = Number(params.get("perPage")) || 20
  const campaigns = useSelector(selectors.getCampaigns)
  const campaignsPaginationData = useSelector(selectors.getCampaignsPaginationData)

  useEffect(() => {
    dispatch(
      getCampaigns({
        page,
        perPage
      })
    )
  }, [page, perPage, dispatch])

  const previousPage = (): void => {
    params.set("page", (page - 1).toString())
    navigate(`/marketing-dashboard/?${params.toString()}`)
  }

  const nextPage = (): void => {
    params.set("page", (page + 1).toString())
    navigate(`/marketing-dashboard/?${params.toString()}`)
  }

  const goToPage = (event: React.MouseEvent<any>): void => {
    params.set("page", event.currentTarget.value.toString())
    navigate(`/marketing-dashboard/?${params.toString()}`)
  }

  return (
    <DataContainer>
      <CampaignActions/>
      <Campaigns campaigns={campaigns} />
      <Pagination
        page={page}
        perPage={perPage}
        pages={campaignsPaginationData.pages}
        nextPage={nextPage}
        previousPage={previousPage}
        goToPage={goToPage}
        showPages={3}
        width={width}
        next={translate("cms.next")}
        previous={translate("cms.previous")}
      />
    </DataContainer>
  )
}
export default MarketingOverViewPage
