import { CustomerStatisticData } from "app/types/customerStatistic"
import { CustomerStatisticTypes } from "app/types/customerStatisticsTypes"
import { useTranslate } from "hooks/translate"
import React, { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import {
  StyledBaseCard,
  StyledCardContent,
  CustomerName,
  CustomerMetadata,
  CustomerActiveAccounts,
  StyledCardActions,
  Button,
  CustomerSourceId
} from "./style"

export type CustomerStatisticsProps = {
  customersStatisticData: CustomerStatisticData
  type: CustomerStatisticTypes
}

type Props = CustomerStatisticsProps

const CustomerStatistic = ({ customersStatisticData, type }: Props) => {
  
  const { translate } = useTranslate()
  const navigate = useNavigate()

  const handleOnClick = useCallback(
    (entityId: any) => () => {
      const path = `/user-management/customers/${entityId}`
      navigate(path)
    },
    [navigate]
  )

  const determineStatusLine = () => {
    switch (type) {
      case CustomerStatisticTypes.ADMIN_NEEDED:
        return "moreInfo"
      case CustomerStatisticTypes.LINK_EXPIRED:
        return "warning"
      case CustomerStatisticTypes.ONBOARDING_INCOMPLETE:
        return "reported"
      case CustomerStatisticTypes.ALL_ACCOUNTS_ACTIVE:
      default:
        return "success"
    }
  }

  const determineButtonText = () => {
    switch (type) {
      case CustomerStatisticTypes.ADMIN_NEEDED:
        return translate("cms.customerOverview.adminNeeded.button")
      case CustomerStatisticTypes.LINK_EXPIRED:
        return translate("cms.customerOverview.linkExpired.button")
      case CustomerStatisticTypes.ONBOARDING_INCOMPLETE:
        return translate("cms.customerOverview.onboardingNotCompleted.button")
      case CustomerStatisticTypes.ALL_ACCOUNTS_ACTIVE:
      default:
        return translate("cms.customerOverview.activeAccounts.button")
    }
  }

  return (
    <StyledBaseCard backgroundColor="white" statusLine={determineStatusLine()}>
      <StyledCardContent>
        <CustomerMetadata
          onClick={handleOnClick(customersStatisticData.entityId)}
        >
          <CustomerName>
            {customersStatisticData.name}
            <CustomerSourceId>
              &nbsp;-&nbsp;{customersStatisticData.entitySourceId}
            </CustomerSourceId>
          </CustomerName>
          <CustomerActiveAccounts>
            {customersStatisticData.activeAccounts}{" "}
            {translate("cms.customerOverview.card.activeAccounts")}
          </CustomerActiveAccounts>
        </CustomerMetadata>
      </StyledCardContent>
      <StyledCardActions
        alignment="horizontal"
        placement="right"
        displayBorder={true}
      >
        <Button onClick={handleOnClick(customersStatisticData.entityId)}>
          {determineButtonText()}
        </Button>
      </StyledCardActions>
    </StyledBaseCard>
  )
}

export default CustomerStatistic
