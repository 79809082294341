import { MailSendIcon, PopUp } from "@paudigital/wdp-components"
import { useTranslate } from "app/hooks/translate"
import React from "react"
import { StyledFormWrapper, ModalDescription } from "./style"

type Props = {
  onClose: () => void
}

export const ResendInviteModal = ({ onClose }: Props) => {
  const { translate } = useTranslate()
  return (
    <PopUp onClose={onClose} delayInMs={4000} show>
      <StyledFormWrapper>
        <MailSendIcon />
        <ModalDescription>
          {translate("cms.modal.resendinvite.description")}
        </ModalDescription>
      </StyledFormWrapper>
    </PopUp>
  )
}

export default ResendInviteModal
