import React from "react"
import { RentedBuildingWithContactPersons } from "app/types/rentedBuildingWithContactPersons"
import CustomerRentedBuilding from "../CustomerRentedBuilding"

export type CustomerRentedBuildingListProps = {
  rentedBuildingsWithContactPersons: RentedBuildingWithContactPersons[]
}

type Props = CustomerRentedBuildingListProps

const CustomerRentedBuildingList = ({
  rentedBuildingsWithContactPersons
}: Props) => {
  const showBuildingAddress = React.useMemo(() => {
    return rentedBuildingsWithContactPersons.length > 1
  }, [rentedBuildingsWithContactPersons])

  return (
    <>
      {rentedBuildingsWithContactPersons.map(rentedBuildingData => {
        return (
          <CustomerRentedBuilding
            key={rentedBuildingData.id}
            showBuildingAddress={showBuildingAddress}
            rentedBuildingData={rentedBuildingData}
          />
        )
      })}
    </>
  )
}

export default CustomerRentedBuildingList
