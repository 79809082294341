import { AppEventComponentProps } from "app/redux/appEvents/types"

export enum PossibleAppEvents {
  PUBLISH_CHANGED_SUCCESS,
  PUBLISH,
  ACCOUNT_CREATED,
  ACCOUNT_DELETED,
  NOTIFICATION,
  ADD_COLLEAGUE_TO_BUILDING,
  PASSWORD_RESET_SUCCESS,
  RESEND_INVITE_SUCCESS,
  DELETE_CONTACT_PERSON,
  ERROR,
  RESEND_INVITE_BULK_SUCCESS,
  SEND_CONFIRMATION
}

export type AppEventMapping = {
  event: PossibleAppEvents
  component: (props: AppEventComponentProps) => JSX.Element
}
