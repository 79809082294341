import React from "react"
import { useTranslate } from "hooks/translate"
import {
  BuildingCheckboxWrapper,
  BuildingSelectWrapper,
  DeleteModalTextBold,
  DeleteModalTextLight,
  RadioGroupWrapper
} from "../style"
import { BaseDeleteModal, Checkbox, Radio, RadioGroup } from "@paudigital/wdp-components"
import { useDispatch, useSelector } from "react-redux"
import * as deleteCpSelectors from "app/redux/deleteContactPerson/deleteContactPerson.selectors"
import { deleteContactPerson } from "app/redux/deleteContactPerson/deleteContactPerson.actions"

type CheckBoxOptionProps = {
  key: string
  name: string
  checked: boolean
  label: string
  guidanceText: string
  disabled: boolean
}

type DeleteContactPersonMultiBuildingModalProps = {
  onCancel: () => void
}

type Props = DeleteContactPersonMultiBuildingModalProps

const DeleteContactPersonMultiBuildingModal = ({
  onCancel
}: Props) => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const [buildingsToDeleteFrom, setBuildingsToDeleteFrom] = React.useState<string[]>([])
  const [stillWorksAtCompany, setStillWorksAtCompany] = React.useState<string | undefined>(undefined)
  const loading = useSelector(deleteCpSelectors.isLoading)
  const email = useSelector(deleteCpSelectors.getEmailOfContactPerson)
  const contactPersonId = useSelector(deleteCpSelectors.getIdOfContactPerson)
  const companyName = useSelector(deleteCpSelectors.getCompanyName)
  const cpBuildingsToDeleteFrom = useSelector(deleteCpSelectors.getBuildingsToDeleteContactPersonFrom)

  const checkboxData = cpBuildingsToDeleteFrom.reduce((data, building) => {
    const contactPerson = building.contactPersons.find(cp => cp.id === contactPersonId)
    if (!contactPerson) return data
    return [
      ...data,
      {
        key: building.id,
        name: building.id,
        checked: buildingsToDeleteFrom.includes(building.id),
        label: `${building.street} ${building.houseNo}, ${building.city}`,
        guidanceText: contactPerson.roles ? contactPerson.roles.join(", ") : "",
        disabled: false
      }
    ]
  }, [] as CheckBoxOptionProps[])

  const onDelete = () => {
    if (!contactPersonId || buildingsToDeleteFrom.length === 0) return
    dispatch(deleteContactPerson(
      contactPersonId,
      buildingsToDeleteFrom,
      stillWorksAtCompany === undefined ? true : stillWorksAtCompany === "available"
    ))
  }

  const handleCheckboxClick = (val: boolean, name: string) => {
    if (buildingsToDeleteFrom.length === cpBuildingsToDeleteFrom.length) setStillWorksAtCompany(undefined)
    setBuildingsToDeleteFrom(
      buildingsToDeleteFrom.includes(name)
        ? buildingsToDeleteFrom.filter(building => building !== name)
        : [
          ...buildingsToDeleteFrom,
          name
        ]
    )
  }

  const handleRadioClick = (name: string) => {
    setStillWorksAtCompany(name)
  }

  return (
    <BaseDeleteModal
      title={translate("cms.modal.delete.seat.restrict.title")}
      cancelBtnText={translate("cms.modal.delete.seat.cancel")}
      deleteBtnText={
        buildingsToDeleteFrom.length === cpBuildingsToDeleteFrom.length
          ? translate("cms.modal.delete.seat.delete.account")
          : buildingsToDeleteFrom.length > 1 || buildingsToDeleteFrom.length === 0
            ? translate("cms.modal.delete.seat.restrict.multi", { amount: buildingsToDeleteFrom.length })
            : translate("cms.modal.delete.seat.restrict.single")
      }
      deleteDisabled={loading || contactPersonId === undefined || buildingsToDeleteFrom.length === 0}
      onCancel={onCancel}
      onDelete={onDelete}
    >
      <>
        <DeleteModalTextBold marginBottom="1.6rem">{translate("cms.modal.delete.seat.restrict.action", { email })}</DeleteModalTextBold>
        <DeleteModalTextLight marginBottom="2.4rem">{translate("cms.modal.delete.seat.restrict.description")}</DeleteModalTextLight>
        <BuildingSelectWrapper>
          {checkboxData.map(data => {
            return (
              <BuildingCheckboxWrapper key={data.key}>
                <Checkbox
                  key={data.key}
                  name={data.name}
                  checked={data.checked}
                  label={data.label}
                  guidanceText={data.guidanceText}
                  disabled={data.disabled}
                  onClick={handleCheckboxClick}
                  tabIndex={0}
                  hasSpacing={true}
                />
              </BuildingCheckboxWrapper>
            )
          })}
        </BuildingSelectWrapper>
        {buildingsToDeleteFrom.length === cpBuildingsToDeleteFrom.length && (
          <>
            <DeleteModalTextBold marginBottom="1.6rem">{translate("cms.modal.delete.seat.action", { companyName })}</DeleteModalTextBold>
            <RadioGroupWrapper>
              <RadioGroup
                contentCentered={true}
              >
                <Radio
                  checked={stillWorksAtCompany === "available"}
                  disabled={false}
                  onClick={handleRadioClick}
                  label={translate("cms.modal.delete.seat.yes")}
                  name="available"
                />
                <Radio
                  checked={stillWorksAtCompany === "notAvailable"}
                  disabled={false}
                  onClick={handleRadioClick}
                  label={translate("cms.modal.delete.seat.no")}
                  name="notAvailable"
                />
              </RadioGroup>
            </RadioGroupWrapper>
          </>
        )}
      </>
    </BaseDeleteModal>
  )
}


export default DeleteContactPersonMultiBuildingModal
