import React from "react"
import {
  BuildingAddress,
  CustomerRentedBuildingWrapper,
  CustomRentedBuildingHeader
} from "./style"
import { RentedBuildingWithContactPersons } from "app/types/rentedBuildingWithContactPersons"
import RentedBuildingSeatOverview from "../RentedBuildingSeatOverview"

export type CustomerRentedBuildingProps = {
  showBuildingAddress: boolean
  rentedBuildingData: RentedBuildingWithContactPersons
}

type Props = CustomerRentedBuildingProps

const CustomerRentedBuilding = ({
  showBuildingAddress,
  rentedBuildingData
}: Props) => {
  const parseBuildingAddress = (
    street: string,
    number: string,
    city: string
  ) => {
    return `${street} ${number}, ${city}`
  }

  const buildingAddress = React.useMemo(() => {
    return parseBuildingAddress(
      rentedBuildingData.street,
      rentedBuildingData.houseNo,
      rentedBuildingData.city
    )
  }, [rentedBuildingData])

  return (
    <CustomerRentedBuildingWrapper>
      <CustomRentedBuildingHeader>
        {showBuildingAddress && (
          <BuildingAddress>{buildingAddress}</BuildingAddress>
        )}
      </CustomRentedBuildingHeader>
      <RentedBuildingSeatOverview rentedBuildingData={rentedBuildingData} />
    </CustomerRentedBuildingWrapper>
  )
}

export default CustomerRentedBuilding
