import React from "react"
import { LanguageSelectWrapper } from "./style"
import { DropDown } from "@paudigital/wdp-components"
import { OnChangeValue } from "react-select"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import { useTranslate } from "hooks/translate"
import { getLanguage, languageCodes } from "./utils/languageTranslations.utils"
import { Locales } from "app/i18n/config"

type Option = {
  label: string
  value: string
}

type Props = {
  defaultLanguage?: string
  label: string
  onChange: (option: OnChangeValue<CustomOptionType, false>) => void
}

const LanguageSelect = ({ defaultLanguage, onChange, label }: Props) => {
  const { translate } = useTranslate()

  const buildTranslation = (value: string) => translate(getLanguage(value))

  const options: Option[] = languageCodes
    .map(l => ({
      label: buildTranslation(l),
      value: l
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1))

  const defaultValue = buildTranslation(
    options.find(o => o.value === defaultLanguage)?.value || Locales.EN_GB
  )

  return (
    <LanguageSelectWrapper>
      <DropDown
        hasDropdownIndicator
        label={label}
        options={options}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </LanguageSelectWrapper>
  )
}

export default LanguageSelect
