import { Action, Types } from "./contactPersonContactRoles.actions"
import { RentedBuildingWithContacRoles } from "app/types/rentedBuildingWithContactRoles"

export type ContactPersonContactRolesState = {
  data: RentedBuildingWithContacRoles[]
  errors?: Record<string, unknown>
  loading: boolean
}

const initialState: ContactPersonContactRolesState = {
  data: [],
  errors: undefined,
  loading: false
}

export default function ContactPersonContactRolesReducer(
  state = initialState,
  action: Action
): ContactPersonContactRolesState {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.CLEAR:
      return {
        ...state,
        data: []
      }

    default:
      return state
  }
}
