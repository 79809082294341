import { Languages } from "app/enums/languages"
import { object, string, boolean, array } from "yup"

export const validationSchema = object({
  title: string().required("cms.input.required"),
  module: string().required("cms.inpu.required"),
  languageData: object({
    [Languages.EN_GB]: object({
      title: string().required("cms.input.required"),
      hasLink: boolean(),
      label: string().when(["hasLink"], {
        is: true,
        then: string().required("cms.input.required")
      }),
      url: string().when(["hasLink"], {
        is: true,
        then: string().required("cms.input.required").url("cms.url.invalid")
      })
    }),
    [Languages.NL_BE]: object({
      title: string().required("cms.input.required"),
      hasLink: boolean(),
      label: string().when(["hasLink"], {
        is: true,
        then: string().required("cms.input.required")
      }),
      url: string().when(["hasLink"], {
        is: true,
        then: string().required("cms.input.required").url("cms.url.invalid")
      })
    }),
    [Languages.NL_NL]: object({
      title: string().required("cms.input.required"),
      hasLink: boolean(),
      label: string().when(["hasLink"], {
        is: true,
        then: string().required("cms.input.required")
      }),
      url: string().when(["hasLink"], {
        is: true,
        then: string().required("cms.input.required").url("cms.url.invalid")
      })
    }),
    [Languages.FR_FR]: object({
      title: string().required("cms.input.required"),
      hasLink: boolean(),
      label: string().when(["hasLink"], {
        is: true,
        then: string().required("cms.input.required")
      }),
      url: string().when(["hasLink"], {
        is: true,
        then: string().required("cms.input.required").url("cms.url.invalid")
      })
    }),
    [Languages.RO_RO]: object({
      title: string().required("cms.input.required"),
      hasLink: boolean(),
      label: string().when(["hasLink"], {
        is: true,
        then: string().required("cms.input.required")
      }),
      url: string().when(["hasLink"], {
        is: true,
        then: string().required("cms.input.required").url("cms.url.invalid")
      })
    })

  }),
  entityIds: array().min(1).required()
})

export const defaultColor = {
  hsl: {
    h: 160.5,
    s: 0.192299999,
    l: 0.4078,
    a: 1
  },
  hex: "#547C6F",
  rgb: {
    r: 84,
    g: 124,
    b: 111,
    a: 1
  },
  source: "default"
}
