import React from "react"
import { ThemeProvider } from "styled-components"
import { theme } from "@paudigital/wdp-components"
import { Provider } from "react-redux"
import store, { history } from "./store"
import { IntlProvider } from "react-intl"
import { Locales, translations } from "../src/app/i18n/config"
import Router from "./routes/router"
import { GlobalStyle } from "@paudigital/wdp-components"
import "reset-css"
import Auth0ProviderWithHistory from "providers/auth0ProviderWithHistory"
import { getBrowserLanguage } from "app/utils/browserLanguage.utils"
import { HistoryRouter } from "redux-first-history/rr6";

const App = (): JSX.Element => {
  const appLanguage =
    Object.values(Locales).find(l => l === getBrowserLanguage()) ??
    Locales.EN_GB

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Provider store={store}>
        <IntlProvider messages={translations[appLanguage]} locale={appLanguage}>
          <HistoryRouter history={history}>
            <Auth0ProviderWithHistory>
              <Router />
            </Auth0ProviderWithHistory>
          </HistoryRouter>
        </IntlProvider>
      </Provider>
    </ThemeProvider>
  )
}

export default App
