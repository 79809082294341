import React, { useMemo } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import Header from "app/components/Header"
import { CMSBanner, CMSContentContainer } from "@paudigital/wdp-components"
import AppEventsProvider from "../AppEventsProvider"
import { useNavigate } from "react-router-dom"
import { Modules } from "app/enums/modules"
import { usePermission } from "app/hooks/permission"
import { useTranslate } from "hooks/translate"

type Prop = {
  children: React.ReactNode
  module?: Modules
}

export const Layout = ({ children, module }: Prop) => {
  const navigate = useNavigate()
  const { user } = useAuth0()
  const { hasPermission } = usePermission()
  const { translate } = useTranslate()
  const handleLogout = () => navigate("/logout")

  const hasAccess = useMemo(() => {
    if (module) {
      return hasPermission(module)
    }
    return true
  }, [module, hasPermission])

  const title =
    module === Modules.MARKETING_DASHBOARD
      ? "cms.banner-title.banner-overview-page"
      : module === Modules.DATA_STORIES
      ? "cms.banner-title.data-stories"
      : "cms.banner-title.customer-admin-mywdp"

  return (
    <>
      <Header
        userFullname={user?.name || ""}
        userDescription="Logout"
        onLogout={handleLogout}
      />
      <CMSBanner title={translate(title)} />
      <CMSContentContainer>
        <AppEventsProvider local={false} />
        {hasAccess ? children : translate("cms.no-permission")}
      </CMSContentContainer>
    </>
  )
}
