import { AxiosPromise } from "axios"
import { rentalApi } from "./api"

export const putCampaignPicture = (
  data: FormData,
  campaignId: string
): AxiosPromise => {
  return rentalApi(`cms/campaigns/${campaignId}/banner_picture`, {
    method: "PUT",
    data,
    headers: { "Content-Type": "multipart/form-data" }
  })
}

export const getCampaignPicture = (campaignId: string): AxiosPromise => {
  return rentalApi(`cms/campaigns/${campaignId}/banner_picture`, {
    method: "GET",
    responseType: "blob"
  })
}

export const deleteCampaignPicture = (campaignId: string): AxiosPromise => {
  return rentalApi(`cms/campaigns/${campaignId}/banner_picture`, {
    method: "DELETE"
  })
}
