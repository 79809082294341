import React from "react"
import { InputElement } from "@paudigital/wdp-components"
import { useTranslate } from "app/hooks/translate"
import {
  ContentContainer,
  TranslationsTitle,
  TranslationsDescription,
  DescriptionContainer,
  HeaderContainer
} from "../style"

type CampaignTitleProps = {
  title: string
  handleTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  errors: string | undefined
  touched: boolean | undefined
  validateField: (name: string) => Promise<void> | Promise<string | undefined>
  handleBlur: (e: React.FocusEvent<any>) => void
}

const CampaignTitle = ({
  title,
  handleTitleChange,
  errors,
  touched,
  validateField,
  handleBlur
}: CampaignTitleProps) => {
  const { translate } = useTranslate()
  const hasErrors = errors && touched

  const onBlur = (e: React.FocusEvent<any>) => {
    validateField("title")
    handleBlur(e)
  }

  return (
    <ContentContainer>
      <HeaderContainer>
        <DescriptionContainer>
          <TranslationsTitle>
            {translate("cms.marketing-dashboard.campaignTitle")}
          </TranslationsTitle>
          <TranslationsDescription>
            {translate("cms.marketing-dashboard.campaignTitle-description")}
          </TranslationsDescription>
        </DescriptionContainer>
      </HeaderContainer>
      <InputElement
        charCount={true}
        charLimit={57}
        name="title"
        value={title}
        onChange={handleTitleChange}
        error={hasErrors}
        errorMessage={hasErrors && translate(errors as string)}
        onBlur={onBlur}
      />
    </ContentContainer>
  )
}

export default CampaignTitle
