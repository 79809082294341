import { CustomerStatisticTypes } from "app/types/customerStatisticsTypes"
import React from "react"
import { CustomerStatisticData } from "app/types/customerStatistic"
import CustomerStatus from "./CustomerStatus"
import { CustomerStatisticWrapper } from "./style"
import CustomerStatistic from "../CustomerStatistic"

export type CustomerStatisticsProps = {
  customersStatistics: CustomerStatisticData[]
  customersTitle: string
  showStatus: boolean
  statisticType: CustomerStatisticTypes
}

export const CustomerStatistics = ({
  customersStatistics,
  customersTitle,
  showStatus,
  statisticType
}: CustomerStatisticsProps) => {
  return (
    <CustomerStatisticWrapper>
      {showStatus && (
        <CustomerStatus
          title={customersTitle}
          count={customersStatistics.length}
        />
      )}
      {customersStatistics.map(customerStatisticData => {
        return (
          <CustomerStatistic 
            customersStatisticData={customerStatisticData} 
            type={statisticType}
            key={customerStatisticData.entityId}
          />
        )
      })}
    </CustomerStatisticWrapper>
  )
}

export default CustomerStatistics
