import { CampaignTitleColor } from "app/types/campaign"
import React, { useState, Dispatch, SetStateAction } from "react"
import { SketchPicker, ColorResult } from "react-color"
import {
  ColorPickerPreview,
  ColorPickerPreviewContainer,
  ColorPickerPopOver,
  ColorPickerCover,
  ColorPickerContainer
} from "./style"
import { useTranslate } from "app/hooks/translate"

const ColorPicker = ({
  handleChangeColor,
  previewColor,
  handleTouched
}: {
  handleChangeColor: Dispatch<SetStateAction<CampaignTitleColor>>
  handleTouched: Dispatch<SetStateAction<boolean>>
  previewColor: CampaignTitleColor
}) => {
  const [displayPicker, setDisplayPicker] = useState<boolean>(false)
  const { translate } = useTranslate()
  const handlePicker = () => setDisplayPicker(!displayPicker)

  const handleChange = (e: ColorResult) => {
    const newColor: CampaignTitleColor = {
      hex: e.hex,
      hsl: e.hsl,
      rgb: e.rgb,
      source: "color-picker"
    }
    handleChangeColor(newColor)
    handleTouched(true)
  }

  return (
    <ColorPickerContainer>
      {translate(
        "cms.marketing-dashboard.campaign-detail.title-color.description"
      )}
      <ColorPickerPreviewContainer onClick={handlePicker}>
        <ColorPickerPreview previewColor={previewColor} />
      </ColorPickerPreviewContainer>
      <ColorPickerPopOver>
        {displayPicker && (
          <>
            <ColorPickerCover onClick={() => setDisplayPicker(false)} />
            <SketchPicker color={previewColor.rgb} onChange={handleChange} />
          </>
        )}
      </ColorPickerPopOver>
    </ColorPickerContainer>
  )
}

export default ColorPicker
