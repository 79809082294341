import { Modules } from "app/enums/modules"

export type MainRoute = {
  url: string
  module: Modules
}

export const mainRoutes: MainRoute[] = [
  {
    url: "/user-management",
    module: Modules.USER_MANAGEMENT
  },
  {
    url: "/marketing-dashboard",
    module: Modules.MARKETING_DASHBOARD
  },
  {
    url: "/data-stories",
    module: Modules.DATA_STORIES
  }
]
