import { useAuth0 } from "@auth0/auth0-react"
import React from "react"

const Logout = () => {
  const { logout } = useAuth0()

  React.useEffect(() => {
    logout({ federated: true })
  }, [logout])
  return <></>
}

export default Logout
