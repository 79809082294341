import { DeleteContactPersonFlows } from "app/types/deleteContactPersonFlow"
import { RentedBuildingWithContactPersons } from "app/types/rentedBuildingWithContactPersons"
import { Action, Types } from "./deleteContactPerson.actions"

export type DeleteContactPersonState = {
  data: {
    flow: DeleteContactPersonFlows | null
    entityId: string | null
    contactPersonId: string | null
    companyName: string | null
    buildings: RentedBuildingWithContactPersons[]
  }
  errors?: Record<string, unknown>
  loading: boolean
}

const initialState: DeleteContactPersonState = {
  data: {
    flow: null,
    entityId: null,
    contactPersonId: null,
    companyName: null,
    buildings: []
  },
  errors: undefined,
  loading: false
}

export default function deleteContactPersonReducer(state = initialState, action: Action): DeleteContactPersonState {
  switch (action.type) {
    case Types.DETERMINE:
      return {
        ...state,
        loading: true
      }
    case Types.DETERMINE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          flow: action.payload.flow,
          entityId: action.payload.entityId,
          contactPersonId: action.payload.contactPersonId,
          companyName: action.payload.companyName,
          buildings: action.payload.buildings
        },
        loading: false
      }
    case Types.DETERMINE_FAIL:
      return {
        ...state,
        loading: false
      }
    case Types.DELETE:
      return {
        ...state,
        loading: true
      }
    case Types.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case Types.DELETE_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }
    case Types.RESET:
      return {
        ...initialState
      }
    default:
      return {
        ...state
      }
  }
}
