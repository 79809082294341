import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const ClearContentButtonContainer = styled.button`
  margin-top: 0.8rem;
  border: none;
  background: none;
  cursor: pointer;
  color: ${theme.colors.system.moreInfo};
  font-size: ${theme.fontSizes.paragraph};
  font-weight: ${theme.fontWeights.bold};
  text-decoration: underline;
  display: flex;

  svg {
    vertical-align: baseline;
    margin-right: 1.6rem;
  }
`
