import { theme, BaseCard, CardContent, CardActions } from "@paudigital/wdp-components"
import ActiveIndicator from "app/components/ActiveIndicator"
import styled from "styled-components"

export const StyledBaseCard = styled(BaseCard)`
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  width: 100%;
  margin-bottom: 1.6rem;
`

export const StyledCardContent = styled(CardContent)`
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  @media screen and (min-width: 555px) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
`

export const CampaignTitle = styled.h4`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  font-weight: ${theme.fontWeights.bold};
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 0.4rem;
  text-transform: capitalize;
  @media screen and (min-width: 555px) {
    max-width: 30rem;
    overflow: visible;
    text-overflow: initial;
  }
`

export const CampaignMetadata = styled.a`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1.6rem .8rem 1.6rem 3.2rem;
  color: ${theme.colors.neutral.darkerGrey};
  text-decoration: none;
  z-index: 100;
  ${theme.outline};
  width: 100%;
  cursor: pointer;
  box-sizing: border-box !important;
`

export const StyledCardActions = styled(CardActions)`
  margin-right: 0;
  height: initial;
  align-items: stretch;
`

export const Button = styled.button`
  ${theme.outline};

  justify-content: center;
  background: ${theme.colors.neutral.white};
  border: none;
  border-left: 1px solid ${theme.colors.neutral.midGrey};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  font-size: ${theme.fontSizes.captionExtraSmall};
  line-height: ${theme.lineHeights.captionExtraSmall};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  cursor: pointer;

  @media screen and (min-width: 415px) {
    width: 12rem;
  }
`

export const CampaignActiveIndicator = styled(ActiveIndicator)`
  align-self: start;
  margin-top: 2rem;
  margin-left: 2.8rem;
  @media screen and (min-width: 555px) {
    margin-left: unset;
    margin-top: unset;
    align-self: center;
  }
` 
