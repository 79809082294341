import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as appEventActions from "../appEvents/appEvents.actions"
import * as bannerPictureActions from "../campaignPicture/campaignPicture.actions"
import * as actions from "./campaign.actions"
import {
  putCampaign,
  postCampaign,
  getCampaigns,
  getCampaign,
  deactivateAll,
  getCampaignCandidates
} from "app/api/campaign"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { intl } from "app/i18n/config"
import { history } from "store"

export function* postCampaignFlow(
  action: actions.PostCampaignAction
): SagaIterator {
  try {
    const { data, bannerPicture } = action.payload
    const { data: createdCampaign } = yield call(postCampaign, data)
    if (bannerPicture) {
      yield put(
        bannerPictureActions.putCampaignPicture(
          bannerPicture,
          createdCampaign.id
        )
      )
    } else {
      yield put(bannerPictureActions.deleteCampaignPicture(createdCampaign.id))
    }
    yield put(actions.postCampaignSuccess(data))
    yield put(
      appEventActions.fire({
        uniqueIdentifier: "publish-changed",
        eventName: PossibleAppEvents.PUBLISH_CHANGED_SUCCESS
      })
    )
    yield call(history.go, -1)
  } catch (e: any) {
    yield put(
      appEventActions.fire({
        uniqueIdentifier: "post-campaign-error",
        eventName: PossibleAppEvents.NOTIFICATION,
        props: {
          title: intl.formatMessage({
            id: "cms.modal.error.title"
          }),
          closeTranslation: intl.formatMessage({ id: "cms.button.close" }),
          description: intl.formatMessage({
            id: "cms.modal.error.description"
          }),
          variant: "error",
          show: true
        }
      })
    )
    yield put(actions.postCampaignFail(e))
  }
}

export function* putCampaignFlow(
  action: actions.PutCampaignAction
): SagaIterator {
  try {
    const { data, bannerPicture, campaignId } = action.payload
    yield call(putCampaign, data)
    if (bannerPicture) {
      yield put(
        bannerPictureActions.putCampaignPicture(bannerPicture, campaignId)
      )
    } else {
      yield put(bannerPictureActions.deleteCampaignPicture(campaignId))
    }
    yield put(actions.putCampaignSuccess(data))
    yield put(
      appEventActions.fire({
        uniqueIdentifier: "publish-changed",
        eventName: PossibleAppEvents.PUBLISH_CHANGED_SUCCESS
      })
    )
    yield call(history.go, -1)
  } catch (e: any) {
    yield put(
      appEventActions.fire({
        uniqueIdentifier: "put-campaign-error",
        eventName: PossibleAppEvents.NOTIFICATION,
        props: {
          title: intl.formatMessage({
            id: "cms.modal.error.title"
          }),
          closeTranslation: intl.formatMessage({ id: "cms.button.close" }),
          description: intl.formatMessage({
            id: "cms.modal.error.description"
          }),
          variant: "error",
          show: true
        }
      })
    )
    yield put(actions.putCampaignFail(e))
  }
}

export function* getCampaignFlow(
  action: actions.GetCampaignAction
): SagaIterator {
  try {
    const { campaignId } = action.payload
    const response = yield call(getCampaign, campaignId)
    yield put(actions.getCampaignSuccess(response.data))
  } catch (e: any) {
    yield put(actions.getCampaignsFail(e))
  }
}

export function* getCampaignsFlow(
  action: actions.GetCampaignsAction
): SagaIterator {
  try {
    const response = yield call(getCampaigns, action.payload)
    yield put(actions.getCampaignsSuccess(response.data))
  } catch (e: any) {
    yield put(actions.getCampaignsFail(e))
  }
}

export function* deactivateAllFlow(
  action: actions.DeactivateAllAction
): SagaIterator {
  try {
    yield call(deactivateAll)
    yield put(actions.deactivateAllSuccess())
    yield put(actions.getCampaigns({ page: 1, perPage: 20 }))
  } catch (e: any) {
    yield put(actions.deactivateAllFail(e))
  }
}

export function* getCampaignCandidatesFlow(
  action: actions.GetCampaignCandidatesAction
): SagaIterator {
  try {
    const { campaignId } = action.payload
    const response = yield call(getCampaignCandidates, campaignId)
    yield put(actions.getCampaignCandidatesSuccess(response.data))
  } catch (e: any) {
    yield put(actions.getCampaignCandidatesFail(e))
  }
}

export default function* campaignSaga(): SagaIterator {
  yield takeLatest(actions.Types.POST_CAMPAIGN, postCampaignFlow)
  yield takeLatest(actions.Types.PUT_CAMPAIGN, putCampaignFlow)
  yield takeLatest(actions.Types.GET_CAMPAIGN, getCampaignFlow)
  yield takeLatest(actions.Types.GET_CAMPAIGNS, getCampaignsFlow)
  yield takeLatest(actions.Types.DEACTIVATE_ALL, deactivateAllFlow)
  yield takeLatest(
    actions.Types.GET_CAMPAIGN_CANDIDATES,
    getCampaignCandidatesFlow
  )
}
