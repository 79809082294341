import { Button, CMSActionCard } from "@paudigital/wdp-components"
import { useTranslate } from "app/hooks/translate"
import React from "react"
import {
  ActionCardHeader,
  ActionCardText,
  ActionCardWrapper,
  ActionText,
  ButtonWrapper
} from "./style"
import * as appEventActions from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { useDispatch } from "react-redux"

export type AddBuildingAdminActionProps = {
  buildingsWithoutAdmin: number
  entityId: string
}

type Props = AddBuildingAdminActionProps

const AddBuildingAdminAction = ({ buildingsWithoutAdmin, entityId }: Props) => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()

  const handleOnClick = () => {
    dispatch(
      appEventActions.fire({
        eventName: PossibleAppEvents.ADD_COLLEAGUE_TO_BUILDING,
        uniqueIdentifier: "add-colleague-to-building",
        props: {
          entityId
        }
      })
    )
  }

  return (
    <ActionCardWrapper>
      <CMSActionCard type="error">
        <ActionCardHeader>
          {translate("cms.action.noAdmin.header")}
        </ActionCardHeader>
        <ActionCardText>
          {translate("cms.action.noAdmin.body.text")}
        </ActionCardText>
        <ButtonWrapper>
          <Button
            label={translate("cms.button.addAnotherAdmin")}
            onClick={handleOnClick}
          />
          <ActionText>
            {buildingsWithoutAdmin === 1 &&
              translate("cms.action.noAdmin.action.text.singular", {
                buildingsWithoutAdmin
              })}
            {buildingsWithoutAdmin > 1 &&
              translate("cms.action.noAdmin.action.text.plural", {
                buildingsWithoutAdmin
              })}
          </ActionText>
        </ButtonWrapper>
      </CMSActionCard>
    </ActionCardWrapper>
  )
}

export default AddBuildingAdminAction
