import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import {
  getContactPerson,
  getContactPersonById,
  passwordResetContactPerson,
  patchContactPersonOnboarding,
  resendInvite
} from "app/api/contactPerson"
import * as actions from "./contactPerson.actions"
import * as appEventActions from "../appEvents/appEvents.actions"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"

export function* getContactPersonFlow(
  action: actions.GetContactPersonAction
): SagaIterator {
  try {
    const { entityId, query } = action.payload
    const response = yield call(getContactPerson, entityId, query)
    yield put(actions.getContactPersonSuccess(response && response.data))
  } catch (e: any) {
    yield put(actions.getContactPersonFail(e))
  }
}

export function* getContactPersonByIdFlow(
  action: actions.GetContactPersonByIdAction
): SagaIterator {
  try {
    const { contactPersonId } = action.payload
    const response = yield call(getContactPersonById, contactPersonId)
    yield put(actions.getContactPersonByIdSuccess(response && response.data))
  } catch (e: any) {
    yield put(actions.getContactPersonByIdFail(e))
  }
}

export function* patchContactPersonOnboardingFlow(
  action: actions.PatchContactPersonOnboardingAction
): SagaIterator {
  try {
    const id = action.payload.contactPersonId
    yield call(patchContactPersonOnboarding, id)
    yield put(actions.patchContactPersonOnboardingSuccess())
  } catch (e: any) {
    yield put(actions.patchContactPersonOnboardingFail(e))
  }
}

export function* passwordResetFlow(
  action: actions.PasswordResetPersonAction
): SagaIterator {
  try {
    yield call(passwordResetContactPerson, action.payload.data)
    yield put(actions.passwordResetSuccess())
    yield put(
      appEventActions.fire({
        eventName: PossibleAppEvents.PASSWORD_RESET_SUCCESS,
        uniqueIdentifier: "password-reset-success",
        props: {
          name: `${action.payload.data.firstName} ${action.payload.data.lastName}`
        }
      })
    )
  } catch (e: any) {
    yield put(actions.passwordResetFail(e))
  }
}

export function* resendInviteFlow(
  action: actions.ResendInviteAction
): SagaIterator {
  try {
    yield call(resendInvite, action.payload)
    yield put(actions.resendInviteSuccess())
    yield put(
      appEventActions.fire({
        uniqueIdentifier: "resend-invite-success",
        eventName: PossibleAppEvents.RESEND_INVITE_SUCCESS
      })
    )
  } catch (e: any) {
    yield put(actions.resendInviteFail(e))
  }
}

export default function* ContactPersonSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getContactPersonFlow)
  yield takeLatest(actions.Types.GET_BY_ID, getContactPersonByIdFlow)
  yield takeLatest(actions.Types.PASSWORD_RESET, passwordResetFlow)
  yield takeLatest(actions.Types.RESEND_INVITE, resendInviteFlow)
  yield takeLatest(
    actions.Types.PATCH_ONBOARDING,
    patchContactPersonOnboardingFlow
  )
}
