import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const CustomerName = styled.h2`
  font-size: ${theme.fontSizes.h5};
  line-height: ${theme.lineHeights.h5};
  color: ${theme.colors.neutral.darkerGrey};
  font-weight: ${theme.fontWeights.bold};
  margin: 0.8rem 0 3.6rem 0;
`

export const CustomerSourceId = styled.span`
  font-size: ${theme.fontSizes.paragraph};
  line-height: ${theme.lineHeights.paragraph};
  font-weight: ${theme.fontWeights.light};
`
