import { auth0Instance } from "app/api/auth0"
import { AUTH0_CONNECTION } from "app/api/config"
import React from "react"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const LoginAsUserCallback = () => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const id = query.get("id")

  useEffect(() => {
    if (id) {
      auth0Instance.authorize({
        connection: AUTH0_CONNECTION,
        prompt: "login",
        contact_person_id: id
      } as any)
    }
  }, [id])
  return <> </>
}

export default LoginAsUserCallback
