import React, { useEffect } from "react"
import {
  DataStoriesContainer,
  DescriptionText,
  DescriptionTextBold
} from "./style"
import { Button, Spinner } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { getDataStoriesLastSending } from "app/redux/dataStories/dataStories.actions"
import {
  selectDataStoriesLastSending,
  selectDataStoriesLoadingState
} from "app/redux/dataStories/dataStories.selectors"
import { formatTimeStamp } from "app/utils/formatTimeStamp.utils"

const DataStoriesPage = () => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const data = useSelector(selectDataStoriesLastSending)
  const isLoading = useSelector(selectDataStoriesLoadingState)

  useEffect(() => {
    dispatch(getDataStoriesLastSending())
  }, [dispatch])

  const handleClick = () => {
    dispatch(
      fire({
        eventName: PossibleAppEvents.SEND_CONFIRMATION,
        uniqueIdentifier: "sending-confirmation-modal"
      })
    )
  }

  return (
    <DataStoriesContainer>
      <Button
        label={translate("cms.data-stories.button")}
        onClick={handleClick}
      />
      {isLoading ? (
        <Spinner />
      ) : data ? (
        <DescriptionText>
          {translate("cms.data-stories.last-send")}
          <DescriptionTextBold>
            {formatTimeStamp(data.sendDate.toString())}
          </DescriptionTextBold>{" "}
          {translate("cms.data-stories.last-send-by")}{" "}
          <DescriptionTextBold>{data.sendUser}</DescriptionTextBold>
        </DescriptionText>
      ) : (
        " "
      )}
    </DataStoriesContainer>
  )
}

export default DataStoriesPage
