import enGbTranslations from "./en-GB.translations.json"
import frFrTranslations from "./fr-FR.translations.json"
import nlBeTranslations from "./nl-BE.translations.json"
import nlNlTranslations from "./nl-NL.translations.json"
import roRoTranslations from "./ro-RO.translations.json"
import deDeTranslations from "./de-DE.translations.json"
import { createIntl, createIntlCache } from "react-intl"

export enum Locales {
  EN_GB = "en-GB",
  NL_BE = "nl-BE",
  NL_NL = "nl-NL",
  FR_FR = "fr-FR",
  RO_RO = "ro-RO",
  DE_DE = "de-DE"
}

const locales = [...Object.values(Locales)]

const translations = {
  [Locales.EN_GB]: {
    ...enGbTranslations
  },
  [Locales.FR_FR]: {
    ...frFrTranslations
  },
  [Locales.NL_BE]: {
    ...nlBeTranslations
  },
  [Locales.NL_NL]: {
    ...nlNlTranslations
  },
  [Locales.RO_RO]: {
    ...roRoTranslations
  },
  [Locales.DE_DE]: {
    ...deDeTranslations
  }
}

const cache = createIntlCache()

const intl = createIntl(
  {
    locale: Locales.EN_GB,
    messages: translations[Locales.EN_GB]
  },
  cache
)

const formats = {}

export { locales, translations, formats, intl }
