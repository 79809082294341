import {
  CustomerStatistic,
  CustomerStatisticData
} from "app/types/customerStatistic"
import State from "../state"

export const isLoading = (state: State): boolean =>
  state.customerStatistics.loading
export const getCustomerStatistics = (state: State): CustomerStatistic =>
  state.customerStatistics.data

export const getCustomerStatisticsAdminNeeded = (
  state: State
): CustomerStatisticData[] => {
  const customerStatistics: CustomerStatistic = getCustomerStatistics(state)
  return customerStatistics.data.filter(
    (customerStatisticsData: CustomerStatisticData) => {
      return (
        customerStatisticsData.adminsNeeded ||
        customerStatisticsData.activeAccounts === 0
      )
    }
  )
}

export const getCustomerStatisticsLinkExpired = (
  state: State
): CustomerStatisticData[] => {
  const customerStatistics: CustomerStatistic = getCustomerStatistics(state)
  return customerStatistics.data.filter(
    (customerStatisticsData: CustomerStatisticData) => {
      return (
        customerStatisticsData.invitationExpired > 0 &&
        !customerStatisticsData.adminsNeeded
      )
    }
  )
}

export const getCustomerStatisticsOnbardingIncomplete = (
  state: State
): CustomerStatisticData[] => {
  const customerStatistics: CustomerStatistic = getCustomerStatistics(state)
  return customerStatistics.data.filter(
    (customerStatisticsData: CustomerStatisticData) => {
      return (
        customerStatisticsData.onboardingIncomplete > 0 &&
        !customerStatisticsData.adminsNeeded &&
        customerStatisticsData.invitationExpired === 0
      )
    }
  )
}

export const getCustomerStatisticsActiveAccounts = (
  state: State
): CustomerStatisticData[] => {
  const customerStatistics: CustomerStatistic = getCustomerStatistics(state)
  return customerStatistics.data.filter(
    (customerStatisticsData: CustomerStatisticData) => {
      return (
        customerStatisticsData.activeAccounts > 0 &&
        customerStatisticsData.invitationExpired === 0
      )
    }
  )
}
