import { theme, Link } from "@paudigital/wdp-components"
import ActiveIndicator from "app/components/ActiveIndicator"
import styled from "styled-components"

export const Container = styled.div`
  background-color: ${theme.colors.neutral.lightGrey};
  border-radius: 4px;
  padding: 1.6rem;
  margin-top: 1rem;
`

export const ContentContainer = styled.div`
  background-color: ${theme.colors.neutral.white};
  border-radius: 4px;
  padding: 2.4rem;
  margin-bottom: 3.1rem;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DescriptionContainer = styled.div`
  overflow-wrap: break-word;
`

export const ActionContainer = styled.div``

export const TranslationsTitle = styled.h3`
  font-size: ${theme.fontSizes.h5};
  line-height: ${theme.lineHeights.h5};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 0.8rem;
`

export const TranslationsDescription = styled.p`
  font-size: ${theme.fontSizes.paragraph};
  line-height: ${theme.lineHeights.paragraph};
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colors.neutral.black};
  margin-bottom: 0.8rem;
`

export const TranslationsDownloadLink = styled.a`
  color: ${theme.colors.green.contrast};
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 3rem;

  & > div:last-child {
    margin-left: 2rem;
  }
`

export const SpinnerContainer = styled.div`
  margin-left: 50%;
  margin-right: 50%;
`

export const FileUploadContainer = styled.div`
  width: 43.3rem;
`
export const StatusContainer = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`
export const StatusTitle = styled.span`
  font-weight: ${theme.fontWeights.regular};
  font-size: ${theme.fontSizes.h5};
  color: #4d4d4d;
`

export const TargetWarning = styled.p`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  color: ${theme.colors.system.error};
  padding-bottom: 1rem;
`

export const StatusIndicator = styled(ActiveIndicator)`
  margin-left: 1.2rem;
  font-size: ${theme.fontSizes.paragraph};
  height: 2.4rem;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin-left: 1rem;
  font-size: ${theme.fontSizes.h6};
`

export const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
`
export const Title = styled.h2`
  font-size: ${theme.fontSizes.h5};
  line-height: ${theme.lineHeights.h5};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 2rem;
  margin-top: 1.2rem;
`
export const CampaignDetailsContainer = styled.div`
  margin-bottom: 1.5rem;
`

export const CampaignDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const TranslationsSubTitle = styled.h3`
  font-size: ${theme.fontSizes.h6};
  line-height: ${theme.lineHeights.h5};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 0.8rem;
`
