import styled from "styled-components"

export const DataContainer = styled.div`
  max-width: 99.8rem;
  margin: 0 auto;
`

export const CampaignsWrapper = styled.div`
  width: 100%;
`

