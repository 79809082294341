export const contactPersonBasetranslation =
  "cms.modal.contactPerson.language.select."

export const languageCodes = [
  "nl-BE",
  "nl-NL",
  "en-GB",
  "fr-FR",
  "de-DE",
  "ro-RO"
]

export const getLanguage = (code: string) =>
  `${contactPersonBasetranslation}${code}`
