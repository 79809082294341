import React from "react"
import { useTranslate } from "hooks/translate"
import {
  DeleteModalTextBold,
  DeleteModalTextLight,
  RadioGroupWrapper
} from "../style"
import {BaseDeleteModal, Radio, RadioGroup } from "@paudigital/wdp-components"
import { useSelector } from "react-redux"
import * as deleteCpSelectors from "app/redux/deleteContactPerson/deleteContactPerson.selectors"
import { deleteContactPerson } from "app/redux/deleteContactPerson/deleteContactPerson.actions"
import { useDispatch } from "react-redux"

type DeleteContactPersonSingleBuildingModalProps = {
  onCancel: () => void
}

type Props = DeleteContactPersonSingleBuildingModalProps

const DeleteContactPersonSingleBuildingModal = ({ onCancel }: Props) => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const [stillWorksAtCompany, setStillWorksAtCompany] = React.useState<
    string | undefined
  >(undefined)
  const loading = useSelector(deleteCpSelectors.isLoading)
  const contactPersonId = useSelector(deleteCpSelectors.getIdOfContactPerson)
  const email = useSelector(deleteCpSelectors.getEmailOfContactPerson)
  const companyName = useSelector(deleteCpSelectors.getCompanyName)
  const buildingsIdsToDeleteCpFrom = useSelector(
    deleteCpSelectors.getBuildingIdsToDeleteContactPersonFrom
  )

  const onDelete = () => {
    if (!contactPersonId || !stillWorksAtCompany) return
    dispatch(
      deleteContactPerson(
        contactPersonId,
        buildingsIdsToDeleteCpFrom,
        stillWorksAtCompany === "available" ? true : false
      )
    )
  }

  const handleRadioClick = (name: string) => {
    setStillWorksAtCompany(name)
  }

  return (
    <BaseDeleteModal
      title={translate("cms.modal.delete.seat.title")}
      cancelBtnText={translate("cms.modal.delete.seat.cancel")}
      deleteBtnText={translate("cms.modal.delete.seat.delete.account")}
      deleteDisabled={
        loading ||
        stillWorksAtCompany === undefined ||
        contactPersonId === undefined
      }
      onCancel={onCancel}
      onDelete={onDelete}
    >
      <>
        <DeleteModalTextLight marginBottom="2.4rem">
          {translate("cms.modal.delete.seat.description", { email })}
        </DeleteModalTextLight>
        <DeleteModalTextBold marginBottom="1.6rem">
          {translate("cms.modal.delete.seat.action", { companyName })}
        </DeleteModalTextBold>
        <RadioGroupWrapper>
          <RadioGroup contentCentered={true}>
            <Radio
              checked={stillWorksAtCompany === "available"}
              disabled={false}
              onClick={handleRadioClick}
              label={translate("cms.modal.delete.seat.yes")}
              name={"available"}
            />
            <Radio
              checked={stillWorksAtCompany === "notAvailable"}
              disabled={false}
              onClick={handleRadioClick}
              label={translate("cms.modal.delete.seat.no")}
              name={"notAvailable"}
            />
          </RadioGroup>
        </RadioGroupWrapper>
      </>
    </BaseDeleteModal>
  )
}

export default DeleteContactPersonSingleBuildingModal
