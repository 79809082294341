import { RentedBuildingWithContacRoles } from "app/types/rentedBuildingWithContactRoles"

export enum Types {
  GET = "GET_CONTACTPERSON_CONTACT_ROLES",
  GET_SUCCESS = "GET_CONTACTPERSON_CONTACT_ROLES_SUCCESS",
  GET_FAIL = "GET_CONTACTPERSON_CONTACT_ROLES_FAIL",
  CLEAR = "CLEAR_CONTACTPERSON_CONTACT_ROLES"
}

export type Action =
  | GetContactPersonContactRolesAction
  | GetContactPersonContactRolesSuccessAction
  | GetContactPersonContactRolesFailAction
  | ClearContactPersonContactRolesFailAction

export type GetContactPersonContactRolesAction = {
  payload: { contactPersonId: string; entityId: string }
  type: Types.GET
}

export type GetContactPersonContactRolesSuccessAction = {
  payload: { data: RentedBuildingWithContacRoles[] }
  type: Types.GET_SUCCESS
}

export type GetContactPersonContactRolesFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_FAIL
}

export type ClearContactPersonContactRolesFailAction = {
  type: Types.CLEAR
}

export function getContactPersonContactRoles(
  entityId: string,
  contactPersonId: string
): Action {
  return {
    type: Types.GET,
    payload: { entityId, contactPersonId }
  }
}

export function getContactPersonContactRolesSuccess(
  data: RentedBuildingWithContacRoles[]
): Action {
  return {
    payload: { data },
    type: Types.GET_SUCCESS
  }
}

export function getContactPersonContactRolesFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function clearContactPersonContactRoles(): Action {
  return {
    type: Types.CLEAR
  }
}
