import { DeleteContactPersonFlows } from "app/types/deleteContactPersonFlow"
import { RentedBuildingWithContactPersons } from "app/types/rentedBuildingWithContactPersons"

export enum Types {
  DETERMINE = "DETERMINE_DELETE_FLOW",
  DETERMINE_SUCCESS = "DETERMINE_DELETE_FLOW_SUCCESS",
  DETERMINE_FAIL = "DETERMINE_DELETE_FLOW_FAIL",
  RESET = "RESET_DELETE_FLOW",
  DELETE = "DELETE_CONTACT_PERSON",
  DELETE_SUCCESS = "DELETE_CONTACT_PERSON_SUCCESS",
  DELETE_FAIL = "DELETE_CONTACT_PERSON_FAIL",
}

export type Action = 
  | DetermineDeleteFlowAction
  | DetermineDeleteFlowSuccessAction
  | DetermineDeleteFlowFailAction
  | DeleteContactPersonAction
  | DeleteContactPersonSuccessAction
  | DeleteContactPersonFailAction
  | ResetDeleteFlowAction

export type DetermineDeleteFlowAction = {
  type: Types.DETERMINE
  payload: {
    entityId: string
    contactPersonId: string
  }
}

export type DetermineDeleteFlowSuccessAction = {
  type: Types.DETERMINE_SUCCESS
  payload: { 
    flow: DeleteContactPersonFlows
    entityId: string
    contactPersonId: string
    companyName: string
    buildings: RentedBuildingWithContactPersons[]
  }
}

export type DetermineDeleteFlowFailAction = {
  type: Types.DETERMINE_FAIL
  payload: { 
    errors: Record<string, unknown> 
  }
}

export type DeleteContactPersonAction = {
  type: Types.DELETE,
  payload: {
    contactPersonId: string
    rentedBuildingIds: string[]
    stillEmployed: boolean
  }
}

export type DeleteContactPersonSuccessAction = {
  type: Types.DELETE_SUCCESS
}

export type DeleteContactPersonFailAction = {
  type: Types.DELETE_FAIL
  payload: {
    errors: Record<string, unknown> 
  }
}

export type ResetDeleteFlowAction = {
  type: Types.RESET
}

export function determineDeleteFlow(
  entityId: string,
  contactPersonId: string
): Action {
  return {
    type: Types.DETERMINE,
    payload: {
      entityId,
      contactPersonId
    }
  }
}

export function determineDeleteFlowSuccess(
  flow: DeleteContactPersonFlows,
  entityId: string,
  contactPersonId: string,
  companyName: string,
  buildings: RentedBuildingWithContactPersons[]
): Action {
  return {
    type: Types.DETERMINE_SUCCESS,
    payload: {
      flow,
      entityId,
      contactPersonId,
      companyName,
      buildings
    }
  }
}

export function determineDeleteFlowFail(
  errors: Record<string, unknown>
): Action {
  return {
    type: Types.DETERMINE_FAIL,
    payload: {
      errors
    }
  }
}

export function deleteContactPerson(
  contactPersonId: string,
  rentedBuildingIds: string[],
  stillEmployed: boolean
): Action {
  return {
    type: Types.DELETE,
    payload: {
      contactPersonId,
      rentedBuildingIds,
      stillEmployed
    }
  }
}

export function deleteContactPersonSuccess(): Action {
  return {
    type: Types.DELETE_SUCCESS
  }
}

export function deleteContactPersonFail(
  errors: Record<string, unknown>
): Action {
  return {
    type: Types.DELETE_FAIL,
    payload: {
      errors
    }
  }
}

export function resetDeleteFlow(): Action {
  return {
    type: Types.RESET
  }
}
