import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4rem;
  text-align: center;
`

export const InfoContainer = styled.div`
  width: 50rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  div {
    width: 100%;
  }

  & > div:last-child {
    margin-left: 2rem;
  }
  }
`

export const SvgContainer = styled.div``

export const Title = styled.h6`
  font-size: ${theme.fontSizes.h6};
  line-height: ${theme.lineHeights.h6};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
`
export const Description = styled.p`
  font-size: ${theme.fontSizes.paragraph};
  line-height: ${theme.lineHeights.paragraph};
  color: ${theme.colors.neutral.darkerGrey};
  text-align: center;
  margin-bottom: 3rem;
`
