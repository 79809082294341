import { useLocation,    useResolvedPath } from "react-router-dom"

export const useNavigation = () => {
  const location = useLocation()
  const resolvedPath = useResolvedPath(location.pathname)

  const isActive = (route: string): boolean => {
    const match = resolvedPath.pathname.match(route)
    if (match?.length) {
      return true
    }
    return false
  }

  return { isActive }
}
