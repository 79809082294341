import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledActiveIndicator = styled.span<{active: boolean}>`
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5000px;
  margin-right: 2rem;
  font-weight: ${theme.fontWeights.regular};
  padding: .4rem;
  padding-right: 1.4rem;
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.fontSizes.captionSmall};
  ${props => props.active ? `color: ${theme.colors.green.contrast};` :  `color: ${theme.colors.neutral.darkerGrey};` };
  ${props => props.active ? `background: rgba(2, 137, 1, 0.1);` :  `background: rgba(0, 0, 0, 0.1);` };
  &::before{
    border-radius: 5000px;
    content: ' ';
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: .8rem;
    ${props => props.active ? `background: rgb(2,137,1);` :  `background: rgb(121,121,125);` };
  }
`