import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  max-width: 99.8rem;
  margin: 0 auto;
`

export const LanguageFormContainer = styled.div`
  background-color: ${theme.colors.neutral.lightGrey};
  border-radius: 4px;
  padding: 1.6rem;
`
