import { getEnvVariable } from "../utils/getEnvVariable"

export const AUTH0_DOMAIN = getEnvVariable("REACT_APP_AUTH0_DOMAIN")
export const CLIENT_ID: string = getEnvVariable("REACT_APP_CLIENT_ID")
export const BASE_URL: string = getEnvVariable("REACT_APP_BASE_URL")
export const REDIRECT_URI: string = getEnvVariable("REACT_APP_REDIRECT_URI")
export const AUDIENCE: string = getEnvVariable("REACT_APP_AUTH0_AUDIENCE")
export const MYWDP_CLIENT_ID: string = getEnvVariable("REACT_APP_MYWDP_CLIENT_ID")
export const MYWDP_REDIRECT_URI: string = getEnvVariable("REACT_APP_MYWDP_REDIRECT_URI")
export const AUTH0_CONNECTION: string = getEnvVariable(
  "REACT_APP_AUTH0_CONNECTION"
)
export const RENTAL_API_URL: string = getEnvVariable("REACT_APP_RENTAL_API_URL")

export const API_URLS = {
  rentalApi: RENTAL_API_URL
}
