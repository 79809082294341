import {
  Campaign,
  CampaignCandidates,
  CampaignsPaginationData
} from "app/types/campaign"
import State from "../state"

export const isLoading = (state: State): boolean => state.campaign.loading

export const getBannerPictureUrl = (state: State): string | undefined => {
  return state.campaign.bannerPictureUrl
}

export const getCampaign = (state: State): Campaign => state.campaign.campaign

export const getCampaignById = (
  state: State,
  id: string
): Campaign | undefined =>
  state.campaign.data.find(campaign => campaign.id === id)

export const getCampaigns = (state: State): Campaign[] => state.campaign.data

export const getCampaignsPaginationData = (
  state: State
): CampaignsPaginationData => state.campaign.paginationData

export const getCampaignCandidates = (
  state: State
): CampaignCandidates[] => state.campaign.campaignCandidates ?? []

export const getActiveCampaignCandidates = (
  state: State
): CampaignCandidates[] =>
  state.campaign.campaignCandidates?.filter(c => c.activeInCampaign === true) ?? []

export const getActiveCampaignCandidateIds = (
  state: State
): string[] | undefined => {
  const activeCandidates = getActiveCampaignCandidates(state)
  return activeCandidates?.map(c => c.entityId)
}

export const getCampaignLoading = (state: State) => state.campaign.loading
