import { Locales } from "app/i18n/config"
import {
  InviteContactPerson,
  RentedBuildingContactRoles
} from "app/types/contactPerson"
import { array, object, string } from "yup"

const getDomain = (email: string): string =>
  email.substring(email.indexOf("@") + 1)

export const getWhitelistedDomains = (whitelistedEmails: string[]) => {
  return whitelistedEmails.map(email => getDomain(email))
}

export const isDomainWhitelisted = (
  whitelistedDomains: string[],
  email: string
) => {
  if (!email) return true
  const emailDomain = getDomain(email.toLowerCase())
  if (whitelistedDomains.length === 0) return false
  return (
    whitelistedDomains.length !== 0 &&
    whitelistedDomains.some(d => d.toLowerCase() === emailDomain)
  )
}

export const validationSchema = object({
  firstName: string().required("portal.input.required"),
  lastName: string().required("portal.input.required"),
  email: string()
    .email("portal.contactPerson.input.email.error")
    .required("portal.input.required"),
  contactRolesPerRentedBuilding: array().test(
    "roles",
    "Must have at least one role",
    (contactRolesPerRentedBuilding: any) => {
      return contactRolesPerRentedBuilding.some((c: any) => {
        return c.roles.length > 0
      })
    }
  )
})

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contactRolesPerRentedBuilding: [] as RentedBuildingContactRoles[],
  languagePreference: Locales.EN_GB
}

export const removeBuildingsWithoutRolesFromContactPerson = (
  user: InviteContactPerson
) => {
  const contactRolesPerRentedBuilding = user.contactRolesPerRentedBuilding.filter(
    r => r.roles.length !== 0
  )
  return {
    ...user,
    contactRolesPerRentedBuilding
  }
}
