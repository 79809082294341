import { ContactPerson } from "app/types/contactPerson"
import { Action, Types } from "./contactPerson.actions"

export type ContactPersonState = {
  contactPerson: ContactPerson | null
  data: ContactPerson[]
  errors?: Record<string, unknown>
  loading: boolean
  success: boolean
}

const initialState: ContactPersonState = {
  contactPerson: null,
  data: [],
  errors: undefined,
  success: false,
  loading: false
}

export default function contactPersonReducer(state = initialState, action: Action): ContactPersonState {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.GET_BY_ID:
      return {
        ...state,
        loading: true
      }

    case Types.GET_BY_ID_SUCCESS:
      return {
        ...state,
        contactPerson: action.payload.data,
        loading: false
      }

    case Types.GET_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.PATCH:
      return {
        ...state,
        loading: true
      }

    case Types.PATCH_SUCCESS:
      return {
        ...state,
        contactPerson: action.payload.data,
        loading: false
      }

    case Types.PATCH_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.PATCH_ONBOARDING:
      return {
        ...state,
        loading: true
      }

    case Types.PATCH_ONBOARDING_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.PATCH_ONBOARDING_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.PASSWORD_RESET:
      return {
        ...state,
        loading: true
      }

    case Types.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false
      }

    case Types.PASSWORD_RESET_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        errors: action.payload.errors
      }

    case Types.RESEND_INVITE:
      return {
        ...state,
        loading: true
      }

    case Types.RESEND_INVITE_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false
      }

    case Types.RESEND_INVITE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        errors: action.payload.errors
      }

    case Types.CLEAR_SUCCESS:
      return {
        ...state,
        success: false
      }

    default:
      return state
  }
}
