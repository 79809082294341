import { AxiosPromise } from "axios"
import { rentalApi } from "./api"
import { Campaign } from "app/types/campaign"
import { QueryParams } from "app/redux/campaign/campaign.actions"

export const postCampaign = (data: Campaign): AxiosPromise => {
  return rentalApi(`/cms/campaigns`, {
    method: "POST",
    data
  })
}

export const putCampaign = (data: Campaign): AxiosPromise => {
  return rentalApi(`/cms/campaigns/${data.id}`, {
    method: "PUT",
    data
  })
}

export const getCampaign = (campaignId: string): AxiosPromise => {
  return rentalApi(`/cms/campaigns/${campaignId}`, {
    method: "GET"
  })
}

export const getCampaigns = (params: QueryParams): AxiosPromise => {
  return rentalApi(`/cms/campaigns`, {
    method: "GET",
    params
  })
}

export const deactivateAll = (): AxiosPromise => {
  return rentalApi(`/cms/campaigns/deactivate`, {
    method: "PATCH"
  })
}

export const getCampaignCandidates = (campaignId?: string): AxiosPromise => {
  return rentalApi(`cms/campaign_candidates`, {
    method: "GET",
    params: {
      campaignId
    }
  })
}
