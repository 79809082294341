import customerStatistics from "../redux/customerStatistics/customerStatistics.reducer"
import customerWithRentedBuildings from "./customerWithRentedBuildings/customerWithRentedBuildings.reducer"
import rentedBuildingContactPersons from "./rentedBuildingContactPerson/rentedBuildingContactPerson.reducer"
import contactPerson from "./contactPerson/contactPerson.reducer"
import customerContactPersons from "./customerContactPersons/customerContactPersons.reducer"
import contactPersonContactRoles from "./contactPersonContactRoles/contactPersonContactRoles.reducer"
import appEvents from "./appEvents/appEvents.reducer"
import deleteContactPerson from "./deleteContactPerson/deleteContactPerson.reducer"
import token from "./token/token.reducer"
import gaEvents from "./gaEvents/gaEvents.reducer"
import campaign from "app/redux/campaign/campaign.reducer"
import campaignPicture from "app/redux/campaignPicture/campaignPicture.reducer"
import rentedBuilding from "app/redux/rentedBuilding/rentedBuilding.reducer"
import dataStories from "./dataStories/dataStories.reducer"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  customerStatistics,
  customerWithRentedBuildings,
  rentedBuildingContactPersons,
  contactPerson,
  customerContactPersons,
  contactPersonContactRoles,
  appEvents,
  deleteContactPerson,
  token,
  gaEvents,
  campaign,
  campaignPicture,
  rentedBuilding,
  dataStories
}
