import { Action, Types } from "./customerContactPersons.actions"
import { ContactPerson } from "app/types/contactPerson"

export type CustomerContactPersonsState = {
  data: ContactPerson[]
  errors?: Record<string, unknown>
  loading: boolean
}

const initialState: CustomerContactPersonsState = {
  data: [],
  errors: undefined,
  loading: false
}

export default function customerContactPersonsReducer(state = initialState, action: Action) {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }
    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }
    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }
    default:
      return state
  }
}

