import { PossibleContactRoles } from "app/enums/roles"
import { RentedBuildingContactRoles } from "app/types/contactPerson"
import React from "react"
import { BuildingWrapper } from "./style"
import { GroupedCheckbox, InputError } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useSelector } from "react-redux"
import * as customerWithRentedBuildingSelector from "app/redux/customerWithRentedBuildings/customerWithRentedBuildings.selectors"

type Props = {
  data: RentedBuildingContactRoles[]
  error: boolean
  onChange: (val: boolean, name: string, rentedBuildingId: string) => void
}

const SingleRentedBuilding = ({ error, onChange, data }: Props) => {
  const { translate } = useTranslate()
  const customerWithRentedBuildings = useSelector(
    customerWithRentedBuildingSelector.getCustomerWithRentedBuildings
  )
  if (!customerWithRentedBuildings?.rentedBuildings) return null

  const handleChange = (val: boolean, name: string) => {
    onChange(val, name, customerWithRentedBuildings.rentedBuildings[0]?.id)
  }

  return (
    <>
      <BuildingWrapper>
        <GroupedCheckbox
          onChange={handleChange}
          data={Object.values(PossibleContactRoles).map(r => ({
            label: translate(
              `cms.modal.contactperson.roles.${r.replace(/\s/g, "")}`
            ),
            name: r,
            guidanceText: translate(
              `cms.modal.contactperson.roles.${r.replace(/\s/g, "")}.guidance`
            ),
            selected: data && data[0] && data[0].roles.includes(r)
          }))}
          title={translate("cms.modal.contactperson.title.facilities.label")}
        />
      </BuildingWrapper>
      {error && (
        <InputError
          errorMessage={translate("cms.modal.contactperson.roles.label")}
        />
      )}
    </>
  )
}

export default SingleRentedBuilding
