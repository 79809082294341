import { DeleteIcon, theme } from "@paudigital/wdp-components"
import React, { FormEvent } from "react"
import { ClearContentButtonContainer } from "./style"

type RestProps = {
  title: string
  handleClearAllFields: (e: FormEvent) => void
}

type Props = RestProps

const ClearContentButton = ({ handleClearAllFields, title }: Props) => {
  return (
    <ClearContentButtonContainer onClick={handleClearAllFields} type="button">
      <div>
        <DeleteIcon
          width="16"
          height="18.67"
          fill={theme.colors.system.moreInfo}
        />
      </div>
      <div>{title}</div>
    </ClearContentButtonContainer>
  )
}

export default ClearContentButton
