import React from "react"
import { Button, PopUp } from "@paudigital/wdp-components"
import {
  ConfirmationTitle,
  ConfirmationContainer,
  ConfirmationButtonsContainer,
  ConfirmationDescription
} from "./style"
import { useTranslate } from "hooks/translate"
import { useDispatch } from "react-redux"
import { remove } from "app/redux/appEvents/appEvents.actions"
import { postDataStoriesSending } from "app/redux/dataStories/dataStories.actions"
import { useAuth0 } from "@auth0/auth0-react"

const ConfirmationModal = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const { user } = useAuth0()

  const handleClose = () => dispatch(remove("sending-confirmation-modal"))
  const handleSend = () => {
    dispatch(
      postDataStoriesSending({
        sendDate: new Date(),
        sendUser: user?.name as string
      })
    )
    dispatch(remove("sending-confirmation-modal"))
  }
  return (
    <PopUp show onClose={handleClose}>
      <ConfirmationContainer>
        <ConfirmationTitle>
          {translate("cms.data-stories.confirmation-modal.title")}
        </ConfirmationTitle>
        <ConfirmationDescription>
          {translate("cms.data-stories.confirmation-modal.description")}
        </ConfirmationDescription>
        <ConfirmationButtonsContainer>
          <Button
            label={translate(
              "cms.data-stories.confirmation-modal.buttons.cancel"
            )}
            type="secondary"
            fullWidth
            onClick={handleClose}
          />
          <Button
            label={translate(
              "cms.data-stories.confirmation-modal.buttons.send"
            )}
            fullWidth
            onClick={handleSend}
          />
        </ConfirmationButtonsContainer>
      </ConfirmationContainer>
    </PopUp>
  )
}

export default ConfirmationModal
