import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const DataStoriesContainer = styled.div`
  margin: 1.4rem 0;
`

export const DescriptionText = styled.p`
  padding-top: 1.5rem;
`

export const DescriptionTextBold = styled.span`
  font-weight: ${theme.fontWeights.medium};
`
