import React, { Dispatch, SetStateAction } from "react"
import { DataGrid, GridSelectionModel } from "@mui/x-data-grid"
import { CampaignCandidates } from "app/types/campaign"
import { useEffect } from "react"
import { useMemo } from "react"
import { renderColumns } from "./config"
import { useTranslate } from "app/hooks/translate"
import {
  ContentContainer,
  HeaderContainer,
  DescriptionContainer,
  TranslationsDescription,
  TranslationsTitle
} from "../style"
import { StyledStaticNotification, stylesForDataGrid } from "./style"
import { GlobalStyles } from "@mui/material"

type TargetUsersTableProps = {
  loading: boolean
  values: CampaignCandidates[] | undefined
  setSelectedIds: (campaignCandidates: CampaignCandidates[]) => void
  setHasCandidatesChanged: Dispatch<SetStateAction<boolean>>
}

const TargetUsersTable = ({
  loading,
  values,
  setSelectedIds,
  setHasCandidatesChanged
}: TargetUsersTableProps) => {
  const { translate } = useTranslate()

  const inConstruction = translate(
    "cms.marketing-dashboard.campaign-detail.candidates-table.inConstruction"
  )
  const available = translate(
    "cms.marketing-dashboard.campaign-detail.candidates-table.availability.available"
  )
  const alreadySelected = translate(
    "cms.marketing-dashboard.campaign-detail.candidates-table.availability.selected"
  )

  const mappedRows = useMemo(
    () =>
      values?.map(value => {
        const availableText = value.activeInCampaign
          ? alreadySelected
          : available

        return {
          ...value,
          id: value.entityId,
          buildingYear: value.buildYear
            ? new Date(value.buildYear).getFullYear()
            : inConstruction,
          availability:
            value.inCampaign && !value.activeInCampaign
              ? value.availability
              : availableText
        }
      }) || [],
    [values, inConstruction, alreadySelected, available]
  )

  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>(() =>
      mappedRows.filter(row => row.activeInCampaign === true).map(row => row.id)
    )

  const isShowOverlapError = mappedRows.some(
    row =>
      selectionModel.includes(row.id) &&
      row.activeInCampaign === false &&
      row.inCampaign === true
  )

  const headerTranslation = {
    sourceId: translate(
      "cms.marketing-dashboard.campaign-detail.candidates-table.sourceId"
    ),
    name: translate(
      "cms.marketing-dashboard.campaign-detail.candidates-table.name"
    ),
    buildingYear: translate(
      "cms.marketing-dashboard.campaign-detail.candidates-table.buildingYear"
    ),
    country: translate(
      "cms.marketing-dashboard.campaign-detail.candidates-table.country"
    ),
    availability: translate(
      "cms.marketing-dashboard.campaign-detail.candidates-table.availability"
    )
  }

  const handleSelectionModel = (e: typeof selectionModel) => {
    setSelectionModel(e)
    const selectedIds = new Set(e)
    const newIds = mappedRows.filter(row => selectedIds.has(row.id))
    setSelectedIds(newIds)
    setHasCandidatesChanged(true)
  }

  useEffect(() => {
    setSelectionModel(
      mappedRows.filter(row => row.activeInCampaign === true).map(row => row.id)
    )
  }, [mappedRows])

  return (
    <ContentContainer>
      <HeaderContainer>
        <DescriptionContainer>
          <TranslationsTitle>
            {translate(
              "cms.marketing-dashboard.campaign-detail.target-users-title"
            )}
          </TranslationsTitle>
          <TranslationsDescription>
            {translate(
              "cms.marketing-dashboard.campaign-detail.target-users-description"
            )}
          </TranslationsDescription>
        </DescriptionContainer>
      </HeaderContainer>
      <GlobalStyles styles={stylesForDataGrid} />
      <DataGrid
        rows={mappedRows}
        columns={renderColumns(headerTranslation)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        autoHeight
        loading={loading}
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionModel}
        checkboxSelection
      />
      {isShowOverlapError && (
        <StyledStaticNotification
          description={translate(
            "cms.marketing-dashboard.campaign-detail.candidates-warning"
          )}
          variant="warning"
        />
      )}
    </ContentContainer>
  )
}

export default TargetUsersTable
