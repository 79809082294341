export enum Types {
  CREATE = "CREATE_GA_EVENT",
  CREATE_SUCCESS = "CREATE_GA_EVENT_SUCCESS",
  CREATE_FAIL = "CREATE_GA_EVENT_FAIL"
}

export enum PossibleGAEvents {
  DELETE_ACCOUNT = "delete_account",
  CREATE_ACCOUNT = "create_account",
  PAGE_VIEW = "page_view"
}

export type ConfigParams = { page_location?: string; page_title?: string }

export type EventParams = {
  onboarding_status?: string
}

type Params = ConfigParams | EventParams

export type Payload = {
  eventName: PossibleGAEvents
  params?: Params
}

export type Action =
  | CreateGAEventAction
  | CreateGAEventActionSuccessAction
  | CreateGAEventActionFailAction

export type CreateGAEventAction = {
  type: Types.CREATE
  payload: Payload
}

export type CreateGAEventActionSuccessAction = {
  type: Types.CREATE_SUCCESS
  payload: Payload
}

export type CreateGAEventActionFailAction = {
  type: Types.CREATE_FAIL
  payload: {
    errors: Record<string, unknown>
  }
}

export function createGAEvent(
  eventName: PossibleGAEvents,
  params?: Params
): Action {
  return {
    type: Types.CREATE,
    payload: {
      eventName,
      params
    }
  }
}

export function createGAEventSuccess(payload: Payload): Action {
  return {
    type: Types.CREATE_SUCCESS,
    payload
  }
}

export function createGAEventFail(errors: Record<string, unknown>): Action {
  return {
    type: Types.CREATE_FAIL,
    payload: {
      errors
    }
  }
}
