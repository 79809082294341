import { useAuth0 } from "@auth0/auth0-react"
import { Modules } from "app/enums/modules"
import { UserGroups } from "app/enums/userGroups"
import { useMemo } from "react"

export const permissions = {
  [Modules.USER_MANAGEMENT]: [UserGroups.SG_MYWDP_GR_CMS],
  [Modules.MARKETING_DASHBOARD]: [UserGroups.SG_MYWDP_MARKETING_CMS],
  [Modules.DATA_STORIES]: [UserGroups.SG_MYWDP_MARKETING_CMS]
}

export const usePermission = () => {
  const { user } = useAuth0()
  const groups = useMemo(() => {
    if (user) {
      return user["https://my.wdp.eu/groups"]
    }
  }, [user])

  const hasPermission = (module: Modules): boolean => {
    if (groups?.length) {
      const permissionsForModule = permissions[module]
      return permissionsForModule.some((p: UserGroups) => groups.includes(p))
    }
    return false
  }

  const isMarketing = useMemo(() => {
    if (groups?.length) {
      return groups.includes(UserGroups.SG_MYWDP_MARKETING_CMS)
    }
  }, [groups])

  const isUserManagement = useMemo(() => {
    if (groups?.length) {
      return groups.includes(UserGroups.SG_MYWDP_GR_CMS)
    }
  }, [groups])

  return { hasPermission, isMarketing, isUserManagement }
}
