import { AvatarIcon } from "@paudigital/wdp-components"
import { Link } from "@paudigital/wdp-components"
import React from "react"
import { UserContainer, UserInfo, UserName } from "./style"


type Props = {
  name: string
  description: string,
  onLogout: () => void
}

const LoggedInUser = ({
  name,
  description,
  onLogout
}: Props) => {
  return (
    <UserContainer>
      <AvatarIcon height="2.5rem" width="2.5rem"/>
      <UserInfo>
      <UserName>{name}</UserName>
        <Link type="logout" value={description} onClick={onLogout}/>
      </UserInfo>
    </UserContainer>
  )

}

export default LoggedInUser
