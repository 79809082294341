import { PossibleContactRoles } from "app/enums/roles"
import { ContactPerson } from "app/types/contactPerson"
import { RentedBuildingWithContactPersons } from "app/types/rentedBuildingWithContactPersons"
import { checkIfDatesAre24HoursApart } from "./time.utils"

export const getAmountOfRentedBuildingsWithoutAdmins = (
  rentedBuildings: RentedBuildingWithContactPersons[]
): number => {
  const adminsNeeded: RentedBuildingWithContactPersons[] = rentedBuildings.filter(
    (rentedBuilding: RentedBuildingWithContactPersons) => {
      const admins: ContactPerson[] = rentedBuilding.contactPersons.filter(
        (contactPerson: ContactPerson) => {
          return (
            contactPerson.roles &&
            contactPerson.roles.includes(PossibleContactRoles.ADMIN)
          )
        }
      )
      return admins.length === 0
    }
  )
  return adminsNeeded.length
}

export const determineIfRentedBuildingContactPersonsHaveExpiredInvitations = (
  rentedBuildings: RentedBuildingWithContactPersons[]
): boolean => {
  const expiredInvitations = rentedBuildings.filter(
    (rentedBuilding: RentedBuildingWithContactPersons) => {
      const expiredPersons: ContactPerson[] = rentedBuilding.contactPersons.filter(
        (contactPerson: ContactPerson) => {
          return (
            contactPerson.invitedAt &&
            checkIfDatesAre24HoursApart(
              contactPerson.invitedAt,
              new Date().toISOString()
            ) &&
            !contactPerson.onboardedAt
          )
        }
      )
      const admins: ContactPerson[] = rentedBuilding.contactPersons.filter(
        (contactPerson: ContactPerson) => {
          return (
            contactPerson.roles &&
            contactPerson.roles.includes(PossibleContactRoles.ADMIN)
          )
        }
      )
      return expiredPersons.length > 0 && admins.length > 0
    }
  )
  return expiredInvitations.length > 0
}
