import { LastSendingDataStories } from "app/types/dataStories"
import { AxiosPromise } from "axios"
import { rentalApi } from "./api"

export const getDataStoriesLastSending = (): AxiosPromise => {
  return rentalApi(`cms/data_stories_history`, {
    method: "GET"
  })
}

export const postDataStoriesSending = (
  data: LastSendingDataStories
): AxiosPromise => {
  return rentalApi(`cms/data_stories_history`, {
    method: "POST",
    data
  })
}

export const postDataStoriesNewsLettersSending = (): AxiosPromise => {
  return rentalApi(`cms/contact_persons/newsletter`, {
    method: "POST"
  })
}
