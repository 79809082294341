import styled from "styled-components"

export const SubmitButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  button {
    margin-right: 2.5rem;
  }
`
