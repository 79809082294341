import React, { useCallback, useMemo } from "react"
import { appEventMappings } from "./eventMappings"
import { AppEventMapping } from "./types"
import { AppEvent as AppEventType } from "app/redux/appEvents/types"
import * as appEventsActions from "app/redux/appEvents/appEvents.actions"
import { useDispatch } from "react-redux"

type Props = AppEventType

export const AppEvent = ({
  props,
  eventName,
  uniqueIdentifier
}: Props): JSX.Element => {
  const dispatch = useDispatch()
  const appEventMapping = useMemo(
    () =>
      appEventMappings.find((aem: AppEventMapping) => aem.event === eventName),
    [eventName]
  )
  const handleOnClose = useCallback(() => {
    dispatch(appEventsActions.remove(uniqueIdentifier))
  }, [dispatch, uniqueIdentifier])

  if (!appEventMapping) {
    return <> </>
  }

  const { component: Component } = appEventMapping
  return <Component onClose={handleOnClose} {...props} />
}
