import { CustomerStatistic } from "app/types/customerStatistic"

export enum Types {
  GET = "CUSTOMER_STATISTICS_GET",
  GET_SUCCESS = "CUSTOMER_STATISTICS_GET_SUCCESS",
  GET_FAIL = "CUSTOMER_STATISTICS_GET_FAIL"
}

export type QueryParams = {
  page: number
  perPage: number
  q?: string
  contract_start_date?: string | null | undefined
  contract_end_date: string
}

export type Action =
  | GetCutomerStatisticsAction
  | GetCutomerStatisticsSuccessAction
  | GetCutomerStatisticsFailAction

export type GetCutomerStatisticsAction = {
  type: Types.GET
  payload: QueryParams
}

export type GetCutomerStatisticsSuccessAction = {
  type: Types.GET_SUCCESS
  payload: { data: CustomerStatistic }
}

export type GetCutomerStatisticsFailAction = {
  type: Types.GET_FAIL
  payload: { errors: Record<string, unknown> }
}

export function getCustomerStatistics(payload: QueryParams): Action {
  return { type: Types.GET, payload }
}

export function getCustomerStatisticsSuccess(data: CustomerStatistic): Action {
  return { type: Types.GET_SUCCESS, payload: { data } }
}

export function getCustomerStatisticsFail(
  errors: Record<string, unknown>
): Action {
  return { type: Types.GET_FAIL, payload: { errors } }
}

