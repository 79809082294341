import { Button, ToolTip } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import React from "react"

type SubmitButtonsProps = {
  setCampaignInactive: () => void
  setCampaignActive: () => void
  valuesHaveChanged: boolean
  active: boolean
  hasErrors: boolean
}

type Props = SubmitButtonsProps

const PutCampaignButtons = ({
  valuesHaveChanged,
  active,
  setCampaignInactive,
  setCampaignActive,
  hasErrors
}: Props) => {
  const { translate } = useTranslate()
  return (
    <>
      {active === true && (
        <Button
          label={translate(
            "cms.marketing-dashboard.campaign-detail.deactivate"
          )}
          onClick={setCampaignInactive}
        />
      )}
      {active === false && (
        <Button
          label={translate("cms.marketing-dashboard.campaign-detail.activate")}
          onClick={setCampaignActive}
        />
      )}
      {valuesHaveChanged && hasErrors && (
        <>
          {!active && (
            <ToolTip
              value={translate(
                "cms.marketing-dashboard.campaign-detail.save-error-tooltip"
              )}
            >
              <Button
                label={translate(
                  "cms.marketing-dashboard.campaign-detail.save-deactivate"
                )}
                type="secondary"
                disabled={hasErrors}
                onClick={setCampaignInactive}
              />
            </ToolTip>
          )}
          <ToolTip
            value={translate(
              "cms.marketing-dashboard.campaign-detail.save-error-tooltip"
            )}
          >
            <Button
              label={translate(
                "cms.marketing-dashboard.campaign-detail.save-activate"
              )}
              type="primary"
              disabled={hasErrors}
              onClick={setCampaignActive}
            />
          </ToolTip>
        </>
      )}
      {valuesHaveChanged && !hasErrors && (
        <>
          {!active && (
            <Button
              label={translate(
                "cms.marketing-dashboard.campaign-detail.save-deactivate"
              )}
              type="secondary"
              disabled={hasErrors}
              onClick={setCampaignInactive}
            />
          )}
          <Button
            label={translate(
              "cms.marketing-dashboard.campaign-detail.save-activate"
            )}
            type="primary"
            disabled={hasErrors}
            onClick={setCampaignActive}
          />
        </>
      )}
    </>
  )
}

export default PutCampaignButtons
