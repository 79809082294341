import { Campaign } from "app/types/campaign"
import React from "react"
import { CampaignCard } from "./CampaignCard"
import { CampaignsWrapper } from "./style"

export type CampaignsState = {
    campaigns: Campaign[]
}

export const Campaigns = ({campaigns}: CampaignsState) => {
    return (
        <CampaignsWrapper>
          {
            campaigns.map(campaign => {
             return <CampaignCard key={campaign.id} campaign={campaign}/>
            })
          }
        </CampaignsWrapper>
      )
}

