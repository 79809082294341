import React, { useMemo } from "react"
import { Button } from "@paudigital/wdp-components"
import {
  DropdownContainer,
  RentedBuildingConfigContainer,
  RentedBuildingSeatOverviewWrapper,
  StyledDropDown,
  Subtitle
} from "./style"
import { useTranslate } from "app/hooks/translate"
import RentedBuildingSeat from "../RentedBuildingSeat"
import { PossibleContactRoles } from "app/enums/roles"
import SeatProposal from "../SeatProposal"
import { useDispatch, useSelector } from "react-redux"
import * as appEventsActions from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { RentedBuildingWithContactPersons } from "app/types/rentedBuildingWithContactPersons"
import * as selectors from "app/redux/customerWithRentedBuildings/customerWithRentedBuildings.selectors"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import * as actions from "app/redux/rentedBuilding/rentedBuilding.actions"
import { NoEnergyReasons } from "app/types/rentedBuildingConfig"
import { OnChangeValue } from "react-select"
export type RentedBuildingSeatOverviewProps = {
  rentedBuildingData: RentedBuildingWithContactPersons
}

type Props = RentedBuildingSeatOverviewProps

const RentedBuildingSeatOverview = ({ rentedBuildingData }: Props) => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const {
    contactPersons,
    entityId,
    minAllowedSeatCount,
    maxAllowedSeatCount
  } = rentedBuildingData

  const rentedBuildingWithContactPersons = useSelector(
    selectors.getRentedBuildingFromCustomer(rentedBuildingData.id)
  )

  const noEnergyReasonOptions = useMemo(() => {
    return Object.values(NoEnergyReasons).map((reason: NoEnergyReasons) => {
      return {
        value: reason,
        label: translate(`cms.noEnergyReason.${reason}`)
      }
    }, [])
  }, [translate])

  const seatOptions = useMemo(() => {
    const min =
      contactPersons.length > minAllowedSeatCount
        ? contactPersons.length
        : minAllowedSeatCount
    const values = maxAllowedSeatCount - min + 1
    return Array.from({ length: values }, (_, i) => ({
      value: (Number(min) + i).toString(),
      label: `${(Number(min) + i).toString()} ${translate("cms.seat")}`
    }))
  }, [
    contactPersons.length,
    maxAllowedSeatCount,
    minAllowedSeatCount,
    translate
  ])

  const noBuildingAdmin = React.useMemo(() => {
    const buildingAdmins = contactPersons.filter(contactPerson =>
      contactPerson.roles?.includes(PossibleContactRoles.ADMIN)
    )
    return buildingAdmins.length === 0
  }, [contactPersons])
  const hasNoSpacesLeft =
    contactPersons.length === rentedBuildingWithContactPersons!.maxSeatCount

  const handleOnClick = () => {
    dispatch(
      appEventsActions.fire({
        eventName: PossibleAppEvents.ADD_COLLEAGUE_TO_BUILDING,
        uniqueIdentifier: "add-colleague-to-building",
        props: {
          entityId
        }
      })
    )
  }

  const handleSeatChange = (o: OnChangeValue<CustomOptionType, false>) => {
    const value = (o as CustomOptionType).value
    dispatch(
      actions.patchRentedBuildingConfig(rentedBuildingData.id, {
        maxSeatCount: Number(value)
      })
    )
  }

  const handleNoEnergyReasonChange = (
    o: OnChangeValue<CustomOptionType, false>
  ) => {
    const value = (o as CustomOptionType).value as NoEnergyReasons
    dispatch(
      actions.patchRentedBuildingConfig(rentedBuildingData.id, {
        noEnergyReason: value
      })
    )
  }

  return (
    <>
      <RentedBuildingSeatOverviewWrapper>
        <RentedBuildingConfigContainer>
          <DropdownContainer>
            <Subtitle>{translate("cms.noEnergyReason.label")}</Subtitle>
            <StyledDropDown
              hasDropdownIndicator
              onChange={handleNoEnergyReasonChange}
              options={noEnergyReasonOptions}
              defaultValue={translate(
                `cms.noEnergyReason.${rentedBuildingData.noEnergyReason}`
              )}
              placeholder={translate("cms.noEnergyReason")}
              isTransparent
            />
          </DropdownContainer>
          <DropdownContainer>
            <Subtitle>{translate("cms.seat.label")}</Subtitle>
            <StyledDropDown
              hasDropdownIndicator
              onChange={handleSeatChange}
              options={seatOptions}
              defaultValue={rentedBuildingData.maxSeatCount.toString()}
              placeholder={`${rentedBuildingData.maxSeatCount.toString()} ${translate(
                "cms.seat"
              )}`}
              isTransparent
            />
          </DropdownContainer>
        </RentedBuildingConfigContainer>
        <Subtitle>{translate("cms.adminUsers")}</Subtitle>
        {contactPersons.map(contactPerson => {
          return (
            <RentedBuildingSeat
              entityId={entityId}
              key={contactPerson.id}
              contactPerson={contactPerson}
            />
          )
        })}
        {noBuildingAdmin && <SeatProposal entityId={entityId} />}
      </RentedBuildingSeatOverviewWrapper>
      <Button
        label={translate("cms.button.addAnotherAdmin")}
        onClick={handleOnClick}
        disabled={hasNoSpacesLeft}
      />
    </>
  )
}

export default RentedBuildingSeatOverview
