import React from "react"
import { Container } from "./style"
import CampaignDetailForm from "./CampaignDetailForm"

const CampaignDetail = () => {
  return (
    <Container>
      <CampaignDetailForm />
    </Container>
  )
}
export default CampaignDetail
