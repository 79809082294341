import React from "react"
import { StatusUpdate, Button, SlideIn } from "@paudigital/wdp-components"
import {
  ButtonContainer,
  Container,
  Title,
  Description,
  InfoContainer
} from "./style"
import { useTranslate } from "hooks/translate"
import { Campaign } from "app/types/campaign"
import { putCampaign } from "app/redux/campaign/campaign.actions"
import { useDispatch } from "react-redux"

type Props = {
  onClose: () => void
  data: Campaign
  campaignId: string
  bannerPicture?: File
}

export const PublishSlideIn = ({
  onClose,
  data,
  campaignId,
  bannerPicture
}: Props): JSX.Element => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()

  const handleOnSubmit = () => {
    dispatch(putCampaign({ data, campaignId, bannerPicture }))
    onClose()
  }

  return (
    <SlideIn show onClose={onClose} showCloseIcon>
      <Container>
        <InfoContainer>
          <StatusUpdate />
          <Title>
            {translate("cms.marketing-dashboard.publishSlideIn.title")}{" "}
          </Title>
          <Description>
            {translate("cms.marketing-dashboard.publishSlideIn.description")}
          </Description>
        </InfoContainer>
        <ButtonContainer>
          <Button
            fullWidth
            label={translate("cms.button.cancel")}
            type="secondary"
            onClick={onClose}
          />
          <Button
            fullWidth
            label={translate("cms.marketing-dashboard.submit")}
            onClick={handleOnSubmit}
          />
        </ButtonContainer>
      </Container>
    </SlideIn>
  )
}
export default PublishSlideIn
