import { AppEventMapping, PossibleAppEvents } from "./types"
import AccountCreatedPopUp from "../AccountCreatedPopUp"
import { Notification } from "@paudigital/wdp-components"
import { AddColleagueToBuilding } from "app/components/AddColleagueToBuilding"
import PasswordResetModal from "../PasswordResetModal"
import ResendInviteModal from "../ResendInviteModal"
import DeleteContactPerson from "../DeleteContactPersonModals"
import { AccountDeletedPopUp } from "../AccountDeletedPopUp"
import ResendInviteBulkModal from "../ResendInviteBulkModal"
import PublishSlideIn from "../PublishSlideIn"
import PublishChangedPopUp from "../PublishChangedPopUp"
import ConfirmationModal from "../DataStoriesPage/ConfirmationModal"

export const appEventMappings: AppEventMapping[] = [
  {
    component: AccountCreatedPopUp,
    event: PossibleAppEvents.ACCOUNT_CREATED
  },
  {
    component: AccountDeletedPopUp,
    event: PossibleAppEvents.ACCOUNT_DELETED
  },
  {
    component: Notification,
    event: PossibleAppEvents.NOTIFICATION
  },
  {
    component: AddColleagueToBuilding,
    event: PossibleAppEvents.ADD_COLLEAGUE_TO_BUILDING
  },
  {
    component: PasswordResetModal,
    event: PossibleAppEvents.PASSWORD_RESET_SUCCESS
  },
  {
    component: ResendInviteModal,
    event: PossibleAppEvents.RESEND_INVITE_SUCCESS
  },
  {
    component: DeleteContactPerson,
    event: PossibleAppEvents.DELETE_CONTACT_PERSON
  },
  {
    component: ResendInviteBulkModal,
    event: PossibleAppEvents.RESEND_INVITE_BULK_SUCCESS
  },
  {
    component: PublishSlideIn,
    event: PossibleAppEvents.PUBLISH
  },
  {
    component: PublishChangedPopUp,
    event: PossibleAppEvents.PUBLISH_CHANGED_SUCCESS
  },
  {
    component: ConfirmationModal,
    event: PossibleAppEvents.SEND_CONFIRMATION
  }
]
