import { Logo } from "@paudigital/wdp-components"
import { useNavigation } from "app/hooks/navigation"
import { usePermission } from "app/hooks/permission"
import { useTranslate } from "app/hooks/translate"
import React from "react"
import LoggedInUser from "./LoggedInUser"
import {
  WDPHeader,
  NavigationMenuItemContainer,
  MenuItem,
  MenuItemLink,
  NavigationMenuContainer,
  LogoContainer,
  Container
} from "./style"
import { mainRoutes } from "./config"

export type HeaderProps = {
  userFullname: string
  userDescription: string
  onLogout: () => void
}

const Header = ({
  userFullname,
  userDescription,
  onLogout
}: HeaderProps) => {
  const { translate } = useTranslate()
  const { isActive } = useNavigation()
  const { hasPermission } = usePermission()

  return (
    <Container>
      <WDPHeader>
        <LogoContainer>
          <Logo width="6.2rem" height="4.1rem" />
        </LogoContainer>
        <LoggedInUser
          name={userFullname}
          description={userDescription}
          onLogout={onLogout}
        />
        <NavigationMenuContainer>
          <NavigationMenuItemContainer isHidden={false}>
            {mainRoutes.map((r: any) => {
              if (hasPermission(r.module)) {
                return (
                  <MenuItem
                    data-testid="test"
                    active={isActive(r.url)}
                    key={r.module}
                  >
                    <MenuItemLink to={r.url}>
                      {translate(`cms.navigation.item.${r.module}`)}
                    </MenuItemLink>
                  </MenuItem>
                )
              }
              return null
            })}
          </NavigationMenuItemContainer>
        </NavigationMenuContainer>
      </WDPHeader>
    </Container>
  )
}

export default Header
