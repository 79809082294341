export enum Types {
  PUT = "PUT_CAMPAIGN_PICTURE",
  PUT_SUCCESS = "PUT_CAMPAIGN_PICTURE_SUCCESS",
  PUT_FAIL = "PUT_CAMPAIGN_PICTURE_FAIL",
  GET = "GET_CAMPAIGN_PICTURE",
  GET_SUCCESS = "GET_CAMPAIGN_PICTURE_SUCCESS",
  GET_FAIL = "GET_CAMPAIGN_PICTURE_FAIL",
  DELETE = "DELETE_CAMPAIGN_PICTURE",
  DELETE_SUCCESS = "DELETE_CAMPAIGN_PICTURE_SUCCESS",
  DELETE_FAIL = "DELETE_CAMPAIGN_PICTURE_FAIL"
}

export type Action =
  | PutCampaignPictureAction
  | PutCampaignPictureSuccessAction
  | PutCampaignPictureFailAction
  | GetCampaignPictureAction
  | GetCampaignPictureSuccessAction
  | GetCampaignPictureFailAction
  | DeleteCampaignPictureAction
  | DeleteCampaignPictureSuccessAction
  | DeleteCampaignPictureFailAction

export type PutCampaignPictureAction = {
  payload: { data: File; campaignId: string }
  type: Types.PUT
}

export type PutCampaignPictureSuccessAction = {
  type: Types.PUT_SUCCESS
}

export type PutCampaignPictureFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.PUT_FAIL
}

export type GetCampaignPictureAction = {
  payload: string
  type: Types.GET
}

export type GetCampaignPictureSuccessAction = {
  type: Types.GET_SUCCESS
  payload: { data: Blob; filename: string }
}

export type GetCampaignPictureFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_FAIL
}

export type DeleteCampaignPictureAction = {
  payload: string
  type: Types.DELETE
}

export type DeleteCampaignPictureSuccessAction = {
  type: Types.DELETE_SUCCESS
}

export type DeleteCampaignPictureFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.DELETE_FAIL
}

export function putCampaignPicture(data: File, campaignId: string): Action {
  return {
    type: Types.PUT,
    payload: { data, campaignId }
  }
}

export function putCampaignPictureSuccess(): Action {
  return {
    type: Types.PUT_SUCCESS
  }
}

export function putCampaignPictureFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.PUT_FAIL
  }
}

export function getCampaignPicture(campaignId: string): Action {
  return {
    payload: campaignId,
    type: Types.GET
  }
}

export function getCampaignPictureSuccess(
  data: Blob,
  filename: string
): Action {
  return {
    type: Types.GET_SUCCESS,
    payload: { data, filename }
  }
}

export function getCampaignPictureFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function deleteCampaignPicture(campaignId: string): Action {
  return {
    payload: campaignId,
    type: Types.DELETE
  }
}

export function deleteCampaignPictureSuccess(): Action {
  return {
    type: Types.DELETE_SUCCESS
  }
}

export function deleteCampaignPictureFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.DELETE_FAIL
  }
}
