import axios, { AxiosInstance, AxiosStatic } from "axios"
import { API_URLS } from "./config"
import { createApiInstance } from "api"
import { requestInterceptor } from "./interceptors"

function createProtectedInstance(
  axiosInstance: AxiosStatic,
  url: string
): AxiosInstance {
  const instance = createApiInstance(axiosInstance, url)
  instance.interceptors.request.use(requestInterceptor)
  instance.interceptors.response.use(
    response => {
      return response
    },
    error => {
      return Promise.reject(error.response.data)
    }
  )
  return instance
}

export const rentalApi = createProtectedInstance(axios, API_URLS.rentalApi)
