/* eslint-disable react-hooks/exhaustive-deps */
import React, { FormEvent, useCallback, useEffect } from "react"
import LanguageForm from "./LanguageForm"
import SubmittingButtons from "./SubmittingButtons"
import CampaignTitle from "./CampaignTitle"
import TargetUsersTable from "./TargetUsersTable"
import ColorPicker from "./ColorPicker"
import { useFormik } from "formik"
import { validationSchema, defaultColor } from "./config"
import { Languages } from "app/enums/languages"
import { FileUpload, ChevronLeft, theme } from "@paudigital/wdp-components"
import { useTranslate } from "app/hooks/translate"
import { useNavigate } from "react-router-dom"
import {
  ContentContainer,
  TranslationsTitle,
  TranslationsDescription,
  FileUploadContainer,
  DescriptionContainer,
  ActionContainer,
  HeaderContainer,
  TranslationsDownloadLink,
  StatusContainer,
  CampaignDetailsContainer,
  StatusTitle,
  StatusIndicator,
  StyledLink,
  BackButton,
  CampaignDetailRow,
  Title,
  TranslationsSubTitle
} from "./style"
import {
  postCampaign,
  putCampaign,
  resetCampaign,
  getCampaignCandidates,
  deactiveAllCandidatesInCampaign,
  getCampaign
} from "app/redux/campaign/campaign.actions"
import { initialValues as campaignInitialValues } from "app/redux/campaign/campaign.reducer"
import * as appEventActions from "app/redux/appEvents/appEvents.actions"
import { useDispatch, useSelector } from "react-redux"
import {
  Campaign,
  CampaignCandidates,
  CampaignTitleColor,
  ModulesEnum
} from "app/types/campaign"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import {
    getActiveCampaignCandidates,
  getCampaign as getCampaignSelector,
  getCampaignCandidates as getCampaignCandidatesSelector,
  getCampaignLoading
} from "app/redux/campaign/campaign.selectors"
import { getCampaignPictureFile } from "app/redux/campaignPicture/campaignPicture.selectors"
import {
  deleteCampaignPictureSuccess,
  getCampaignPicture
} from "app/redux/campaignPicture/campaignPicture.actions"
import { useParams } from "react-router-dom"
import { isEqual } from "lodash"
import { validate } from "uuid"
import ClearContentButton from "./ClearContentButton"
import CampaignTarget from "./CampaignTarget"
import { OnChangeValue } from "react-select"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"

const CampaignDetailForm = () => {
  const { translate } = useTranslate()
  const campaign = useSelector(getCampaignSelector)
  const { campaignId } = useParams<{ campaignId: string }>()
  const campaignCandidates = useSelector(getCampaignCandidatesSelector)
  const activeCampaignCandidates = useSelector(getActiveCampaignCandidates)
  const bannerPictureFile = useSelector(getCampaignPictureFile)
  const isLoading = useSelector(getCampaignLoading)
  const [bannerPicture, setCampaignPicture] = React.useState<File | undefined>()
  const [hasPictureChanged, setHasPictureChanged] =
    React.useState<boolean>(false)
  const [hasTitleColorChanged, setHasTitleColorChanged] =
    React.useState<boolean>(false)
  const [hasCandidatesChanged, setHasCandidatesChanged] =
    React.useState<boolean>(false)
  const [hasModuleChanged, setModuleChanged] = React.useState<boolean>(false)
  const [entities, setEntities] = React.useState<CampaignCandidates[]>([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [titleColor, setTitleColor] =
    React.useState<CampaignTitleColor>(defaultColor)

  const initialValues: Campaign = campaign

  const {
    values,
    handleChange,
    errors,
    touched,
    validateField,
    isValid,
    setErrors,
    setTouched,
    handleBlur,
    handleSubmit,
    setValues,
    setFieldValue,
    isSubmitting
  } = useFormik<Campaign>({
    initialValues,
    onSubmit: (values: Campaign) => {
      if (isValid) {
        setErrors({})
        setTouched({})
        dispatch(
          appEventActions.fire({
            eventName: PossibleAppEvents.PUBLISH,
            uniqueIdentifier: "publish-confirmation",
            props: {
              data: values,
              campaignId,
              bannerPicture
            }
          })
        )
      }
    },
    validationSchema
  })
  const supportedLanguages = [
    Languages.EN_GB,
    Languages.NL_BE,
    Languages.NL_NL,
    Languages.FR_FR,
    Languages.RO_RO
  ]

  const valuesHaveChanged =
    !isEqual(initialValues, values) ||
    hasPictureChanged ||
    hasCandidatesChanged ||
    hasTitleColorChanged ||
    hasModuleChanged

  const hasErrors =
    errors.languageData ||
    errors.active ||
    errors.bannerPictureUrl ||
    (entities.length === 0 && values.module === "dashboard") ||
    errors.module ||
    errors.title
      ? true
      : false


  useEffect(() => {
    if (values.module !== "dashboard") {
      return setEntities(campaignCandidates)
    }
    return setEntities(activeCampaignCandidates)
  }, [values.module])

  useEffect(() => {
    if (validate(campaignId as string)) {
      dispatch(getCampaignCandidates(campaignId as string))
      dispatch(getCampaignPicture(campaignId as string))
      dispatch(getCampaign(campaignId as string))
    }

    return () => {
      dispatch(resetCampaign())
      dispatch(deleteCampaignPictureSuccess())
      dispatch(getCampaignCandidates())
    }
  }, [dispatch, campaignId])

  useEffect(() => {
    if (campaign?.id) {
      setValues(campaign)
      setTitleColor(campaign.color)
    }
  }, [campaign?.id])

  useEffect(() => {
    if (bannerPictureFile && !isSubmitting) {
      setCampaignPicture(bannerPictureFile)
    }
  }, [bannerPictureFile, isSubmitting])

  const handleFileUpload = (data: File[]) => {
    setCampaignPicture(data[0])
    setHasPictureChanged(true)
  }

  const handleClearTranslationContent = (e: FormEvent) => {
    e.preventDefault()
    setFieldValue("languageData", campaignInitialValues.languageData)
  }

  const handleClearCampaignPicture = (e: FormEvent) => {
    e.preventDefault()
    setCampaignPicture(undefined)
  }

  const handleClickBack = () => {
    navigate("/marketing-dashboard/")
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue("title", e.target.value)
    validateField("title")
  }

  const handleModuleChange = (
    option: OnChangeValue<CustomOptionType, false>
  ) => {
    setFieldValue("module", option?.value)
    validateField("module")
    setModuleChanged(true)
    if (option?.value === ModulesEnum.DASHBOARD) {
      setFieldValue(
        "entityIds",
        entities.map(e => e.entityId)
      )
      validateField("entityIds")
    } else {
      setFieldValue(
        "entityIds",
        campaignCandidates!.map(e => e.entityId)
      )
    }
  }

  const handleClearAllFields = (e: FormEvent) => {
    e.preventDefault()
    setCampaignPicture(undefined)
    setHasPictureChanged(true)
    dispatch(deactiveAllCandidatesInCampaign())
  }

  const modifyCampaign = (status: boolean) => {
    dispatch(
      putCampaign({
        campaignId: campaignId as string,
        data: {
          ...values,
          id: campaignId as string,
          entityIds: entities.map(entity => entity.entityId),
          active: status,
          color: titleColor
        },
        bannerPicture
      })
    )
  }

  const createCampaign = () => {
    dispatch(
      postCampaign({
        data: {
          ...values,
          active: true,
          entityIds: values.entityIds,
          color: titleColor
        },
        bannerPicture
      })
    )
  }

  const setCampaignActive = useCallback(
    () => modifyCampaign(true),
    [values, bannerPicture, entities, titleColor]
  )
  const setCampaignInactive = useCallback(
    () => modifyCampaign(false),
    [values, bannerPicture, entities, titleColor]
  )

  const setSelectedIds = (entityIds: CampaignCandidates[]) => {
    setEntities(entityIds)
    setFieldValue(
      "entityIds",
      entityIds.map(e => e.entityId)
    )
    setHasCandidatesChanged(true)
  }

  return (
    <>
      <CampaignDetailsContainer>
        <BackButton onClick={handleClickBack}>
          <ChevronLeft
            width="20"
            height="30"
            fill={theme.colors.green.contrast}
          />
          <StyledLink value="Back" type="default" />
        </BackButton>
        <Title>{translate("cms.marketing-dashboard.title")}</Title>
        <CampaignDetailRow>
          <StatusContainer>
            <StatusTitle>
              {translate(
                "cms.marketing-dashboard.campaign-detail.currentStatus"
              )}
            </StatusTitle>
            <StatusIndicator active={values.active} />
          </StatusContainer>
          <ClearContentButton
            handleClearAllFields={handleClearAllFields}
            title={translate("cms.marketing-dashboard.clear-all-content")}
          />
        </CampaignDetailRow>
      </CampaignDetailsContainer>
      <form onSubmit={handleSubmit}>
        <CampaignTitle
          handleTitleChange={handleTitleChange}
          errors={errors.title}
          touched={touched.title}
          title={values.title}
          validateField={validateField}
          handleBlur={handleBlur}
        />
        <CampaignTarget
          handleTargetChange={handleModuleChange}
          module={values.module}
        />
        <ContentContainer>
          <HeaderContainer>
            <DescriptionContainer>
              <TranslationsTitle>
                {translate("cms.marketing-dashboard.bannerPhoto")}
              </TranslationsTitle>
              <TranslationsDescription>
                <span>
                  {translate(
                    "cms.marketing-dashboard.bannerPhoto.description.start"
                  )}
                </span>
                <TranslationsDownloadLink
                  href="CMS_MARKETING_BANNER_TEMPLATE.psd"
                  download
                >
                  {translate(
                    "cms.marketing-dashboard.bannerPhoto.description.link"
                  )}
                </TranslationsDownloadLink>
                <span>
                  {translate(
                    "cms.marketing-dashboard.bannerPhoto.description.end"
                  )}
                </span>
              </TranslationsDescription>
            </DescriptionContainer>
            <ActionContainer>
              <ClearContentButton
                handleClearAllFields={handleClearCampaignPicture}
                title={translate("cms.marketing-dashboard.clear-content")}
              />
            </ActionContainer>
          </HeaderContainer>
          <FileUploadContainer>
            <FileUpload
              maxFileSize={102400}
              allowedFileTypes={["image/png", "image/jpeg", "image/jpg"]}
              fileTypeErrorTranslation={translate(
                "cms.marketing-dashboard.filetype.error"
              )}
              fileLimitErrorTranslation={translate(
                "cms.marketing-dashboard.filelimit.error"
              )}
              fileSizeErrorTranslation={translate(
                "cms.marketing-dashboard.filesize.error"
              )}
              initialValue={bannerPicture ? [bannerPicture] : []}
              optional
              required={false}
              onChange={handleFileUpload}
              placeholder={translate(
                "cms.marketing-dashboard.fileUpload.placeholder"
              )}
              inputLimit={1}
            />
          </FileUploadContainer>
        </ContentContainer>
        <ContentContainer>
          <HeaderContainer>
            <TranslationsTitle>
              {translate(
                "cms.marketing-dashboard.campaign-detail.banner-section.title"
              )}
            </TranslationsTitle>
            <ActionContainer>
              <ClearContentButton
                handleClearAllFields={handleClearTranslationContent}
                title={translate("cms.marketing-dashboard.clear-content")}
              />
            </ActionContainer>
          </HeaderContainer>
          <HeaderContainer>
            <DescriptionContainer>
              <TranslationsSubTitle>
                {translate(
                  "cms.marketing-dashboard.campaign-detail.title-color.title"
                )}
              </TranslationsSubTitle>
              <ColorPicker
                previewColor={titleColor}
                handleChangeColor={setTitleColor}
                handleTouched={setHasTitleColorChanged}
              />
            </DescriptionContainer>
          </HeaderContainer>
          <HeaderContainer>
            <DescriptionContainer>
              <TranslationsSubTitle>
                {translate("cms.marketing-dashboard.translations-title")}
              </TranslationsSubTitle>
              <TranslationsDescription>
                {translate("cms.marketing-dashboard.translations-description")}
              </TranslationsDescription>
            </DescriptionContainer>
          </HeaderContainer>
          {supportedLanguages.map((language: Languages) => (
            <LanguageForm
              key={language}
              language={language}
              values={values.languageData}
              handleChange={handleChange}
              errors={errors.languageData as any}
              touched={touched.languageData as any}
              validateField={validateField}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
          ))}
        </ContentContainer>
        {values.module === ModulesEnum.DASHBOARD && (
          <TargetUsersTable
            loading={isLoading}
            values={campaignCandidates}
            setSelectedIds={setSelectedIds}
            setHasCandidatesChanged={setHasCandidatesChanged}
          />
        )}

        <SubmittingButtons
          createCampaign={createCampaign}
          valuesHaveChanged={valuesHaveChanged}
          active={values.active}
          handleClickBack={handleClickBack}
          hasErrors={hasErrors}
          setCampaignActive={setCampaignActive}
          setCampaignInactive={setCampaignInactive}
          campaignId={campaignId as string}
        />
      </form>
    </>
  )
}
export default CampaignDetailForm
