import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const CustomerRentedBuildingWrapper = styled.div`
  margin: 0 0 5.6rem 0;
`

export const BuildingAddress = styled.h6`
  font-size: ${theme.fontSizes.h6};
  line-height: ${theme.lineHeights.h6};
  color: ${theme.colors.neutral.darkerGrey};
`

export const CustomRentedBuildingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  flex-direction: column;
  @media screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
    align-items: center;
  }
`
