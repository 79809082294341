import { SagaIterator } from "redux-saga"
import { put, takeLatest } from "redux-saga/effects"
import * as actions from "./gaEvents.actions"
import { PossibleGAEvents } from "./gaEvents.actions"

export function* createEventFlow(
  action: actions.CreateGAEventAction
): SagaIterator {
  try {
    const { eventName, params } = action.payload
    if (eventName === PossibleGAEvents.PAGE_VIEW) {
      gtag("event", eventName, {
        ...params
      })
    } else {
      gtag("event", eventName, params ?? undefined)
    }
    yield put(actions.createGAEventSuccess(action.payload))
  } catch (e: any) {
    yield put(actions.createGAEventFail(e))
  }
}

export default function* gaEventsSaga(): SagaIterator {
  yield takeLatest(actions.Types.CREATE, createEventFlow)
}
