import React from "react"
import { useTranslate } from "app/hooks/translate"
import { ContactPerson } from "app/types/contactPerson"
import {
  RentedBuildingSeatWrapper,
  LinkWrapper,
  StyledProfileCard
} from "./style"
import { checkIfDatesAre24HoursApart } from "app/utils/time.utils"
import { Link } from "@paudigital/wdp-components"
import {
  passwordReset,
  resendInvite
} from "app/redux/contactPerson/contactPerson.actions"
import { useDispatch } from "react-redux"
import * as appEventActions from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { BASE_URL } from "app/api/config"

export type RentedBuildingSeatProps = {
  entityId: string
  contactPerson: ContactPerson
}

type Props = RentedBuildingSeatProps

const RentedBuildingSeat = ({ entityId, contactPerson }: Props) => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()

  const seatDetails = React.useMemo(() => {
    return {
      label: `${contactPerson.firstName} ${contactPerson.lastName}`,
      email: contactPerson.email,
      functionDescription: contactPerson.function ? contactPerson.function : "",
      roles: contactPerson.roles ? contactPerson.roles.join(", ") : ""
    }
  }, [
    contactPerson.firstName,
    contactPerson.lastName,
    contactPerson.email,
    contactPerson.function,
    contactPerson.roles
  ])
  const invitationExpired = React.useMemo(() => {
    if (!contactPerson.invitedAt) return true
    return (
      checkIfDatesAre24HoursApart(
        contactPerson.invitedAt,
        new Date().toISOString()
      ) && !contactPerson.onboardedAt
    )
  }, [contactPerson.invitedAt, contactPerson.onboardedAt])

  const onboardingIncomplete = React.useMemo(() => {
    if (!contactPerson.invitedAt) return false
    return (
      !checkIfDatesAre24HoursApart(
        contactPerson.invitedAt,
        new Date().toISOString()
      ) && !contactPerson.onboardedAt
    )
  }, [contactPerson.invitedAt, contactPerson.onboardedAt])

  const cardType = invitationExpired
    ? "warning"
    : onboardingIncomplete
    ? "report"
    : "default"

  const handleResetPassword = () => {
    dispatch(passwordReset(contactPerson))
  }

  const handleResendInvite = () => {
    dispatch(resendInvite(entityId, contactPerson.id))
  }

  const handleOnEdit = () => {
    dispatch(
      appEventActions.fire({
        uniqueIdentifier: "add-colleague-to-building",
        eventName: PossibleAppEvents.ADD_COLLEAGUE_TO_BUILDING,
        props: { entityId, id: contactPerson.id }
      })
    )
  }

  const handleOnDelete = () => {
    dispatch(
      appEventActions.fire({
        eventName: PossibleAppEvents.DELETE_CONTACT_PERSON,
        uniqueIdentifier: "delete-contact-person",
        props: {
          entityId,
          contactPersonIdToDelete: contactPerson.id
        }
      })
    )
  }

  const handleOnLogin = () => {
    window.open(`${BASE_URL}/callback?id=${contactPerson.id}`, "_blank")
  }

  return (
    <RentedBuildingSeatWrapper>
      <StyledProfileCard
        type={cardType}
        active={true}
        avatar={contactPerson.profilePictureUrl}
        email={seatDetails.email}
        label={seatDetails.label}
        functionDescription={seatDetails.functionDescription}
        roles={seatDetails.roles}
        translations={{
          edit: translate("cms.button.edit"),
          delete: translate("cms.button.delete"),
          login: translate("cms.button.login")
        }}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
        onLogin={handleOnLogin}
      />
      <LinkWrapper>
        {invitationExpired ? (
          <Link
            type="default"
            value={translate("cms.seat.resend.invitation")}
            onClick={handleResendInvite}
          />
        ) : (
          <Link
            type="default"
            value={translate("cms.seat.reset.password")}
            onClick={handleResetPassword}
          />
        )}
      </LinkWrapper>
    </RentedBuildingSeatWrapper>
  )
}

export default RentedBuildingSeat
