import { Campaign, CampaignCandidates } from "app/types/campaign"
import { CampaignWithCounts } from "app/types/campaignsWithCount"

export enum Types {
  POST_CAMPAIGN = "POST_CAMPAIGN",
  POST_CAMPAIGN_SUCCESS = "POST_CAMPAIGN_SUCCESS",
  POST_CAMPAIGN_FAIL = "POST_CAMPAIGN_FAIL",
  PUT_CAMPAIGN = "PUT_CAMPAIGN",
  PUT_CAMPAIGN_SUCCESS = "PUT_CAMPAIGN_SUCCESS",
  PUT_CAMPAIGN_FAIL = "PUT_CAMPAIGN_FAIL",
  GET_CAMPAIGN = "GET_CAMPAIGN",
  GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS",
  GET_CAMPAIGN_FAIL = "GET_CAMPAIGN_FAIL",
  GET_CAMPAIGNS = "GET_CAMPAIGNS",
  GET_CAMPAIGNS_SUCCES = "GET_CAMPAIGNS_SUCCESS",
  GET_CAMPAIGNS_FAIL = "GET_CAMPAIGNS_FAIL",
  DEACTIVATE_ALL = "DEACTIVATE_ALL",
  DEACTIVATE_ALL_SUCCESS = "DEACTIVATE_ALL_SUCCESS",
  DEACTIVATE_ALL_FAIL = "DEACTIVATE_ALL_FAIL",
  RESET_CAMPAIGN = "RESET_CAMPAIGN",
  GET_CAMPAIGN_CANDIDATES = "GET_CAMPAIGN_CANDIDATES",
  GET_CAMPAIGN_CANDIDATES_SUCCESS = "GET_CAMPAIGN_CANDIDATES_SUCCESS",
  GET_CAMPAIGN_CANDIDATES_FAIL = "GET_CAMPAIGN_CANDIDATES_FAIL",
  DEACTIVATE_ALL_CANDIDATES_IN_CAMPAIGN = "DEACTIVATE_ALL_CANDIDATES_IN_CAMPAIGN"
}

export type Action =
  | PostCampaignAction
  | PostCampaignSuccessAction
  | PostCampaignFailAction
  | PutCampaignAction
  | PutCampaignSuccessAction
  | PutCampaignFailAction
  | GetCampaignAction
  | GetCampaignSuccessAction
  | GetCampaignFailAction
  | GetCampaignsAction
  | GetCampaignsFailAction
  | GetCampaignsSuccessAction
  | DeactivateAllAction
  | DeactivateAllSuccessAction
  | DeactivateAllFailAction
  | ResetCampaignAction
  | GetCampaignCandidatesAction
  | GetCampaignCandidatesSuccessAction
  | GetCampaignCandidatesFailAction
  | DeactivateAllCandidatesInCampaign

export type QueryParams = {
  page: number
  perPage: number
}

export type PostCampaignAction = {
  payload: { data: Campaign; bannerPicture?: File }
  type: Types.POST_CAMPAIGN
}

export type PostCampaignSuccessAction = {
  type: Types.POST_CAMPAIGN_SUCCESS
  payload: Campaign
}

export type PostCampaignFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.POST_CAMPAIGN_FAIL
}

export type PutCampaignAction = {
  payload: { data: Campaign; bannerPicture?: File; campaignId: string }
  type: Types.PUT_CAMPAIGN
}

export type PutCampaignSuccessAction = {
  type: Types.PUT_CAMPAIGN_SUCCESS
  payload: Campaign
}

export type PutCampaignFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.PUT_CAMPAIGN_FAIL
}

export type GetCampaignAction = {
  payload: { campaignId: string }
  type: Types.GET_CAMPAIGN
}

export type GetCampaignSuccessAction = {
  payload: Campaign
  type: Types.GET_CAMPAIGN_SUCCESS
}

export type GetCampaignFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_CAMPAIGN_FAIL
}

export type GetCampaignsAction = {
  payload: QueryParams
  type: Types.GET_CAMPAIGNS
}

export type GetCampaignsSuccessAction = {
  payload: CampaignWithCounts
  type: Types.GET_CAMPAIGNS_SUCCES
}

export type GetCampaignsFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_CAMPAIGNS_FAIL
}

export type DeactivateAllAction = {
  type: Types.DEACTIVATE_ALL
}

export type DeactivateAllSuccessAction = {
  type: Types.DEACTIVATE_ALL_SUCCESS
}

export type DeactivateAllFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.DEACTIVATE_ALL_FAIL
}

export type ResetCampaignAction = {
  type: Types.RESET_CAMPAIGN
}

export type DeactivateAllCandidatesInCampaign = {
  type: Types.DEACTIVATE_ALL_CANDIDATES_IN_CAMPAIGN
}

export function postCampaign({
  data,
  bannerPicture
}: {
  data: Campaign
  bannerPicture?: File
}): Action {
  return {
    type: Types.POST_CAMPAIGN,
    payload: { data, bannerPicture }
  }
}

export function postCampaignSuccess(data: Campaign): Action {
  return {
    type: Types.POST_CAMPAIGN_SUCCESS,
    payload: data
  }
}

export function postCampaignFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.POST_CAMPAIGN_FAIL
  }
}

export type GetCampaignCandidatesAction = {
  payload: { campaignId?: string }
  type: Types.GET_CAMPAIGN_CANDIDATES
}

export type GetCampaignCandidatesSuccessAction = {
  payload: CampaignCandidates[]
  type: Types.GET_CAMPAIGN_CANDIDATES_SUCCESS
}

export type GetCampaignCandidatesFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_CAMPAIGN_CANDIDATES_FAIL
}

export function putCampaign({
  data,
  campaignId,
  bannerPicture
}: {
  data: Campaign
  campaignId: string
  bannerPicture?: File
}): Action {
  return {
    type: Types.PUT_CAMPAIGN,
    payload: { data, bannerPicture, campaignId }
  }
}

export function putCampaignSuccess(data: Campaign): Action {
  return {
    type: Types.PUT_CAMPAIGN_SUCCESS,
    payload: data
  }
}

export function putCampaignFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.PUT_CAMPAIGN_FAIL
  }
}

export function getCampaign(campaignId: string): Action {
  return {
    payload: { campaignId },
    type: Types.GET_CAMPAIGN
  }
}

export function getCampaignSuccess(data: Campaign): Action {
  return {
    payload: data,
    type: Types.GET_CAMPAIGN_SUCCESS
  }
}

export function getCampaignFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.GET_CAMPAIGN_FAIL
  }
}

export function getCampaigns(queryParams: QueryParams): Action {
  return {
    payload: queryParams,
    type: Types.GET_CAMPAIGNS
  }
}

export function getCampaignsSuccess(
  campaignWithCounts: CampaignWithCounts
): Action {
  return {
    payload: campaignWithCounts,
    type: Types.GET_CAMPAIGNS_SUCCES
  }
}

export function getCampaignsFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.GET_CAMPAIGNS_FAIL
  }
}

export function resetCampaign(): Action {
  return {
    type: Types.RESET_CAMPAIGN
  }
}

export function deactivateAll(): Action {
  return {
    type: Types.DEACTIVATE_ALL
  }
}

export function deactivateAllSuccess(): Action {
  return {
    type: Types.DEACTIVATE_ALL_SUCCESS
  }
}

export function deactivateAllFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.DEACTIVATE_ALL_FAIL
  }
}

export function getCampaignCandidates(campaignId?: string): Action {
  return {
    payload: { campaignId },
    type: Types.GET_CAMPAIGN_CANDIDATES
  }
}

export function getCampaignCandidatesSuccess(
  data: CampaignCandidates[]
): Action {
  return {
    payload: data,
    type: Types.GET_CAMPAIGN_CANDIDATES_SUCCESS
  }
}

export function getCampaignCandidatesFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.GET_CAMPAIGN_CANDIDATES_FAIL
  }
}

export function deactiveAllCandidatesInCampaign(): Action {
  return {
    type: Types.DEACTIVATE_ALL_CANDIDATES_IN_CAMPAIGN
  }
}
