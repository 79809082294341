import { getCustomerStatistics } from "app/redux/customerStatistics/customerStatistics.actions"
import * as selectors from "app/redux/customerStatistics/customerStatistics.selectors"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useTranslate } from "hooks/translate"
import { CustomerCount, SearchBarWrapper } from "./style"
import { useNavigate, useLocation } from "react-router-dom"
import { SearchBar, Pagination } from "@paudigital/wdp-components"
import { useViewport } from "app/hooks/viewport"
import CustomerStatistics from "./CustomerStatistics"
import { DataContainer } from "../Layout/style"
import { CustomerStatisticTypes } from "app/types/customerStatisticsTypes"
import { useDispatch } from "react-redux"

const CustomerOverviewPage = () => {
  const navigate = useNavigate()
  const { translate } = useTranslate()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const params = new URLSearchParams(search)
  const page = Number(params.get("page")) || 1
  const perPage = Number(params.get("perPage")) || 40
  const q = params.get("q") || ""
  const contractStartDate = params.get("contract_start_date")
  const contractEndDate = params.get("contract_end_date")

  useEffect(() => {
    const today = new Date()
    today.setDate(today.getDate() - 90)
    const contract_end_date = contractEndDate || today.toISOString()
    const contract_start_date = contractStartDate
    dispatch(
      getCustomerStatistics({
        page,
        perPage,
        q,
        contract_end_date,
        contract_start_date
      })
    )
  }, [contractEndDate, contractStartDate, dispatch, page, perPage, q])

  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [page, perPage, q])

  const handleKeyPress = (value: string): void => {
    params.set("q", value.toString())
    params.set("page", "1")
    params.set("perPage", perPage.toString())
    navigate(`/user-management/?${params.toString()}`)
  }

  const handleClick = (value: string): void => {
    params.set("q", value.toString())
    params.set("page", "1")
    params.set("perPage", perPage.toString())
    navigate(`/user-management/?${params.toString()}`)
  }

  const previousPage = (): void => {
    params.set("page", (page - 1).toString())
    navigate(`/user-management/?${params.toString()}`)
  }

  const nextPage = (): void => {
    params.set("page", (page + 1).toString())
    navigate(`/user-management/?${params.toString()}`)
  }

  const goToPage = (event: React.MouseEvent<any>): void => {
    params.set("page", event.currentTarget.value.toString())
    navigate(`/user-management/?${params.toString()}`)
  }

  const removeSearch = () => {
    params.set("q", "")
    navigate(`/user-management/?${params.toString()}`)
  }

  const { width } = useViewport()
  const customers = useSelector(selectors.getCustomerStatistics)
  const adminNeededCustomers = useSelector(
    selectors.getCustomerStatisticsAdminNeeded
  )
  const linkExpiredCustomers = useSelector(
    selectors.getCustomerStatisticsLinkExpired
  )
  const onboardingIncompleteCustomers = useSelector(
    selectors.getCustomerStatisticsOnbardingIncomplete
  )
  const activeAccountsCustomers = useSelector(
    selectors.getCustomerStatisticsActiveAccounts
  )

  return (
    <DataContainer>
      <CustomerCount>
        {customers.total} {translate("cms.customers")}
      </CustomerCount>
      <SearchBarWrapper>
        <SearchBar
          onClick={handleClick}
          onKeyPress={handleKeyPress}
          clearValue={removeSearch}
          placeholder={translate("cms.customerOverview.search.placeholder")}
          value={q}
        />
      </SearchBarWrapper>
      <CustomerStatistics
        customersStatistics={adminNeededCustomers}
        customersTitle={translate("cms.customerOverview.adminNeeded")}
        showStatus={true}
        statisticType={CustomerStatisticTypes.ADMIN_NEEDED}
      />
      <CustomerStatistics
        customersStatistics={linkExpiredCustomers}
        customersTitle={translate("cms.customerOverview.linkExpired")}
        showStatus={adminNeededCustomers.length < perPage}
        statisticType={CustomerStatisticTypes.PASSWORD_RESET}
      />
      <CustomerStatistics
        customersStatistics={onboardingIncompleteCustomers}
        customersTitle={translate(
          "cms.customerOverview.onboardingNotCompleted"
        )}
        showStatus={
          adminNeededCustomers.length + linkExpiredCustomers.length < perPage
        }
        statisticType={CustomerStatisticTypes.PASSWORD_RESET}
      />
      <CustomerStatistics
        customersStatistics={activeAccountsCustomers}
        customersTitle={translate("cms.customerOverview.activeAccounts")}
        showStatus={
          adminNeededCustomers.length +
            linkExpiredCustomers.length +
            onboardingIncompleteCustomers.length <
          perPage
        }
        statisticType={CustomerStatisticTypes.PASSWORD_RESET}
      />
      <Pagination
        page={page}
        perPage={perPage}
        pages={customers.pages}
        nextPage={nextPage}
        previousPage={previousPage}
        goToPage={goToPage}
        showPages={3}
        width={width}
        next={translate("cms.next")}
        previous={translate("cms.previous")}
      />
    </DataContainer>
  )
}

export default CustomerOverviewPage
