import { CustomerWithRentedBuildings } from "app/types/customerWithRentedBuildings"
import State from "../state"
import { ContactPerson } from "app/types/contactPerson"
import { RentedBuildingWithContactPersons } from "app/types/rentedBuildingWithContactPersons"
import { InviteContactPerson } from "app/types/contactPerson"
import { checkIfDatesAre24HoursApart } from "app/utils/time.utils"

export const getCustomerWithRentedBuildings = (
  state: State
): CustomerWithRentedBuildings | null => state.customerWithRentedBuildings.data

export const getRentedBuildingFromCustomer = (rentedBuildingId: string) => (
  state: State
): RentedBuildingWithContactPersons | undefined => {
  return state.customerWithRentedBuildings.data?.rentedBuildings.find(
    r => r.id === rentedBuildingId
  )
}

export const isLoading = (state: State): boolean =>
  state.customerWithRentedBuildings.loading

export const getCustomerWithRentedBuildingsErrors = (
  state: State
): Record<string, unknown> | undefined =>
  state.customerWithRentedBuildings.errors

export const getContactPersonById = (contactPersonId: string | undefined) => (
  state: State
): InviteContactPerson | undefined => {
  if (!contactPersonId) {
    return undefined
  }
  const buildings = state.customerWithRentedBuildings.data?.rentedBuildings
  if (!buildings) {
    return undefined
  }

  const contactPersons = buildings?.map((b: RentedBuildingWithContactPersons) =>
    b.contactPersons.filter((cp: ContactPerson) => cp.id === contactPersonId)
  )

  if (contactPersons.length === 0) return undefined

  const contact = contactPersons.flat().filter(Boolean)[0]

  const roles = buildings
    .map((b: RentedBuildingWithContactPersons) => {
      const user = b.contactPersons.find(
        (u: ContactPerson) => u.id === contact.id
      )
      return {
        rentedBuildingId: b.id,
        roles: user?.roles ? user.roles : []
      }
    })
    .flat()

  const { email, lastName, firstName, languagePreference } = contact
  return {
    email,
    firstName,
    lastName,
    contactRolesPerRentedBuilding: roles,
    languagePreference
  }
}

export const getExpiredInvitationContactPersons = (
  state: State
): ContactPerson[] => {
  const buildings = state.customerWithRentedBuildings.data?.rentedBuildings
  if (!buildings) {
    return []
  }
  return buildings.reduce(
    (
      result: ContactPerson[],
      rentedBuilding: RentedBuildingWithContactPersons
    ) => {
      const contactPersons = rentedBuilding.contactPersons.filter(
        (contactPerson: ContactPerson) => {
          return (
            contactPerson.invitedAt &&
            checkIfDatesAre24HoursApart(
              contactPerson.invitedAt,
              new Date().toISOString()
            ) &&
            !contactPerson.onboardedAt
          )
        }
      )
      return [...result, ...contactPersons]
    },
    []
  )
}
