import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import { getContactPersonContactRoles } from "app/api/contactPerson"
import * as actions from "./contactPersonContactRoles.actions"

export function* getContactPersonContactRolesFlow(
  action: actions.GetContactPersonContactRolesAction
): SagaIterator {
  try {
    const { entityId, contactPersonId } = action.payload
    const response = yield call(
      getContactPersonContactRoles,
      entityId,
      contactPersonId
    )
    yield put(
      actions.getContactPersonContactRolesSuccess(response && response.data)
    )
  } catch (e: any) {
    yield put(actions.getContactPersonContactRolesFail(e))
  }
}

export default function* contactPersonContactRolesSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getContactPersonContactRolesFlow)
}
