import { ContactPerson, PatchContactPerson } from "app/types/contactPerson"

export enum Types {
  GET = "GET_CONTACTPERSON",
  GET_SUCCESS = "GET_CONTACTPERSON_SUCCESS",
  GET_FAIL = "GET_CONTACTPERSON_FAIL",
  GET_BY_ID = "GET_CONTACTPERSON_BY_ID",
  GET_BY_ID_SUCCESS = "GET_CONTACTPERSON_BY_ID_SUCCESS",
  GET_BY_ID_FAIL = "GET_CONTACTPERSON_BY_ID_FAIL",
  PATCH = "PATCH_CONTACTPERSON",
  PATCH_SUCCESS = "PATCH_CONTACTPERSON_SUCCESS",
  PATCH_FAIL = "PATCH_CONTACTPERSON_FAIL",
  PATCH_ONBOARDING = "PATCH_CONTACTPERSON_ONBOARDING",
  PATCH_ONBOARDING_SUCCESS = "PATCH_CONTACTPERSON_ONBOARDING_SUCCESS",
  PATCH_ONBOARDING_FAIL = "PATCH_CONTACTPERSON_ONBOARDING_FAIL",
  PUT_PROFILEPICTURE = "PUT_PROFILEPICTURE",
  PUT_PROFILEPICTURE_SUCCESS = "PUT_PROFILEPICTURE_SUCCESS",
  PUT_PROFILEPICTURE_FAIL = "PUT_PROFILEPICTURE_FAIL",
  PASSWORD_RESET = "PASSWORD_RESET",
  PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL",
  RESEND_INVITE = "RESEND_INVITE",
  RESEND_INVITE_SUCCESS = "RESEND_INVITE_SUCCESS",
  RESEND_INVITE_FAIL = "RESEND_INVITE_FAIL",
  CLEAR_SUCCESS = "CLEAR_SUCCESS"
}

export type Action =
  | GetContactPersonAction
  | GetContactPersonSuccessAction
  | GetContactPersonFailAction
  | GetContactPersonByIdAction
  | GetContactPersonByIdSuccessAction
  | GetContactPersonByIdFailAction
  | PatchContactPersonAction
  | PatchContactPersonSuccessAction
  | PatchContactPersonFailAction
  | PatchContactPersonOnboardingAction
  | PatchContactPersonOnboardingSuccessAction
  | PatchContactPersonOnboardingFailAction
  | PasswordResetPersonAction
  | PasswordResetPersonSuccessAction
  | PasswordResetPersonFailAction
  | ResendInviteAction
  | ResendInviteSuccessAction
  | ResendInviteFailAction
  | ClearSuccessAction

export type PatchContactPersonAction = {
  type: Types.PATCH
  payload: {
    contactPersonId: string
    data: PatchContactPerson
  }
}

export type PatchContactPersonSuccessAction = {
  payload: { data: ContactPerson }
  type: Types.PATCH_SUCCESS
}

export type PatchContactPersonFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.PATCH_FAIL
}

export type GetContactPersonAction = {
  payload: { entityId: string; query: string }
  type: Types.GET
}

export type GetContactPersonSuccessAction = {
  payload: { data: ContactPerson[] }
  type: Types.GET_SUCCESS
}

export type GetContactPersonFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_FAIL
}

export type GetContactPersonByIdAction = {
  payload: { contactPersonId: string }
  type: Types.GET_BY_ID
}

export type GetContactPersonByIdSuccessAction = {
  payload: { data: ContactPerson }
  type: Types.GET_BY_ID_SUCCESS
}

export type GetContactPersonByIdFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_BY_ID_FAIL
}

export type PatchContactPersonOnboardingAction = {
  type: Types.PATCH_ONBOARDING
  payload: {
    contactPersonId: string
  }
}

export type PatchContactPersonOnboardingSuccessAction = {
  type: Types.PATCH_ONBOARDING_SUCCESS
}

export type PatchContactPersonOnboardingFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.PATCH_ONBOARDING_FAIL
}

export type PasswordResetPersonAction = {
  type: Types.PASSWORD_RESET
  payload: {
    data: ContactPerson
  }
}

export type PasswordResetPersonSuccessAction = {
  type: Types.PASSWORD_RESET_SUCCESS
}

export type PasswordResetPersonFailAction = {
  type: Types.PASSWORD_RESET_FAIL
  payload: { errors: Record<string, unknown> }
}

export type ResendInviteAction = {
  type: Types.RESEND_INVITE
  payload: {
    contactPersonId: string
    entityId: string
  }
}

export type ResendInviteSuccessAction = {
  type: Types.RESEND_INVITE_SUCCESS
}

export type ResendInviteFailAction = {
  type: Types.RESEND_INVITE_FAIL
  payload: { errors: Record<string, unknown> }
}

export type ClearSuccessAction = {
  type: Types.CLEAR_SUCCESS
}

export function patchContactPerson(
  contactPersonId: string,
  data: PatchContactPerson
): Action {
  return {
    type: Types.PATCH,
    payload: { contactPersonId, data }
  }
}

export function patchContactPersonSuccess(data: ContactPerson): Action {
  return {
    type: Types.PATCH_SUCCESS,
    payload: { data }
  }
}

export function patchContactPersonFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}

export function patchContactPersonOnboarding(contactPersonId: string): Action {
  return {
    type: Types.PATCH_ONBOARDING,
    payload: { contactPersonId }
  }
}

export function patchContactPersonOnboardingSuccess(): Action {
  return {
    type: Types.PATCH_ONBOARDING_SUCCESS
  }
}

export function patchContactPersonOnboardingFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.PATCH_ONBOARDING_FAIL
  }
}

export function getContactPerson(entityId: string, query: string): Action {
  return {
    type: Types.GET,
    payload: { entityId, query }
  }
}

export function getContactPersonSuccess(data: ContactPerson[]): Action {
  return {
    payload: { data },
    type: Types.GET_SUCCESS
  }
}

export function getContactPersonFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function getContactPersonById(contactPersonId: string): Action {
  return {
    type: Types.GET_BY_ID,
    payload: { contactPersonId }
  }
}

export function getContactPersonByIdSuccess(data: ContactPerson): Action {
  return {
    payload: { data },
    type: Types.GET_BY_ID_SUCCESS
  }
}

export function getContactPersonByIdFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.GET_BY_ID_FAIL
  }
}

export function passwordReset(data: ContactPerson): Action {
  return {
    type: Types.PASSWORD_RESET,
    payload: { data }
  }
}

export function passwordResetSuccess(): Action {
  return {
    type: Types.PASSWORD_RESET_SUCCESS
  }
}

export function passwordResetFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.PASSWORD_RESET_FAIL
  }
}

export function resendInvite(
  entityId: string,
  contactPersonId: string
): Action {
  return {
    type: Types.RESEND_INVITE,
    payload: { entityId, contactPersonId }
  }
}

export function resendInviteSuccess(): Action {
  return {
    type: Types.RESEND_INVITE_SUCCESS
  }
}

export function resendInviteFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.RESEND_INVITE_FAIL
  }
}

export function clearSuccess(): Action {
  return {
    type: Types.CLEAR_SUCCESS
  }
}
