import State from "../state"

export const isLoading = (state: State): boolean =>
  state.campaignPicture.loading

export const getCampaignPictureFile = (state: State): File | undefined => {
  const data = state.campaignPicture.data
  const filename = state.campaignPicture.filename
  if (data && filename) {
    return new File([data], filename, {
      type: data.type
    })
  }
}
