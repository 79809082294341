import React from "react"
import { useTranslate } from "app/hooks/translate"
import { useDispatch } from "react-redux"
import * as appEventActions from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { StyledProfileCard } from "./style"

type Props = {
  entityId: string
}

const SeatProposal = ({ entityId }: Props) => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()

  const handleOnClick = () => {
    dispatch(
      appEventActions.fire({
        uniqueIdentifier: "add-colleague-to-building",
        eventName: PossibleAppEvents.ADD_COLLEAGUE_TO_BUILDING,
        props: { entityId }
      })
    )
  }

  return (
    <StyledProfileCard
      type="error"
      active={false}
      label={translate("cms.seatProposal.admin.needed")}
      roles={translate("cms.seatProposal.admin.needed.explanation")}
      translations={{
        add: translate("cms.button.add")
      }}
      onAdd={handleOnClick}
    />
  )
}

export default SeatProposal
