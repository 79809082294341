import { RentedBuildingConfig } from "app/types/rentedBuildingConfig"

export enum Types {
  PATCH_RENTEDBUILDING_CONFIG = "PATCH_RENTEDBUILDING_CONFIG",
  PATCH_RENTEDBUILDING_CONFIG_SUCCESS = "PATCH_RENTEDBUILDING_CONFIG_SUCCESS",
  PATCH_RENTEDBUILDING_CONFIG_FAIL = "PATCH_RENTEDBUILDING_CONFIG_FAIL"
}

export type Action =
  | PutRentedBuildingConfigAction
  | PutRentedBuildingConfigSuccessAction
  | PutRentedBuildingConfigFailAction

export type PutRentedBuildingConfigAction = {
  payload: { rentedBuildingId: string; configData: any }
  type: Types.PATCH_RENTEDBUILDING_CONFIG
}

export type PutRentedBuildingConfigSuccessAction = {
  payload: { data: any }
  type: Types.PATCH_RENTEDBUILDING_CONFIG_SUCCESS
}

export type PutRentedBuildingConfigFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.PATCH_RENTEDBUILDING_CONFIG_FAIL
}

export function patchRentedBuildingConfig(
  rentedBuildingId: string,
  configData: RentedBuildingConfig
): Action {
  return {
    type: Types.PATCH_RENTEDBUILDING_CONFIG,
    payload: { rentedBuildingId, configData }
  }
}

export function patchRentedBuildingConfigSuccess(data: RentedBuildingConfig): Action {
  return {
    type: Types.PATCH_RENTEDBUILDING_CONFIG_SUCCESS,
    payload: { data }
  }
}

export function patchRentedBuildingConfigFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.PATCH_RENTEDBUILDING_CONFIG_FAIL
  }
}
