import { LastSendingDataStories } from "app/types/dataStories"

export enum Types {
  GET_DATA_STORIES_LAST_SENDING = "GET_DATA_STORIES__LAST_SENDING",
  GET_DATA_STORIES_LAST_SENDING_SUCCESS = "GET_DATA_STORIES__LAST_SENDING_SUCCESS",
  GET_DATA_STORIES_LAST_SENDING_FAIL = "GET_DATA_STORIES__LAST_SENDING_FAIL",
  POST_DATA_STORIES = "POST_DATA_STORIES",
  POST_DATA_STORIES_SUCCESS = "POST_DATA_STORIES_SUCCESS",
  POST_DATA_STORIES_FAIL = "POST_DATA_STORIES_FAIL"
}

export type Action =
  | GetDataStoriesLastSendingAction
  | GetDataStoriesLastSendingSuccessAction
  | GetDataStoriesLastSendingFailAction
  | PostDataStoriesSendingAction
  | PostDataStoriesSendingSuccessAction
  | PostDataStoriesSendingFailAction

export type GetDataStoriesLastSendingAction = {
  type: Types.GET_DATA_STORIES_LAST_SENDING
}

export type GetDataStoriesLastSendingSuccessAction = {
  type: Types.GET_DATA_STORIES_LAST_SENDING_SUCCESS
  payload: LastSendingDataStories
}

export type GetDataStoriesLastSendingFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_DATA_STORIES_LAST_SENDING_FAIL
}

export type PostDataStoriesSendingAction = {
  payload: LastSendingDataStories
  type: Types.POST_DATA_STORIES
}

export type PostDataStoriesSendingSuccessAction = {
  payload: { data: LastSendingDataStories }
  type: Types.POST_DATA_STORIES_SUCCESS
}

export type PostDataStoriesSendingFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.POST_DATA_STORIES_FAIL
}

export function getDataStoriesLastSending(): Action {
  return {
    type: Types.GET_DATA_STORIES_LAST_SENDING
  }
}

export function getDataStoriesLastSendingSuccess(
  data: LastSendingDataStories
): Action {
  return {
    payload: data,
    type: Types.GET_DATA_STORIES_LAST_SENDING_SUCCESS
  }
}

export function getDataStoriesLastSendingFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.GET_DATA_STORIES_LAST_SENDING_FAIL
  }
}

export function postDataStoriesSending({
  sendUser,
  sendDate
}: LastSendingDataStories): Action {
  return {
    type: Types.POST_DATA_STORIES,
    payload: { sendUser, sendDate }
  }
}

export function postDataStoriesSendingSuccess(
  data: LastSendingDataStories
): Action {
  return {
    type: Types.POST_DATA_STORIES_SUCCESS,
    payload: { data }
  }
}

export function postDataStoriesSendingFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.POST_DATA_STORIES_FAIL
  }
}
