import { AxiosPromise } from "axios"
import { rentalApi } from "./api"
import {
  PatchContactPerson,
  InviteContactPerson,
  PasswordReset,
  ResendInvite,
  DeleteContactPersonOnRentedBuildings
} from "app/types/contactPerson"

export const patchContactPerson = (
  contactPersonId: string,
  data: PatchContactPerson
): AxiosPromise => {
  return rentalApi(`/contact_persons/${contactPersonId}`, {
    method: "PATCH",
    data
  })
}

export const getContactPerson = (entityId: string, q: string): AxiosPromise => {
  return rentalApi(`/cms/customers/${entityId}/contact_persons`, {
    method: "GET",
    params: { q }
  })
}

export const getContactPersonById = (contactPersonId: string): AxiosPromise => {
  return rentalApi(`/contact_persons/${contactPersonId}`, {
    method: "GET"
  })
}

export const patchContactPersonOnboarding = (
  contactPersonId: string
): AxiosPromise => {
  return rentalApi(`/contact_persons/${contactPersonId}/onboarded_at`, {
    method: "PATCH"
  })
}

export const inviteContactPersons = (
  entityId: string,
  data: InviteContactPerson
): AxiosPromise => {
  return rentalApi(`/cms/customers/${entityId}/contact_persons/invite`, {
    method: "POST",
    data
  })
}

export const putContactPersons = (
  entityId: string,
  contactPersonId: string,
  data: InviteContactPerson
): AxiosPromise => {
  return rentalApi(
    `/cms/customers/${entityId}/contact_persons/${contactPersonId}`,
    {
      method: "PUT",
      data
    }
  )
}

export const deleteContactPerson = (id: string): AxiosPromise => {
  return rentalApi(`/contact_persons/${id}`, {
    method: "DELETE"
  })
}

export const passwordResetContactPerson = (
  data: PasswordReset
): AxiosPromise => {
  return rentalApi("/cms/contact_persons/send-reset-password-link", {
    method: "POST",
    data
  })
}

export const resendInvite = (data: ResendInvite): AxiosPromise => {
  return rentalApi("/cms/contact_persons/resend", {
    method: "POST",
    data
  })
}

export const getContactPersonContactRoles = (
  entityId: string,
  contactPersonId: string
): AxiosPromise => {
  return rentalApi(
    `/cms/customers/${entityId}/contact_persons/${contactPersonId}/contact_roles`,
    {
      method: "GET"
    }
  )
}

export const deleteContactPersonOnRentedBuildings = (
  id: string,
  data: DeleteContactPersonOnRentedBuildings
): AxiosPromise => {
  return rentalApi(`/cms/contact_persons/${id}/rented_buildings`, {
    method: "DELETE",
    data
  })
}
