import { BaseCard, CardContent, CardActions, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledBaseCard = styled(BaseCard)`
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  width: 100%;
  margin-bottom: 1.6rem;
`
export const StyledCardActions = styled(CardActions)`
  margin-right: 0;
  height: initial;
  align-items: stretch;
`

export const StyledCardContent = styled(CardContent)`
  align-items: stretch;
`

export const CustomerName = styled.h4`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  font-weight: ${theme.fontWeights.bold};
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 0.4rem;
  text-transform: capitalize;
  @media screen and (min-width: 555px) {
    max-width: 30rem;
    overflow: visible;
    text-overflow: initial;
  }
`

export const CustomerSourceId = styled.span`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  font-weight: ${theme.fontWeights.light};
`


export const CustomerActiveAccounts = styled.p`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.paragraph};
  font-weight: ${theme.fontWeights.regular};
`

export const CustomerMetadata = styled.a`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1.6rem 0rem 1.6rem 3.2rem;
  color: ${theme.colors.neutral.darkerGrey};
  text-decoration: none;
  z-index: 100;
  ${theme.outline};
  width: 100%;
  cursor: pointer;
`

export const Button = styled.button`
  ${theme.outline};

  justify-content: center;
  background: ${theme.colors.neutral.white};
  border: none;
  border-left: 1px solid ${theme.colors.neutral.midGrey};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  font-size: ${theme.fontSizes.captionExtraSmall};
  line-height: ${theme.lineHeights.captionExtraSmall};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  cursor: pointer;

  @media screen and (min-width: 415px) {
    width: 12rem;
  }
`
