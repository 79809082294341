import { determineDeleteFlow } from "app/redux/deleteContactPerson/deleteContactPerson.actions"
import React from "react"
import { PopUp } from "@paudigital/wdp-components"
import { useDispatch, useSelector } from "react-redux"
import * as deleteFlowSelectors from "app/redux/deleteContactPerson/deleteContactPerson.selectors"
import { DeleteContactPersonFlows } from "app/types/deleteContactPersonFlow"
import DeleteContactPersonSingleBuildingModal from "./flows/DeleteContactPersonSingleBuildingModal"
import DeleteContactPersonMultiBuildingModal from "./flows/DeleteContactPersonMultiBuildingModal"

type DeleteContactPersonProps = {
  entityId: string
  contactPersonIdToDelete: string
  onClose: () => void
}

type Props = DeleteContactPersonProps

const DeleteContactPerson = ({
  entityId,
  contactPersonIdToDelete,
  onClose
}: Props) => {
  const dispatch = useDispatch()
  const deleteFlow = useSelector(deleteFlowSelectors.getDeleteFlow)
  const deleteFlowIsLoading = useSelector(deleteFlowSelectors.isLoading)

  React.useEffect(() => {
    dispatch(determineDeleteFlow(
      entityId,
      contactPersonIdToDelete
    ))
  }, [dispatch, entityId, contactPersonIdToDelete])
  
  return (
    <PopUp onClose={onClose} show>
      <>
        {deleteFlowIsLoading && <></>}
        {deleteFlow === DeleteContactPersonFlows.DELETE_SINGLE_BUILDING && <DeleteContactPersonSingleBuildingModal onCancel={onClose} />}
        {deleteFlow === DeleteContactPersonFlows.DELETE_MULTI_BUILDING && <DeleteContactPersonMultiBuildingModal onCancel={onClose} />}
      </>
    </PopUp>
  )
}

export default DeleteContactPerson
