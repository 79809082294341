import { useTranslate } from "hooks/translate"
import React from "react"
import { CustomerTitle, TitleCaption } from "./style"

export type Props = {
  title: string,
  count: number
}

const CustomerStatus = ({title, count}:Props ) => {
  const {translate} = useTranslate()
  return (
    <div>
      <CustomerTitle>{title} ({count})</CustomerTitle>
      {count === 0 && <TitleCaption>{translate("cms.customerOverview.noClients.message")}</TitleCaption>}
    </div>
  )
}

export default CustomerStatus