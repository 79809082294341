import auth0 from "auth0-js"
import { AUDIENCE, AUTH0_DOMAIN, MYWDP_CLIENT_ID, MYWDP_REDIRECT_URI } from "./config"

export const auth0Instance = new auth0.WebAuth({
  audience: AUDIENCE,
  clientID: MYWDP_CLIENT_ID,
  domain: AUTH0_DOMAIN,
  redirectUri: MYWDP_REDIRECT_URI,
  responseType: "token id_token"
})

