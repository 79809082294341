import { Button } from "@paudigital/wdp-components"
import {
  deactivateAll,
  getCampaignCandidates
} from "app/redux/campaign/campaign.actions"
import { useTranslate } from "hooks/translate"
import React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  ButtonContainer,
  CampaignActionsContainer,
  StyledFirstButton,
  StyledTitle
} from "./style"

export const CampaignActions = () => {
  const { translate } = useTranslate()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleDeactivate = () => {
    dispatch(deactivateAll())
  }

  const createNew = () => {
    dispatch(getCampaignCandidates())
    navigate("/marketing-dashboard/create-new-campaign")
  }

  return (
    <CampaignActionsContainer>
      <StyledTitle>
        {translate("cms.marketing-dashboard.actions.title")}
      </StyledTitle>
      <ButtonContainer>
        <StyledFirstButton
          onClick={handleDeactivate}
          type="secondary"
          label={translate("cms.marketing-dashboard.actions.deactivate_all")}
        />
        <Button
          type="primary"
          label={translate("cms.marketing-dashboard.actions.create")}
          onClick={createNew}
        />
      </ButtonContainer>
    </CampaignActionsContainer>
  )
}
