/* eslint-disable import/no-anonymous-default-export */
import {} from "app/types/campaign"
import { LastSendingDataStories } from "app/types/dataStories"
import { Action, Types } from "./dataStories.actions"

export type DataStoriesState = {
  data: LastSendingDataStories | undefined
  errors?: Record<string, unknown>
  loading: boolean
}

const initialState: DataStoriesState = {
  data: {
    sendDate: new Date(),
    sendUser: ""
  },
  errors: undefined,
  loading: false
}

export default (state = initialState, action: Action): DataStoriesState => {
  switch (action.type) {
    case Types.GET_DATA_STORIES_LAST_SENDING:
      return {
        ...state,
        loading: true
      }

    case Types.GET_DATA_STORIES_LAST_SENDING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      }

    case Types.GET_DATA_STORIES_LAST_SENDING_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        data: initialState.data
      }

    case Types.POST_DATA_STORIES:
      return {
        ...state,
        loading: true
      }

    case Types.POST_DATA_STORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data
      }

    case Types.POST_DATA_STORIES_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        data: undefined
      }
    default:
      return state
  }
}
