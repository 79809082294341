export enum NoEnergyReasons {
  INSTALLATION_ONGOING= "installation_ongoing",
  MULTI_TENANT_SITE = "multi_tenant_site",
  METERS_INSTALLED_BUT_NO_SIGNAL = "meters_installed_but_no_signal",
  NO_ACCESS_TO_METERS = "no_access_to_meters",
  NANOGRID_READY = "nanogrid_ready"
}

export type RentedBuildingConfig = {
  maxSeatCount?: number
  noEnergyReason?: NoEnergyReasons
}
