import { ProfileCard, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const RentedBuildingSeatWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: unset;
    margin-bottom: 1.6rem;

    &:last-child {
      margin-bottom: 0rem;
    }
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  padding: 0 0 0 2.4rem;
  min-width: 18.2rem;
  justify-content: center;
`

export const StyledProfileCard = styled(ProfileCard)`
  cursor: default;
  margin-bottom: 0rem;
`
