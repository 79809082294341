import { GridColDef } from "@mui/x-data-grid"

export type DataGridColumnsHeader = {
  sourceId: string
  name: string
  buildingYear: string
  country: string
  availability: string
}

export const renderColumns = ({
  sourceId,
  name,
  buildingYear,
  country,
  availability
}: DataGridColumnsHeader): GridColDef[] => [
  {
    field: "sourceId",
    headerName: sourceId,
    width: 100
  },
  {
    field: "name",
    headerName: name,
    width: 200
  },
  {
    field: "buildingYear",
    headerName: buildingYear,
    width: 230
  },
  {
    field: "country",
    headerName: country,
    width: 150
  },
  {
    field: "availability",
    headerName: availability,
    width: 200
  }
]
