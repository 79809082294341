import CustomerOverviewPage from "app/components/CustomerOverviewPage"
import React from "react"

const UserManagement = () => {
  return (
    <CustomerOverviewPage/>
  )
}

export default UserManagement
