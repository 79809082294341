import { eachHourOfInterval, parseISO } from "date-fns"

export const checkIfDatesAre24HoursApart = (
  firstDate: string,
  secondDate: string
) => {
  const date1 = parseISO(firstDate)
  const date2 = parseISO(secondDate)
  const interval = eachHourOfInterval({ start: date1, end: date2 })
  return interval.length > 24
}
