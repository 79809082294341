import {
  InviteContactPerson,
  RentedBuildingContactRoles
} from "app/types/contactPerson"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getWhitelistedDomains,
  initialValues,
  isDomainWhitelisted,
  removeBuildingsWithoutRolesFromContactPerson,
  validationSchema
} from "./config"
import * as actions from "app/redux/rentedBuildingContactPerson/rentedBuildingContactPerson.actions"
import { PossibleContactRoles } from "app/enums/roles"
import {
  DropDownWrapper,
  StyledFormWrapper,
  ModalTitle,
  Container,
  StyledEmail,
  ButtonWrapper,
  StyledButton,
  AnchorScrollDiv
} from "./style"
import * as contactActions from "app/redux/contactPerson/contactPerson.actions"
import * as contactSelectors from "app/redux/contactPerson/contactPerson.selectors"
import * as rentedBuildingContactPersonSelectors from "app/redux/rentedBuildingContactPerson/rentedBuildingContactPerson.selectors"
import * as customerWithRentedBuildingsSelector from "app/redux/customerWithRentedBuildings/customerWithRentedBuildings.selectors"
import * as customerWithRentedBuildingSelector from "app/redux/customerWithRentedBuildings/customerWithRentedBuildings.selectors"
import MultipleRentedBuildings from "./MultipleRentedBuilding"
import { InputElement, SlideIn } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import AppEventsProvider from "app/components/AppEventsProvider"
import * as contactPersonContactRolesActions from "app/redux/contactPersonContactRoles/contactPersonContactRoles.actions"
import * as contactPersonContactRolesSelectors from "app/redux/contactPersonContactRoles/contactPersonContactRoles.selectors"
import * as appEventActions from "app/redux/appEvents/appEvents.actions"
import SingleRentedBuilding from "./SingleRentedBuilding"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { remove } from "app/redux/appEvents/appEvents.actions"
import LanguageSelect from "../LanguageSelect"
import { OnChangeValue } from "react-select"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"

type Props = {
  entityId: string
  id?: string
  onClose: () => void
}

export const AddColleagueToBuilding = ({ onClose, entityId, id }: Props) => {
  const dispatch = useDispatch()
  const [isEditing, setIsEditing] = useState(id ? true : false)
  const { translate } = useTranslate()

  const hasApiErrors = useSelector(
    rentedBuildingContactPersonSelectors.hasErrors
  )
  const contactPersons = useSelector(contactSelectors.getContactPerson)
  const contactPersonContactRoles = useSelector(
    contactPersonContactRolesSelectors.getContactPersonContactRoles
  )
  const isLoading = useSelector(rentedBuildingContactPersonSelectors.isLoading)

  const customerWithRentedBuildings = useSelector(
    customerWithRentedBuildingSelector.getCustomerWithRentedBuildings
  )
  const customerWhitelistedDomains = getWhitelistedDomains(
    customerWithRentedBuildings?.allowedEmails ?? []
  )
  const hasSingleRentedBuilding =
    customerWithRentedBuildings?.rentedBuildings.length === 1

  const user: InviteContactPerson | undefined = useSelector(
    customerWithRentedBuildingsSelector.getContactPersonById(id)
  )

  const [searchValue, setSearchValue] = React.useState("")

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    dirty,
    values,
    setFieldValue,
    setFieldTouched
  } = useFormik({
    initialValues: user ? user : initialValues,
    onSubmit: (c: InviteContactPerson) => {
      if (isValid) {
        const user = removeBuildingsWithoutRolesFromContactPerson(c)
        dispatch(
          isEditing && id
            ? actions.putContactPerson(entityId, id, {
                ...user,
                contactRolesPerRentedBuilding: [
                  ...user.contactRolesPerRentedBuilding,
                  ...contactPersonContactRoles
                ]
              })
            : actions.postContactPerson(entityId, user)
        )
      }
    },
    validationSchema
  })

  const {
    email,
    firstName,
    lastName,
    contactRolesPerRentedBuilding,
    languagePreference
  } = values

  const hasErrors = (name: string): boolean => {
    return errors[name as keyof InviteContactPerson] &&
      touched[name as keyof InviteContactPerson]
      ? true
      : false
  }

  const emailHasErrors = (): boolean => {
    if (touched["email"]) {
      return (
        !!errors["email"] ||
        !isDomainWhitelisted(customerWhitelistedDomains, email)
      )
    }
    return false
  }

  useEffect(() => {
    if (id) {
      dispatch(
        contactPersonContactRolesActions.getContactPersonContactRoles(
          entityId,
          id
        )
      )
    }
  }, [id, dispatch, entityId])

  const determineEmailErrorMessage = (): string => {
    if (hasErrors("email")) return translate("cms.email.error")
    if (!isDomainWhitelisted(customerWhitelistedDomains, email))
      return translate("cms.email.nonWhitelisted", {
        domains: customerWhitelistedDomains.join(", ")
      })
    return ""
  }

  const handleCheckboxChange = (
    val: boolean,
    name: string,
    rentedBuildingId: string
  ) => {
    const existingRentedBuilding = contactRolesPerRentedBuilding.find(
      (c: RentedBuildingContactRoles) => c.rentedBuildingId === rentedBuildingId
    )
    if (existingRentedBuilding) {
      const newRoles = val
        ? [...existingRentedBuilding.roles, name as PossibleContactRoles]
        : existingRentedBuilding.roles.filter(
            (r: PossibleContactRoles) => r !== name
          )

      const filteredRentedBuildings = contactRolesPerRentedBuilding.filter(
        c => c.rentedBuildingId !== existingRentedBuilding.rentedBuildingId
      )

      setFieldValue("contactRolesPerRentedBuilding", [
        ...filteredRentedBuildings,
        {
          ...existingRentedBuilding,
          roles: newRoles
        }
      ])
    } else {
      setFieldValue("contactRolesPerRentedBuilding", [
        ...contactRolesPerRentedBuilding,
        {
          rentedBuildingId: rentedBuildingId,
          roles: [name]
        }
      ])
    }
  }

  const handleEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const email: string = e.target.value
    setFieldTouched("email", true, true)
    handleBlur(e)
    setFieldValue("email", email)
    const contactPerson = contactPersons.find(c => c.email === email)
    if (contactPerson) {
      setIsEditing(true)
      dispatch(
        contactPersonContactRolesActions.getContactPersonContactRoles(
          entityId,
          contactPerson.id
        )
      )
      setFieldValue("firstName", contactPerson.firstName)
      setFieldValue("lastName", contactPerson.lastName)
    }
  }

  const handleCustomBlur = (e: React.FocusEvent<any>) => {
    setFieldValue(e.target.name, e.currentTarget.value)
    handleBlur(e)
  }

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue)
    if (isEditing && searchValue !== user?.email) {
      dispatch(
        appEventActions.fire({
          local: true,
          eventName: PossibleAppEvents.NOTIFICATION,
          uniqueIdentifier: "change-email-warning",
          props: {
            title: translate("cms.modal.warning.email.title"),
            description: translate("cms.modal.warning.email.description", {
              oldEmail: user?.email
            }),
            variant: "warning",
            show: true,
            onClose: undefined
          }
        })
      )
    } else if (isEditing) {
      dispatch(remove("change-email-warning"))
    }
    setFieldValue("email", searchValue)
  }

  const handleOnClose = () => {
    onClose()
    dispatch(
      appEventActions.removeAllEventsByEventName(PossibleAppEvents.NOTIFICATION)
    )
  }

  const handleLanguageChange = (option: OnChangeValue<CustomOptionType, false>) => {
    const lang = (option as { value: string }).value
    setFieldValue("languagePreference", lang)
  }

  React.useEffect(() => {
    if (!user && contactPersonContactRoles.length > 0) {
      setFieldValue(
        "contactRolesPerRentedBuilding",
        contactPersonContactRoles.filter(cpc => cpc.entityId === entityId)
      )
    }
  }, [contactPersonContactRoles, setFieldValue, user, email, entityId])

  React.useEffect(() => {
    dispatch(contactPersonContactRolesActions.clearContactPersonContactRoles())
  }, [dispatch])

  React.useEffect(() => {
    if (searchValue) {
      dispatch(contactActions.getContactPerson(entityId, searchValue))
    }
  }, [dispatch, entityId, searchValue])

  React.useEffect(() => {
    if (hasApiErrors) {
      const element = document.getElementById("anchor-scroll-div")
      element?.scrollIntoView()
    }
  }, [hasApiErrors])

  return (
    <SlideIn show onClose={handleOnClose} showCloseIcon>
      <>
        <AnchorScrollDiv id="anchor-scroll-div" />
        <StyledFormWrapper>
          <Container>
            <ModalTitle>
              {translate(
                `cms.modal.contactperson.rentedbuilding.${
                  isEditing ? "edit" : "add"
                }.title`
              )}
            </ModalTitle>
            <form onSubmit={handleSubmit} noValidate>
              <DropDownWrapper>
                <StyledEmail
                  isEditing={isEditing}
                  width="100%"
                  name="email"
                  label={translate("cms.email.label")}
                  options={contactPersons.map(c => c.email)}
                  onChange={handleSearch}
                  onBlur={handleEmailBlur}
                  error={emailHasErrors()}
                  errorMessage={determineEmailErrorMessage()}
                  value={email}
                  placeholder={email}
                />
                <AppEventsProvider local />
              </DropDownWrapper>
              <InputElement
                label={translate("cms.input.firstName.label")}
                name="firstName"
                onChange={handleChange}
                onBlur={handleCustomBlur}
                value={firstName}
                error={hasErrors("firstName")}
                errorMessage={translate("cms.input.required")}
              />
              <InputElement
                label={translate("cms.input.lastName.label")}
                name="lastName"
                onChange={handleChange}
                onBlur={handleCustomBlur}
                value={lastName}
                error={hasErrors("lastName")}
                errorMessage={translate("cms.input.required")}
              />
              <LanguageSelect
                defaultLanguage={languagePreference}
                label={translate(
                  "cms.modal.contactPerson.language.select.label"
                )}
                onChange={handleLanguageChange}
              />
              {hasSingleRentedBuilding ? (
                <SingleRentedBuilding
                  error={hasErrors("contactRolesPerRentedBuilding")}
                  data={contactRolesPerRentedBuilding}
                  onChange={handleCheckboxChange}
                />
              ) : (
                <MultipleRentedBuildings
                  error={hasErrors("contactRolesPerRentedBuilding")}
                  data={contactRolesPerRentedBuilding}
                  onChange={handleCheckboxChange}
                  isEditing={isEditing}
                  id={id}
                />
              )}{" "}
              <ButtonWrapper>
                <StyledButton
                  type="secondary"
                  label={translate("cms.button.cancel")}
                  onClick={onClose}
                />
                <StyledButton
                  label={translate("cms.button.save")}
                  disabled={
                    isLoading ||
                    !dirty ||
                    !isValid
                  }
                  submit
                />
              </ButtonWrapper>
            </form>
          </Container>
        </StyledFormWrapper>
      </>
    </SlideIn>
  )
}
