import { InputElement, Toggle, ChevronIcon } from "@paudigital/wdp-components"
import { Languages } from "app/enums/languages"
import { useTranslate } from "app/hooks/translate"
import {
  CampaignFormData,
  LanguageFormData,
  Campaign
} from "app/types/campaign"
import { FormikErrors, FormikTouched } from "formik"
import React, { useState } from "react"
import {
  Container,
  LinkInfoContainer,
  FormContainer,
  RotateContainer,
  LanguageFormHeader
} from "./style"

type LanguageFormProps = {
  language: Languages
  values: CampaignFormData
  handleChange: (e: React.ChangeEvent<any>) => void
  errors: FormikErrors<Campaign["languageData"]>
  touched: FormikTouched<Campaign["languageData"]>
  validateField: (name: string) => Promise<void> | Promise<string | undefined>
  handleBlur: (e: React.FocusEvent<any>) => void
  setFieldValue: (
    field: string,
    value: Campaign["languageData"],
    shouldValidate?: boolean
  ) => Promise<void> | Promise<FormikErrors<Campaign>>
}

const LanguageForm = ({
  language,
  values,
  handleChange,
  errors,
  touched,
  validateField,
  handleBlur,
  setFieldValue
}: LanguageFormProps) => {
  const { translate } = useTranslate()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const errorData = errors?.[language]
  const touchData = touched?.[language]

  const hasLink = values[language]?.hasLink

  const hasErrors = (name: keyof LanguageFormData) => {
    return errorData && errorData[name] && touchData && touchData[name]
      ? true
      : false
  }

  const handleLinkToggle = () => {
    setFieldValue("languageData", {
      ...values,
      [language]: {
        ...values[language],
        hasLink: !values[language]?.hasLink
      }
    })
  }

  const onBlur = (e: React.FocusEvent<any>) => {
    validateField("languageData")
    handleBlur(e)
  }

  const toggleLanguageSection = () => {
    setIsOpen(prev => !prev)
  }

  return (
    <Container>
      <LanguageFormHeader onClick={toggleLanguageSection}>
        {translate(`cms.marketing-dashboard.checkbox.${language}.label`)}
        <RotateContainer isOpen={isOpen}>
          <ChevronIcon />
        </RotateContainer>
      </LanguageFormHeader>
      {isOpen && (
        <FormContainer>
          <InputElement
            charCount
            charLimit={57}
            charTranslation={translate("cms.input.characters.label")}
            label={translate("cms.marketing-dashboard.title.label")}
            name={`languageData.${language}.title`}
            onChange={handleChange}
            onBlur={onBlur}
            value={values[language]?.title}
            error={hasErrors("title")}
            errorMessage={hasErrors("title") && translate(errorData!["title"]!)}
          />
          <Toggle
            toggled={!!hasLink}
            onClick={handleLinkToggle}
            label={translate("cms.marketing-dashboard.toggle.label")}
          />
          {hasLink && (
            <LinkInfoContainer>
              <InputElement
                charCount
                charLimit={25}
                charTranslation={translate("cms.input.characters.label")}
                label={translate("cms.marketing-dashboard.link.label")}
                name={`languageData.${language}.label`}
                onChange={handleChange}
                onBlur={onBlur}
                value={values[language].label}
                error={hasErrors("label")}
                errorMessage={
                  hasErrors("label") && translate(errorData!["label"]!)
                }
              />
              <InputElement
                label={translate("cms.marketing-dashboard.link-url.label")}
                name={`languageData.${language}.url`}
                onChange={handleChange}
                value={values[language].url}
                onBlur={onBlur}
                error={hasErrors("url")}
                errorMessage={hasErrors("url") && translate(errorData!["url"]!)}
              />
            </LinkInfoContainer>
          )}
        </FormContainer>
      )}
    </Container>
  )
}
export default LanguageForm
