import { CMSActionCard, Button } from "@paudigital/wdp-components"
import { useTranslate } from "app/hooks/translate"
import React from "react"
import { ActionCardWrapper, ActionCardHeader, ActionCardText } from "./style"
import * as actions from "app/redux/customerWithRentedBuildings/customerWithRentedBuildings.actions"
import { useDispatch } from "react-redux"

type Props = {
  entityId: string
}

const ResendInvitationsAction = ({ entityId }: Props) => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()

  const handleOnClick = () => {
    dispatch(actions.resendInviteBulk(entityId))
  }

  return (
    <ActionCardWrapper>
      <CMSActionCard type="warning">
        <ActionCardHeader>
          {translate("cms.action.invitations.expired.header")}
        </ActionCardHeader>
        <ActionCardText>
          {translate("cms.action.invitations.expired.body.text")}
        </ActionCardText>
        <Button
          label={translate("cms.button.resendInvitations")}
          onClick={handleOnClick}
        />
      </CMSActionCard>
    </ActionCardWrapper>
  )
}

export default ResendInvitationsAction
