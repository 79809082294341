import { getCustomerWithRentedBuildings } from "app/api/customerWithRentedbuildings"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest, select, all } from "redux-saga/effects"
import { getExpiredInvitationContactPersons } from "./customerWithRentedBuildings.selectors"
import * as actions from "./customerWithRentedBuildings.actions"
import * as appEventActions from "app/redux/appEvents/appEvents.actions"
import { resendInvite } from "app/api/contactPerson"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { ContactPerson } from "app/types/contactPerson"

export function* getCustomerWithRentedBuildingsFlow(
  action: actions.GetCustomerWithRentedBuildingsAction
): SagaIterator {
  try {
    const response = yield call(getCustomerWithRentedBuildings, action.payload)
    yield put(actions.getCustomerWithRentedBuildingsSuccess(response.data))
  } catch (e: any) {
    yield put(actions.getCustomerWithRentedBuildingsFail(e))
  }
}

export function* resendInviteBulk(
  action: actions.ResendInviteBulkAction
): SagaIterator {
  try {
    const { entityId } = action.payload
    const contactPersons = yield select(getExpiredInvitationContactPersons)
    const uniqueContactPersonIds = [
      ...new Set<string>(contactPersons.map((cp: ContactPerson) => cp.id))
    ]
    yield all(
      uniqueContactPersonIds.map((contactPersonId: string) =>
        call(resendInvite, { entityId, contactPersonId })
      )
    )
    yield put(actions.getCustomerWithRentedBuildings({ customerId: entityId }))
    yield put(actions.resendInviteBulkSuccess())
    yield put(
      appEventActions.fire({
        uniqueIdentifier: "resend-invite-bulk-error",
        eventName: PossibleAppEvents.RESEND_INVITE_BULK_SUCCESS
      })
    )
  } catch (e: any) {
    yield put(actions.getCustomerWithRentedBuildingsFail(e))
  }
}

export default function* customerWithRentedBuildingsSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getCustomerWithRentedBuildingsFlow)
  yield takeLatest(actions.Types.RESEND_INVITE_BULK, resendInviteBulk)
}
