import { Button, MailSendIcon, SlideIn } from "@paudigital/wdp-components"
import { useTranslate } from "app/hooks/translate"
import React from "react"
import { StyledFormWrapper, ModalDescription } from "./style"

type Props = {
  name: string
  onClose: () => void
}

export const PasswordResetModal = ({ name, onClose }: Props) => {
  const { translate } = useTranslate()
  return (
    <SlideIn onClose={onClose} showCloseIcon show>
      <StyledFormWrapper>
        <MailSendIcon />
        <ModalDescription>
          {translate("cms.modal.passwordreset.description", { name })}
        </ModalDescription>
        <Button onClick={onClose} label={translate("cms.modal.button.text")} />
      </StyledFormWrapper>
    </SlideIn>
  )
}

export default PasswordResetModal

