import React from "react"
import { PossibleContactRoles } from "app/enums/roles"
import {
  ContactPerson,
  RentedBuildingContactRoles
} from "app/types/contactPerson"
import { BuildingWrapper } from "./style"
import {
  Separator,
  InputLabel,
  InputError,
  NestedCheckbox
} from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { RentedBuildingWithContactPersons } from "app/types/rentedBuildingWithContactPersons"
import { useSelector } from "react-redux"
import * as customerWithRentedBuildingSelector from "app/redux/customerWithRentedBuildings/customerWithRentedBuildings.selectors"

type Props = {
  data: RentedBuildingContactRoles[]
  error: boolean
  isEditing: boolean
  onChange: (val: boolean, name: string, rentedBuildingId: string) => void
  id?: string
}

const MultipleRentedBuildings = ({ error, data, onChange, id }: Props) => {
  const { translate } = useTranslate()

  const customerWithRentedBuildings = useSelector(
    customerWithRentedBuildingSelector.getCustomerWithRentedBuildings
  )

  const transformData = (id: string) => {
    const rentedBuilding = customerWithRentedBuildings?.rentedBuildings?.find(
      r => r.id === id
    )
    const existingRentedBuilding = data.find(
      (c: RentedBuildingContactRoles) => c.rentedBuildingId === id
    )
    return {
      parent: {
        label: `${rentedBuilding?.street} ${rentedBuilding?.houseNo}, ${rentedBuilding?.city}`
      },
      children: Object.values(PossibleContactRoles).map(r => ({
        name: r,
        label: r,
        guidanceText: translate(
          `cms.modal.contactperson.roles.${r.replace(/\s/g, "")}.guidance`
        ),
        selected: existingRentedBuilding?.roles.includes(r as any) || false
      }))
    }
  }

  const handleChildSelect = (val: boolean, name: string, id?: string) => {
    onChange(val, name, id!)
  }

  const isFull = (contactPersons: ContactPerson[], maxSeatCount: number) => {
    const contactPersonCount = contactPersons.length >= maxSeatCount
    return id
      ? contactPersonCount && !contactPersons.some(e => e.id === id)
      : contactPersonCount
  }

  return (
    <>
      <InputLabel
        label={translate("cms.modal.contactperson.title.facilities.label")}
        guidanceText={translate(
          "cms.modal.contactperson.title.facilities.guidance"
        )}
      />
      <BuildingWrapper>
        {customerWithRentedBuildings?.rentedBuildings?.map(
          (r: RentedBuildingWithContactPersons, i: number) => {
            const contactPersons = r.contactPersons
            const full = isFull(contactPersons, r.maxSeatCount)
            return (
              <React.Fragment key={r.id}>
                <NestedCheckbox
                  defaultCollapsed={false}
                  id={r.id}
                  data={transformData(r.id)}
                  onChildSelect={handleChildSelect}
                  full={full}
                  fullText={translate(
                    "cms.modal.contactperson.rentedbuilding.full.description",
                    { maxSeatCount: r.maxSeatCount }
                  )}
                />
                {i !==
                  customerWithRentedBuildings?.rentedBuildings.length - 1 && (
                  <Separator />
                )}
              </React.Fragment>
            )
          }
        )}
      </BuildingWrapper>
      {error && (
        <InputError
          errorMessage={translate("portal.onboarding.step4.roles.label")}
        />
      )}
    </>
  )
}

export default MultipleRentedBuildings
