import styled from "styled-components"
import { ColorResult } from "react-color"
import { convertRgbObjectToString } from "./utils"
export const ColorPickerPreview = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 2px;
  background: ${({ previewColor }: { previewColor: ColorResult }) =>
    convertRgbObjectToString(previewColor.rgb)};
`

export const ColorPickerPreviewContainer = styled.div`
  margin-left: 1rem;
  padding: 0.3rem;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`

export const ColorPickerPopOver = styled.div`
  position: absolute;
  z-index: 10;
`

export const ColorPickerCover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const ColorPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.2rem 0;
`
