/* eslint-disable import/no-anonymous-default-export */
import { Languages } from "app/enums/languages"
import {
  Campaign,
  CampaignCandidates,
  CampaignsPaginationData,
  ModulesEnum
} from "app/types/campaign"
import { Action, Types } from "./campaign.actions"

export const initialValues: Campaign = {
  id: undefined,
  title: "",
  languageData: {
    [Languages.EN_GB]: {
      title: "",
      hasLink: false
    },
    [Languages.NL_BE]: {
      title: "",
      hasLink: false
    },
    [Languages.NL_NL]: {
      title: "",
      hasLink: false
    },
    [Languages.FR_FR]: {
      title: "",
      hasLink: false
    },
    [Languages.RO_RO]: {
      title: "",
      hasLink: false
    }
  },
  active: false,
  color: {
    hsl: {
      h: 160.5,
      s: 0.192299999,
      l: 0.4078,
      a: 1
    },
    hex: "#547C6F",
    rgb: {
      r: 84,
      g: 124,
      b: 111,
      a: 1
    },
    source: "initial"
  },
  module: ModulesEnum.DASHBOARD,
  entityIds: []
}

export type CampaignState = {
  data: Campaign[]
  campaign: Campaign
  paginationData: CampaignsPaginationData
  bannerPictureUrl?: string
  errors?: Record<string, unknown>
  loading: boolean
  campaignCandidates?: CampaignCandidates[]
}

const initialState: CampaignState = {
  data: [],
  campaign: initialValues,
  paginationData: {
    page: 1,
    perPage: 20,
    pages: 1
  },
  bannerPictureUrl: undefined,
  errors: undefined,
  loading: false,
  campaignCandidates: []
}

export default (state = initialState, action: Action): CampaignState => {
  switch (action.type) {
    case Types.POST_CAMPAIGN:
      return {
        ...state,
        loading: true
      }

    case Types.POST_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.POST_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.PUT_CAMPAIGN:
      return {
        ...state,
        loading: true
      }

    case Types.PUT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.PUT_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.GET_CAMPAIGN:
      return {
        ...state,
        loading: true
      }

    case Types.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        campaign: action.payload
      }

    case Types.GET_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.GET_CAMPAIGNS:
      return {
        ...state,
        loading: true
      }

    case Types.GET_CAMPAIGNS_SUCCES:
      return {
        ...state,
        data: action.payload.campaigns,
        paginationData: {
          page: action.payload.page,
          perPage: action.payload.perPage,
          pages: action.payload.pages
        },
        loading: false
      }

    case Types.GET_CAMPAIGNS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.DEACTIVATE_ALL:
      return {
        ...state,
        loading: true
      }

    case Types.DEACTIVATE_ALL_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.DEACTIVATE_ALL_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.RESET_CAMPAIGN:
      return {
        ...state,
        loading: false,
        campaign: initialValues,
        bannerPictureUrl: undefined,
        campaignCandidates: undefined
      }

    case Types.GET_CAMPAIGN_CANDIDATES:
      return {
        ...state,
        loading: true
      }

    case Types.GET_CAMPAIGN_CANDIDATES_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.GET_CAMPAIGN_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignCandidates: action.payload
      }
    case Types.DEACTIVATE_ALL_CANDIDATES_IN_CAMPAIGN:
      return {
        ...state,
        campaignCandidates: state.campaignCandidates?.map(
          (c: CampaignCandidates) => {
            return { ...c, activeInCampaign: false }
          }
        ),
        campaign: { ...initialValues, active: state.campaign.active }
      }

    default:
      return state
  }
}
