import { AppEvent } from "./types"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"

export enum Types {
  FIRE = "FIRE_EVENT",
  REMOVE = "REMOVE_EVENT",
  REMOVE_ALL_EVENTS_BY_EVENT_NAME = "REMOVE_ALL_EVENTS_BY_EVENT_NAME",
  CLEAR = "CLEAR_EVENT"
}

export type Action =
  | FireEventAction
  | RemoveEventAction
  | RemoveAllEventsByEventNameAction
  | ClearEventAction

export type FireEventAction = {
  payload: { event: AppEvent }
  type: Types.FIRE
}

export type RemoveEventAction = {
  payload: { uniqueIdentifier: string }
  type: Types.REMOVE
}

export type RemoveAllEventsByEventNameAction = {
  payload: { eventName: PossibleAppEvents }
  type: Types.REMOVE_ALL_EVENTS_BY_EVENT_NAME
}

export type ClearEventAction = {
  type: Types.CLEAR
}

export function fire(event: AppEvent): Action {
  return {
    payload: { event },
    type: Types.FIRE
  }
}

export function remove(uniqueIdentifier: string): Action {
  return {
    payload: { uniqueIdentifier },
    type: Types.REMOVE
  }
}

export function removeAllEventsByEventName(
  eventName: PossibleAppEvents
): Action {
  return {
    payload: { eventName },
    type: Types.REMOVE_ALL_EVENTS_BY_EVENT_NAME
  }
}

export function clear(): Action {
  return {
    type: Types.CLEAR
  }
}
