import { Action, Types } from "./rentedBuilding.actions"

export type RentedBuildingState = {
  data: any
  errors?: Record<string, unknown>
  loading: boolean
}

const initialState: RentedBuildingState = {
  data: [],
  errors: undefined,
  loading: false
}

export default function rentedBuildingReducer(state = initialState, action: Action): RentedBuildingState {
  switch (action.type) {
    case Types.PATCH_RENTEDBUILDING_CONFIG:
      return {
        ...state,
        loading: true
      }

    case Types.PATCH_RENTEDBUILDING_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: undefined
      }

    case Types.PATCH_RENTEDBUILDING_CONFIG_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    default:
      return state
  }
}
