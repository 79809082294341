import { Button, theme } from "@paudigital/wdp-components";
import styled from "styled-components";

export const CampaignActionsContainer = styled.div`
  width: 100%;
  background: ${theme.colors.neutral.white};
  border-radius: 4px;
  margin-top: -8.4rem;
  margin-bottom: 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 3.2rem;
  box-sizing: border-box;
`

export const StyledTitle = styled.h2`
  font-size: ${theme.fontSizes.h5};
  line-height: ${theme.lineHeights.h5};
  color:${theme.colors.neutral.darkerGrey};
  font-weight:${theme.fontWeights.bold};
`

export const ButtonContainer = styled.div`
  display:flex;
`

export const StyledFirstButton = styled(Button)`
  margin-right: 1.6rem;
`