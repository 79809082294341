import { DropDown, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const RentedBuildingSeatOverviewWrapper = styled.div`
  background: ${theme.colors.neutral.white};
  margin: 0 0 3.2rem;
  padding: 1.6rem;
  border-radius: 4px;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
    align-items: center;
    padding: 5.6rem;
  }
`

export const StyledDropDown = styled(DropDown)`
  background: ${theme.colors.neutral.white};
  border-radius: 1rem;
  border: 1px solid ${theme.colors.neutral.darkGrey};
  width: 100%;

  @media only screen and (${theme.breakpoints.desktop}) {
    width: 42rem;
  }
`

export const RentedBuildingConfigContainer = styled.div`
  width: 100%;
  margin-bottom: 5rem;
  @media only screen and (${theme.breakpoints.desktop}) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
  }
`

export const DropdownContainer = styled.div`
  margin-bottom: 1rem;
  @media only screen and (${theme.breakpoints.desktop}) {
    width: 100%;
  }
`

export const Subtitle = styled.h6`
  font-size: ${theme.fontSizes.h6};
  line-height: ${theme.fontSizes.h6};
  font-weight: ${theme.fontWeights.medium};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 1.6rem;
  width: 25rem;
`
