import { Action, Types } from "./customerStatistics.actions"
import { CustomerStatistic } from "app/types/customerStatistic"

export type CustomerStatisticsState = {
  data: CustomerStatistic
  errors?: Record<string, unknown>
  loading: boolean
}

const initialState: CustomerStatisticsState = {
  data: {
    data: [],
    page: 0,
    perPage: 0,
    pages: 0,
    total: 0
  },
  errors: undefined,
  loading: false
}

export default function customerStatisticsReducer(state = initialState, action: Action) {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true,
        data: { ...initialState.data }
      }
    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }
    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }
    default:
      return state
  }
}

