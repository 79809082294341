/* eslint-disable import/no-anonymous-default-export */
import contactPersonSaga from "./redux/contactPerson/contactPerson.saga"
import customerContactPersonsSaga from "./redux/customerContactPersons/customerContactPersons.saga"
import customerStatisticsSaga from "./redux/customerStatistics/customerStatistics.saga"
import customerWithRentedBuildingsSaga from "./redux/customerWithRentedBuildings/customerWithRentedBuildings.saga"
import rentedBuildingContactPersonSaga from "./redux/rentedBuildingContactPerson/rentedBuildingContactPerson.saga"
import contactPersonContactRolesSaga from "./redux/contactPersonContactRoles/contactPersonContactRoles.saga"
import deleteContactPersonSaga from "./redux/deleteContactPerson/deleteContactPerson.saga"
import gaEventsSaga from "./redux/gaEvents/gaEvents.saga"
import appEventsSaga from "./redux/appEvents/appEvents.saga"
import campaignSaga from "./redux/campaign/campaign.saga"
import campaignPictureSaga from "./redux/campaignPicture/campaignPicture.saga"
import rentedBuildingSaga from "./redux/rentedBuilding/rentedBuilding.saga"
import dataStoriesSaga from "./redux/dataStories/dataStories.saga"

export default [
  customerStatisticsSaga,
  customerWithRentedBuildingsSaga,
  rentedBuildingContactPersonSaga,
  customerContactPersonsSaga,
  contactPersonSaga,
  contactPersonContactRolesSaga,
  deleteContactPersonSaga,
  gaEventsSaga,
  appEventsSaga,
  campaignSaga,
  campaignPictureSaga,
  rentedBuildingSaga,
  dataStoriesSaga
]
