import { deleteContactPersonOnRentedBuildings } from "app/api/contactPerson"
import { SagaIterator } from "redux-saga"
import { call, put, select, takeLatest } from "redux-saga/effects"
import * as actions from "./deleteContactPerson.actions"
import * as deleteFlowSelectors from "./deleteContactPerson.selectors"
import * as appEventActions from "../appEvents/appEvents.actions"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { intl } from "app/i18n/config"
import { getCustomerWithRentedBuildings } from "app/api/customerWithRentedbuildings"
import { DeleteContactPersonFlows } from "app/types/deleteContactPersonFlow"
import { CustomerWithRentedBuildings } from "app/types/customerWithRentedBuildings"
import * as customerRentedBuildingsActions from "../customerWithRentedBuildings/customerWithRentedBuildings.actions"
import { createGAEvent, PossibleGAEvents } from "../gaEvents/gaEvents.actions"
import { getContactPersonById } from "../customerWithRentedBuildings/customerWithRentedBuildings.selectors"

export function* determineDeleteModalFlow(
  action: actions.DetermineDeleteFlowAction
): SagaIterator {
  try {
    const response = yield call(getCustomerWithRentedBuildings, {
      customerId: action.payload.entityId
    })
    const entityWithRentedBuildings: CustomerWithRentedBuildings = response.data
    const buildings = entityWithRentedBuildings.rentedBuildings.filter(
      building => {
        const cp = building.contactPersons.find(
          cp => cp.id === action.payload.contactPersonId
        )
        if (!cp) return false
        return true
      }
    )

    yield put(
      actions.determineDeleteFlowSuccess(
        buildings.length === 1
          ? DeleteContactPersonFlows.DELETE_SINGLE_BUILDING
          : DeleteContactPersonFlows.DELETE_MULTI_BUILDING,
        action.payload.entityId,
        action.payload.contactPersonId,
        entityWithRentedBuildings.name!,
        buildings
      )
    )
  } catch (e: any) {
    yield put(
      appEventActions.fire({
        local: true,
        uniqueIdentifier: "determine-delete-modal-error",
        eventName: PossibleAppEvents.ERROR,
        props: {
          title: intl.formatMessage({
            id: "cms.modal.error.title"
          }),
          description: intl.formatMessage({
            id: "cms.modal.error.delete.seat.description"
          }),
          variant: "error",
          show: true
        }
      })
    )
    yield put(actions.determineDeleteFlowFail(e))
  }
}

export function* deleteContactPersonFlow(
  action: actions.DeleteContactPersonAction
): SagaIterator {
  try {
    const contactPerson = yield select(
      getContactPersonById(action.payload.contactPersonId)
    )
    const onboardedAt = contactPerson.onboardedAt
    const entityId: string = yield select(
      deleteFlowSelectors.getEntityIdOfContactPerson
    )
    if (!entityId) {
      throw new Error("Could not determine entity id of the contact person")
    }
    yield call(
      deleteContactPersonOnRentedBuildings,
      action.payload.contactPersonId,
      {
        entityId,
        rentedBuildingIds: action.payload.rentedBuildingIds,
        stillEmployed: action.payload.stillEmployed
      }
    )
    const response = yield call(getCustomerWithRentedBuildings, {
      customerId: entityId
    })
    yield put(
      customerRentedBuildingsActions.getCustomerWithRentedBuildingsSuccess(
        response.data
      )
    )
    yield put(appEventActions.remove("delete-contact-person"))
    yield put(
      appEventActions.fire({
        uniqueIdentifier: "account-deleted",
        eventName: PossibleAppEvents.ACCOUNT_DELETED
      })
    )
    yield put(actions.resetDeleteFlow())
    yield put(
      createGAEvent(PossibleGAEvents.DELETE_ACCOUNT, {
        onboarding_status: onboardedAt ? "onboarded" : "not-onboarded"
      })
    )
    yield put(actions.deleteContactPersonSuccess())
  } catch (e: any) {
    yield put(
      appEventActions.fire({
        local: true,
        uniqueIdentifier: "delete-contactperson-error",
        eventName: PossibleAppEvents.NOTIFICATION,
        props: {
          title: intl.formatMessage({
            id: "cms.modal.error.title"
          }),
          closeTranslation: intl.formatMessage({ id: "cms.button.close" }),
          description: intl.formatMessage({
            id: "cms.modal.error.delete.seat.description"
          }),
          variant: "error",
          show: true
        }
      })
    )
    yield put(actions.deleteContactPersonFail(e))
  }
}

export default function* DeleteContactPersonSaga(): SagaIterator {
  yield takeLatest(actions.Types.DETERMINE, determineDeleteModalFlow)
  yield takeLatest(actions.Types.DELETE, deleteContactPersonFlow)
}
