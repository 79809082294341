import { createBrowserHistory } from "history"
import { combineReducers, Reducer } from "redux"
import { createReduxHistoryContext } from "redux-first-history"
import reducers from "./app/redux/reducers"
import State from "./app/redux/state"

export const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() })

const rootReducer = (): Reducer<State> => combineReducers({
    router: routerReducer,
    ...reducers
})

export default rootReducer
